import {ActionIcon, createStyles, Group, RangeSlider, Text, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useDebouncedValue} from '@mantine/hooks';
import {Cross1Icon, PlusCircledIcon} from '@modulz/radix-icons';
import {useEffect} from 'react';
import {Filters} from '../app/features/imageLabels/slice';

interface Props {
  initialValues: Filters;
  onChange: (filters: Filters) => void;
}

const useStyles = createStyles((theme) => ({
  rangeNumberInput: {
    width: 45,
    textAlign: 'center'
  },
  removeButton: {
    alignSelf: 'center'
  }
}))

export default function ObjectsFilter({initialValues, onChange}: Props) {
  const {classes} = useStyles();

  const form = useForm<Filters>({
    initialValues: initialValues
  });

  const [debounced] = useDebouncedValue(form.values, 700);

  useEffect(() => {
    onChange(debounced);
  }, [debounced]);

  return (
    <form action="">
      <Text
        size="sm"
        mb="sm"
      >
        Sensitivity
      </Text>
      <RangeSlider
        mb="lg"
        color="dark"
        size="sm"
        min={0}
        max={255}
        {...form.getInputProps('sensitivity')}
      />
      {([
        ["ECD (um)", "ecd", [0, 255]],
        ["Area (px)", "area", [0, 1600]],
        ["Perimeter (px)", "perimeter", [0, 1600]],
        ["Intensity Mean", "intencity_mean", [0, 255]],
        ["Intensity STD", "intencity_std", [0, 255]],
        ["Aspect Ratio", "aspectratio", [0, 1]],
        ["Circularity", "circularity", [0, 1]],
      ] as [string, keyof Filters, [number, number]][]).map(([label, key, [min, max]]) =>
        <div key={key}>
          <Group
            position="apart"
          >
            <Text
              size="sm"
              mb="xs"
            >
              {label}
            </Text>
            <ActionIcon
              onClick={() => {
                form.insertListItem(key, [min, max])
              }}
              color="dark"
              size="xs"
            >
              <PlusCircledIcon />
            </ActionIcon>
          </Group>
          {form.values[key].map((val, index) =>
            <Group
              key={index}
              align="baseline"
              spacing={4}
              mt="md"
              mb={24}
            >
              <TextInput
                size="xs"
                radius={4}
                variant="filled"
                onChange={e => form.setFieldValue(
                  `${key}.${index}`,
                  // @ts-ignore
                  [Math.max(e.currentTarget.value, min), val[1]])}
                value={(val as [number, number])[0]}
                onFocus={e => e.currentTarget.select()}
                classNames={{
                  input: classes.rangeNumberInput
                }}
              />
              <RangeSlider
                style={{
                  flexGrow: 1,
                  alignSelf: 'center',
                }}
                color="dark"
                size="sm"
                key={index}
                min={min}
                max={max}
                step={max === 1 ? 0.05 : 1}
                minRange={max === 1 ? 0.05 : 1}
                precision={2}
                {...form.getInputProps(`${key}.${index}`)}
              />
              <TextInput
                classNames={{
                  input: classes.rangeNumberInput
                }}
                size="xs"
                radius={4}
                variant="filled"
                onChange={e => form.setFieldValue(
                  `${key}.${index}`,
                  // @ts-ignore
                  [val[0], Math.min(e.currentTarget.value, max)]
                )}
                value={(val as [number, number])[1]}
                onFocus={e => e.currentTarget.select()}
              />
              <ActionIcon
                onClick={() => {
                  form.removeListItem(key, index)
                }}
                color="dark"
                disabled={form.values[key].length === 1}
                size="xs"
                className={classes.removeButton}
              >
                <Cross1Icon />
              </ActionIcon>
            </Group>
          )}
        </div>
      )}
    </form>
  )
}
