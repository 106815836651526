import {Anchor, Button, createStyles, Group, HoverCard, Popover, PopoverBaseProps, Text} from '@mantine/core';
import {completeTutorial, openQuickGuide, stepInfo, TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';

const useStyles = createStyles(theme => ({
  dropdown: {
    background: '#544E94',
    border: 'none',
    color: '#FFF',

    '& .arrow': {
      border: 'none'
    }
  },
  controls: {

  },
  backButton: {
    color: '#FFB340',
    '&:hover': {
      background: 'transparent',
      color: '#FFB340',
    }
  },
  nextButton: {
    background: '#FFB340',
    color: '#000',
    '&:hover': {
      background: '#FFB340',
      color: '#000',
    }
  },
  learnMoreLink: {
    color: '#FFB340',
  }
}));

interface Props {
  target: React.ReactNode;
  content: React.ReactNode;
  tutorialContent?: React.ReactNode;
  position?: PopoverBaseProps["position"];
  isTutoring?: boolean;
  disabled?: boolean;
  stepInfo?: string;
  learnMoreLink?: string;
  onNextClick?: () => void;
  onBackClick?: () => void;
  disableNav?: boolean;
  offset?: number;
  tutorialComponent?: TutorialComponent;
  tutorialOnly?: boolean;
  isFinal?: boolean;
}

export default function Tooltip(props: Props) {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const isTutorialActive = useAppSelector(state => (
    props.isTutoring ||
    (
      state.quickGuide.isTutorialActive &&
      state.quickGuide.activeComponent === props.tutorialComponent
    )
  ));

  const activeTutorial = useAppSelector(state => state.quickGuide.activeTutorial);
  if (props.disabled || (props.tutorialOnly && !isTutorialActive)) {
    return (
      <>
        {props.target}
      </>
    );
  }

  const handleFinishClick = () => {
    dispatch(completeTutorial(activeTutorial));
  };

  const commonProps = {
    width: 233,
    shadow: 'xs',
    radius: 4,
    withArrow: true,
    offset: props.offset,
    arrowSize: 11,
    position: props.position,
  };

  if (props.tutorialOnly || isTutorialActive) {
    return (
      <Popover
        {...commonProps}
        opened={isTutorialActive}
        styles={{
          arrow: {
            border: 'none',
          }
        }}
      >
        <Popover.Target>
          {props.target}
        </Popover.Target>
        <Popover.Dropdown
          className={classes.dropdown}
          styles={{
            arrow: {
              border: 'none'
            }
          }}
        >
          <>
            <Text
              transform='uppercase'
              size="xs"
              mb="xs"
            >
              {stepInfo(activeTutorial, props.tutorialComponent!)}
            </Text>
            {props.tutorialContent || props.content}
            {props.learnMoreLink &&
              <Anchor
                className={classes.learnMoreLink}
                size="sm"
                onClick={() => {
                  setTimeout(() => {
                    document.getElementById(props.learnMoreLink!)?.focus()
                  }, 0);
                  dispatch(openQuickGuide());
                }}
              >
                Learn more
              </Anchor>
            }
            {!props.disableNav &&
              <Group
                className={classes.controls}
                position="right"
                mt="sm"
              >
                {props.isFinal ?
                  <Button
                    radius="lg"
                    size="xs"
                    className={classes.nextButton}
                    onClick={handleFinishClick}
                  >
                    OK
                  </Button>
                  :
                  <>
                    {props.onBackClick &&
                      <Button
                        className={classes.backButton}
                        variant="subtle"
                        size="xs"
                        onClick={props.onBackClick}
                      >
                        Back
                      </Button>
                    }
                    {props.onNextClick &&
                      <Button
                        radius="lg"
                        size="xs"
                        onClick={props.onNextClick}
                        className={classes.nextButton}
                      >
                        Next
                      </Button>
                    }
                  </>
                }

              </Group>
            }
          </>
        </Popover.Dropdown>
      </Popover>
    )
  };

  return (
    <HoverCard
      {...commonProps}
      openDelay={1000}
      closeDelay={1000}
    >
      <HoverCard.Target>
        {props.target}
      </HoverCard.Target>
      <HoverCard.Dropdown
        className={classes.dropdown}
        styles={{
          arrow: {
            border: 'none',
          }
        }}
      >
        <>
          {props.content}
          {props.learnMoreLink &&
            <Anchor
              className={classes.learnMoreLink}
              size="sm"
              onClick={() => {
                setTimeout(() => {
                  document.getElementById(props.learnMoreLink!)?.focus()
                }, 0);
                dispatch(openQuickGuide());
              }}
            >
              Learn more
            </Anchor>
          }
        </>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
