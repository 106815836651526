import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface Label {
  id: number;
  title: string;
};

const addLabel = createAsyncThunk(
  "labels/add",
  async (data: Partial<Label>, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post('/api/datasets/labels/all', data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const editLabel = createAsyncThunk(
  "labels/update",
  async (data: {id: number} & Partial<Label>, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.patch(`/api/datasets/labels/all/${data.id}`, data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const deleteLabel = createAsyncThunk(
  "labels/delete",
  async (labelId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.remove(`/api/datasets/labels/all/${labelId}`, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadLabels = createAsyncThunk(
  "labels/load",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get('/api/datasets/labels/all', {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'labels',
  initialState: [] as Label[],
  reducers: {
    add: (state, action: PayloadAction<Label>) => {
      state.push(action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(addLabel.fulfilled, (state, action) => {
      state.unshift(action.payload);
    });
    builder.addCase(editLabel.fulfilled, (state, action) => {
      return state.map(label => action.payload.id === label.id ? action.payload : label);
    });
    builder.addCase(deleteLabel.fulfilled, (state, action) => {
      return state.filter(label => action.payload[0] !== label.id);
    });
    builder.addCase(loadLabels.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

export const {add} = slice.actions;
export {addLabel, loadLabels, editLabel, deleteLabel};

export default slice.reducer;
