import {
  Badge,
  Button,
  createStyles,
  Group,
  Popover,
  Text
} from '@mantine/core';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import chevronDownURL from '../assets/icons/chevron-down.svg';
import {formatDate} from '../utils/formatting';

interface Props {
  sessions: {
    id: number;
    created: string;
    started: string | null;
    finished: string | null;
  }[];
}

const useStyles = createStyles(theme => ({
  listItem: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },
}));

export default function CalibrationSessionList(props: Props) {
  const {classes} = useStyles();
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="bottom"
      withArrow
      width={400}
      transition="pop-top-right"
    >
      <Popover.Target>
        <Button
          onClick={() => setOpened((o) => !o)}
          variant="light"
          color="gray"
          disabled={!props.sessions.length}
          rightIcon={<img src={chevronDownURL} alt="" />}
        >
          {props.sessions.length} Session{props.sessions.length > 1 ? 's' : ''}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        {props.sessions.map(session =>
          <Group
            key={session.id}
            position="apart"
            className={classes.listItem}
          >
            <div>
              <Text
                size="sm"
              >
                {formatDate(session.created)}
              </Text>
            </div>
            {!session.finished &&
              <>
                <Badge variant="dot" color="yellow">In Progress</Badge>
                <Button
                  size="xs"
                  variant="outline"
                  disabled
                >
                  view
                </Button>
              </>
            }
            {session.finished &&
              <>
                <Badge variant="dot">Done</Badge>
                <Button
                  size="xs"
                  variant="outline"
                  // @ts-ignore
                  component={Link}
                  to={`/calibration/report/${session.id}/`}
                >
                  view
                </Button>
              </>
            }
          </Group>
        )}
      </Popover.Dropdown>
    </Popover>
  );
}
