import {Divider, Image, Text, Title} from '@mantine/core';
import imageURL from '../assets/images/score-example.jpg';

export default function DocumentationScore() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        Scores represent the confidence of a model in a prediction.
        Score values range from 0 to 100, with 100 representing a model’s
        full confidence of an object’s predicted classification.  These
        scores are typically reviewed during a model’s training to observe
        its performance over time and to determine which classifications require
        additional data to improve performance.
      </Text>
      <Divider my="sm" variant="dashed" />
      <Title
        order={5}
        style={{fontWeight: 500}}
        mb="xs"
      >
        Example
      </Title>

      <Text size="sm" mb="lg">
        Example of a particle label reported with model’s confidence percentage.  This percentage is a score, in this case, equal to 100
      </Text>

      <Image
        src={imageURL}
        alt=""
        mb="md"
        mt="md"
      />
    </>
  )
}
