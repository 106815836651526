import {Button, Card, createStyles, Divider, Grid, Group, GroupPosition, ScrollArea, Text, Title} from '@mantine/core';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import chevronLeftIconURL from '../assets/icons/chevron-left-thin.svg';
import chevronRightIconURL from '../assets/icons/chevron-right-thin.svg';
import logoImageURL from '../assets/icons/logo.svg';
import DocumentationAI from './DocumentationAI';
import DocumentationCalibration from './DocumentationCalibration';
import DocumentationIndex from './DocumentationIndex';
import DocumentationInference from './DocumentationInference';
import DocumentationLabelling from './DocumentationLabelling';
import DocumentationLoss from './DocumentationLoss';
import DocumentationMean from './DocumentationMean';
import DocumentationNetwork from './DocumentationNetwork';
import DocumentationReport from './DocumentationReport';
import DocumentationScore from './DocumentationScore';
import DocumentationTraining from './DocumentationTraining';
import DocumentationTrainingSet from './DocumentationTrainingSet';
import DocumentationTrainingValidationSet from './DocumentationTrainingValidationSet';
import LinkDocumentation from './LinkDocumentation';

const useStyles = createStyles(theme => ({
  indexTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing.md,
  },
  index: {
    backgroundColor: '#F5F5F5',
    height: 'calc(100vh - 100px)',
  },
  indexList: {
    fontSize: theme.fontSizes.sm,
  },
  content: {
    height: 'calc(100vh - 265px)',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bottomControls: {
  },
  button: {
    fontWeight: 500,
    color: '#000',
  }
}));

const links = [
  {
    to: "/documentation/",
    label: "Overview",
    component: <DocumentationIndex key={1} />
  },
  {
    to: "/documentation/ai/",
    label: "Artificial Intelligence (AI) Models",
    component: <DocumentationAI key={2} />,
  },
  {
    to: "/documentation/neural-network/",
    label: "Neural Network",
    component: <DocumentationNetwork key={6} />,
  },
  {
    to: "/documentation/labelling/",
    label: "Labelling",
    component: <DocumentationLabelling key={4} />,
  },
  {
    to: "/documentation/training-set/",
    label: "Training Set",
    component: <DocumentationTrainingSet key={10} />,
  },
  {
    to: "/documentation/training/",
    label: "Training",
    component: <DocumentationTraining key={9} />,
  },
  {
    to: "/documentation/loss/",
    label: "Loss",
    component: <DocumentationLoss key={4} />,
  },
  {
    to: "/documentation/mean-average-precision/",
    label: "Mean Average Precision (mAP)",
    component: <DocumentationMean key={5} />,
  },
  {
    to: "/documentation/score/",
    label: "Score",
    component: <DocumentationScore key={8} />,
  },
  {
    to: "/documentation/validation-images/",
    label: "Validation Images",
    component: <DocumentationTrainingValidationSet key={11} />,
  },
  {
    to: "/documentation/inference/",
    label: "Inference",
    component: <DocumentationInference key={3} />,
  },
  {
    to: "/documentation/reporting/",
    label: "Reporting",
    component: <DocumentationReport key={7} />,
  },
  {
    to: "/documentation/calibration/",
    label: "Calibration",
    component: <DocumentationCalibration key={123} />,
  },
];

export default function PageDocumentation() {
  const {classes} = useStyles();
  const {pathname} = useLocation();

  const contentViewport = React.useRef<HTMLDivElement>();
  const resetContentScroll = () => contentViewport.current!.scrollTo({top: 0, behavior: "auto"});

  const docIndex = links.findIndex(link => link.to === pathname);
  if (docIndex === -1) {
    return (
      <div>NOT FOUND</div>
    );
  }
  const DocComponent = links[docIndex].component;
  const pageTitle = links[docIndex].label;

  const prevDocData = docIndex > 0 ? links[docIndex - 1] : false;
  const nextDocData = docIndex < links.length - 1 ? links[docIndex + 1] : false;
  const bottomNavigationGroupPosition = (
    ((prevDocData && nextDocData) && 'apart') ||
    (nextDocData && 'right') ||
    (prevDocData && 'left')
  );

  return (
    <Grid
      gutter={12}
      columns={32}
    >
      <Grid.Col
        span={7}
      >
        <Card className={classes.index}>

          <Title order={5} className={classes.indexTitle}>
            General Overview
          </Title>

          <div
            className={classes.indexList}
          >
            {links.map((linkProps, index) =>
              <LinkDocumentation
                key={index}
                onClick={resetContentScroll}
                {...linkProps}
              />
            )}
          </div>
        </Card>
      </Grid.Col>
      <Grid.Col
        span={25}
      >
        <Card
          pl={42}
          pr={42}
        >
          <Group position="apart">
            <Title
              order={3}
            >
              {pageTitle}
            </Title>
            <img src={logoImageURL} alt="" />
          </Group>
          <Divider
            mt="sm"
            variant="dashed"
          />
          <Card.Section>
            <ScrollArea
              className={classes.content}
              // @ts-ignore
              viewportRef={contentViewport}
            >
              {DocComponent}
            </ScrollArea>
          </Card.Section>
          <Divider />
          <Group
            mt="sm"
            position={bottomNavigationGroupPosition as GroupPosition}
            className={classes.bottomControls}
          >
            {prevDocData &&
              <div>
                <Text
                  size="sm"
                  color="gray"
                  mb="xs"
                >
                  {prevDocData.label}
                </Text>
                <Button
                  component={Link}
                  color="gray"
                  onClick={resetContentScroll}
                  to={prevDocData.to}
                  variant="light"
                  className={classes.button}
                  leftIcon={
                    <img
                      alt=""
                      src={chevronLeftIconURL}
                    />
                  }
                >
                  Previous
                </Button>
              </div>
            }
            {nextDocData &&
              <div>
                <Text
                  size="sm"
                  color="gray"
                  mb="xs"
                  align='right'
                >
                  {nextDocData.label}
                </Text>
                <Text align="right">
                  <Button
                    component={Link}
                    to={nextDocData.to}
                    onClick={resetContentScroll}
                    color="gray"
                    variant="light"
                    className={classes.button}
                    rightIcon={
                      <img
                        alt=""
                        src={chevronRightIconURL}
                      />
                    }
                  >
                    Next
                  </Button>
                </Text>
              </div>
            }
          </Group>
        </Card>
      </Grid.Col>
    </Grid>
  )
}
