import React from "react";
import {AxisOptions, Chart} from "react-charts";
import {THEME_COLORS} from '../utils/misc';

interface Props {
  showTooltip: boolean;
  data: {
    label: string;
    data: {
      primary: string;
      secondary: number;
    }[];
  }[];
};

export default function BarStacked(props: Props) {
  const {data} = props;

  const primaryAxis = React.useMemo<
    AxisOptions<typeof data[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof data[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        stacked: true,
      },
    ],
    []
  );

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        defaultColors: THEME_COLORS,
      }}
    />
  );
}
