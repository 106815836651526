import {Button, Text} from '@mantine/core';
import {TutorialComponent} from '../app/features/quick-guide/slice';
import Tooltip from './Tooltip';

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  selectedPoint?: number;
  tooltipDisabled?: boolean;
}

export default function ExportModelButton({selectedPoint, onClick, disabled, tooltipDisabled}: Props) {
  return (
    <Tooltip
      disabled={tooltipDisabled}
      learnMoreLink='q-select-ai-model'
      tutorialComponent={TutorialComponent.CLICK_EXPORT_MODEL_BUTTON}
      target={
        <Button
          disabled={disabled}
          onClick={onClick}
        >
          {!selectedPoint && "Click on the desired saved model on chart"}
          {!!selectedPoint && `Export model at ${selectedPoint}`}
        </Button>
      }
      content={
        <>
          <Text size="sm">
            When done reviewing the training session progress
            select "Export Model Button" after chosing the Model
            on Loss Chart.
          </Text>
        </>
      }
    />
  )
}
