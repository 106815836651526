import {
  Badge, Button, createStyles,
  Group,
  Popover,
  Text
} from '@mantine/core';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import * as analytics from '../app/features/analytics';
import {goToNextStep, TutorialComponent} from '../app/features/quick-guide/slice';
import {loadSessions, stopSession} from '../app/features/training/slice';
import {useAppDispatch} from '../app/hooks';
import chevronDownURL from '../assets/icons/chevron-down.svg';
import {formatDate} from '../utils/formatting';
import Tooltip from './Tooltip';

interface Props {
  sessions: {
    id: number;
    dataset: number;
    status: string;
    is_done: boolean;
    date_started: string;
    is_stopped: boolean;
    is_running: boolean;
  }[];
  disableTooltip?: boolean;
}

const useStyles = createStyles(theme => ({
  listItem: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },
  listWrapper: {
    maxHeight: 400,
    overflow: 'auto',
  }
}));

export default function TrainingSessionList(props: Props) {
  const {classes} = useStyles();
  const [opened, setOpened] = useState(false);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(loadSessions());
    }, 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [dispatch]);

  const handleSessionStop = (sessionId: number) => {
    dispatch(stopSession(sessionId));
  };

  return (
    <Tooltip
      disabled={props.disableTooltip || opened}
      tutorialComponent={TutorialComponent.OPEN_TRAINING_SESSIONS_LIST}
      tutorialContent={
        <>
          <Text size="sm">
            Select ‘Sessions’ to see a complete list of your training sessions in progress.
            Check this status again after training finishes in up to six hours.
          </Text>
        </>
      }
      target={
        <Group>
          <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            position="bottom"
            withArrow
            width={470}
            transition="pop-top-right"
          >
            <Popover.Target>
              <Button
                onClick={() => {
                  setOpened((o) => !o);
                  analytics.logButtonClick({eventName: analytics.TRAINING_SESSIONS_DROPDOWN});
                  dispatch(goToNextStep(TutorialComponent.OPEN_TRAINING_SESSIONS_LIST));
                }}
                variant="light"
                color="gray"
                rightIcon={<img src={chevronDownURL} alt="" />}
              >
                {props.sessions.length} Session{props.sessions.length > 1 ? 's' : ''}
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <div>
                <Tooltip
                  offset={20}
                  tutorialComponent={TutorialComponent.CLICK_VIEW_TRAINING_SESSION}
                  position="right-start"
                  target={
                    <div className={classes.listWrapper}>
                      {props.sessions.map(session =>
                        <Group
                          key={session.id}
                          position="apart"
                          className={classes.listItem}
                        >
                          <div>
                            <Text
                              size="sm"
                            >
                              {session.date_started ? formatDate(session.date_started) : 'Feb 7, 11:22 AM'}
                            </Text>
                          </div>
                          {session.is_stopped &&
                            <>
                              <Badge
                                variant="dot"
                                color="gray"
                              >
                                Stopped
                              </Badge>
                            </>
                          }
                          {!session.is_done && !session.is_stopped &&
                            <>
                              <Badge
                                variant="dot"
                                color="yellow"
                              >
                                In Progress
                              </Badge>
                              <Button
                                size="xs"
                                variant="outline"
                                color="red"
                                onClick={() => handleSessionStop(session.id)}
                              >
                                stop
                              </Button>
                            </>
                          }

                          {session.is_done &&
                            <>
                              <Badge
                                variant="dot"
                              >
                                Done
                              </Badge>
                              <Button
                                size="xs"
                                variant="outline"
                                // @ts-ignore
                                component={Link}
                                to={`/training/session/${session.id}/`}
                              >
                                view
                              </Button>
                            </>
                          }
                          {session.is_running &&
                            <Button
                              size="xs"
                              variant="outline"
                              // @ts-ignore
                              component={Link}
                              to={`/training/session/${session.id}/`}
                            >
                              view
                            </Button>
                          }
                        </Group>
                      )}
                    </div>
                  }
                  content={
                    <>
                      <Text size="sm">
                        Once your training session completes (may take up to six hours), select the ‘View’ button.
                      </Text>
                    </>
                  }
                />
              </div>
            </Popover.Dropdown>
          </Popover>
        </Group>
      }
      content={
        <>
          <Text size="sm">
            Complete list of your training sessions in progress.  Check this status again after training finishes in up to six hours.
          </Text>
        </>
      }
    />
  );
}
