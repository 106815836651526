import {
  Anchor,
  Button,
  Checkbox,
  createStyles,
  Group,
  NativeSelect,
  Text
} from '@mantine/core';
import {useForm} from '@mantine/form';

const useStyles = createStyles(theme => ({
  anomaliesWrapper: {
    padding: theme.spacing.xs,
    border: `solid 1px ${theme.colors.gray[4]}`,
    maxHeight: 100,
    overflowY: 'auto'
  }
}));

interface Props {
  onSubmit(values: {model: string;}): void;
  onCancel(): void;
  labels: {
    id: number;
    title: string;
  }[];
  initialValues: {model: string;};
  modelsList: {
    id: number;
    title: string;
  }[];
  disabled?: boolean;
}

export default function InferenceStartForm({initialValues, onSubmit, onCancel, modelsList, labels}: Props) {
  const {classes} = useStyles();

  const form = useForm({
    initialValues,
    validate: {
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <NativeSelect
        required
        label="Model"
        placeholder="Select model to run inference"
        data={modelsList.map(model => ({
          value: model.id + '',
          label: model.title,
        })).concat({
          value: '0',
          label: 'Click to select model',
        })}
        variant="default"
        {...form.getInputProps('model')}
      />
      <Text mb={'sm'} mt="sm">
        Anomaly Labels
      </Text>
      <div className={classes.anomaliesWrapper}>
        {labels.map(label =>
          <Checkbox
            mb="xs"
            mt="xs"
            key={label.id}
            label={label.title}
          />
        )}
      </div>
      <Group position="apart" style={{marginTop: 15}}>
        <Anchor component="button" color="gray" size="sm" onClick={onCancel}>
          Cancel
        </Anchor>
        <Button type="submit" size="sm" >
          Confirm
        </Button>
      </Group>
    </form>
  );
}

