import {DatasetType} from './app/features/datasets/slice';

export const DATASETS = '/datasets/:type/';
export const DATASETS_WITH_ID = '/datasets/:type/:datasetId/';

export const ELAB_EXPERIMENT_IMPORT = '/elab/integration/:experimentId/';
export const ELAB_SECTION_IMPORT = '/elab/integration/:experimentId/section/:sectionId/';
export const ELAB_CHECK_ACCESS = '/elab/check-access/';

export const TRAINING_SESSION = '/training/session/:trainingSessionId/';
export const ANNOTATION_GALLERY = '/training/annotate/:datasetId/';
export const TRAINING_DATASET_IMPORT = '/training/import/:datasetId/';
export const QUALITY_DASHBOARD = '/quality-dashboard/';
export const DOCUMENTATION = '/documentation/:section?/';
export const DOCUMENTATION_HOME = '/documentation/';

export const INFERENCE = '/inference/';

export const INFERENCE_REPORTS_PAGE = '/inference/reports/';
export const INFERENCE_REPORT = '/inference/report/:sessionId/';
export const CALIBRATION_REPORT = '/calibration/report/:sessionId/';
export const COMPARE_REPORTS = '/report/:sessionAid/compare/:sessionBid/';
export const INFERENCE_IMAGE_PREVIEW = '/inference/session/:sessionId/image/:imageId/';
export const MPCOMPONENTS = '/foundational-components/';

export const EDIT_LABELS = '/labels/';
export const MODELS_LIST = '/models/';
export const WORKSPACE = '/:datasetType?/:datasetId?/:imageId?/';
export const getWorkspaceURL = (
  datasetType?: 'inference' | 'training' | 'calibration',
  datasetId?: number | string,
  imageId?: number | string,
) => `/${datasetType ? datasetType + '/' : ''}${datasetId ? datasetId + '/' : ''}${imageId ? imageId + '/' : ''}`;

export const getCompareReportsURL = (sessions: number[]) => `/inference/reports/compare/${sessions.join('-')}/`;
export const getInferenceReportURL = (sessionId: number, isCalibration: boolean = false) => isCalibration ? `/calibration/report/${sessionId}/` : `/inference/report/${sessionId}/`;
export const getTrainingSessionURL = (sessionId: number) => `/training/session/${sessionId}/`;
export const getTrainingImportURL = (datasetId: number) => `/training/import/${datasetId}/`;
export const getDatasetsURL = (type: DatasetType, datasetId?: string | number) => (
  `/${type.toLocaleLowerCase()}/${datasetId ? datasetId + '/' : ''}`
);
export const getElabImportPage = (experimentId: string | number, sectionId: string | number) => (
  `/elab/integration/${experimentId}/section/${sectionId}/`
);
