import {Button, createStyles} from '@mantine/core';
import {openContextModal} from '@mantine/modals';
import {ONBOARDING_MODAL} from './Modals';

const useStyles = createStyles(theme => ({
  root: {
    textTransform: 'initial',
    fontWeight: 500,
    height: 36,
    cursor: 'pointer',
    paddingLeft: 8,
  },
  icon: {
    verticalAlign: 'middle',
    marginBottom: 2,
    width: 16.67,
  },
}));

interface Props {
  ml?: number;
}


export default function OnboardingBadge(props: Props) {
  const {classes} = useStyles();

  const onRequestSubmit = () => {

  };

  return (
    <Button
      variant="subtle"
      ml={props.ml}
      className={classes.root}
      onClick={() => {
        openContextModal({
          modal: ONBOARDING_MODAL,
          title: '',
          size: 684,
          innerProps: {
            onSubmitSuccess: onRequestSubmit
          }
        });
      }}
    >
      Request Onboarding Demo
    </Button>
  )
}
