import {AspectRatio, Box, Divider, Text, Title} from '@mantine/core';
import ProcessingWorkflow from './ProcessingWorkflow';


export default function DocumentationLabelling() {
  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        The first step to creating an image processing model is to label a training set.  The training set is a group of images that the model will learn object identities and locations from over time.  To label a data set, all the objects within each image should be outlined by coordinates and assigned a label, known as a class.  The more images labelled, the more accurate the model will become.
      </Text>
      <Divider my="sm" variant="dashed" />
      <Title
        order={5}
        style={{fontWeight: 500}}
        mb="xs"
      >
        Precision
      </Title>
      <Text size="sm" mb="lg">
        Label at a minimum 20 images to train an initial model.
      </Text>
      <AspectRatio ratio={16 / 9}>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube-nocookie.com/embed/AzNvyUGgcTg?controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
      <Divider my="sm" variant="dashed" />


      <Box mb="lg">
        <ProcessingWorkflow
          activeIndex={0}
        />
      </Box>
    </>
  )
}
