import { Button, createStyles, Text, Title } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { chunk } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Image, uploadDatasetImages } from '../app/features/images/slice';
import { useAppSelector } from '../app/hooks';
import uploadIconImageURL from '../assets/icons/upload.svg';
import UploadingInfo from './UploadingInfo';

const useStyles = createStyles((theme) => ({
  root: {
    border: '1px dashed #CED7DF',
    textAlign: 'center',
    padding: 44,
  },
  dropZone: {
    border: 'none'
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing.md,
  },
  textOr: {
    color: '#737A8D',
    marginBottom: theme.spacing.md,
  },
  uploadContentWrapper: {
    height: 210,
  }
}));

interface Props {
  onSubmitSuccess?: () => void;
  datasetId: number;
}

const UPLOAD_CHUNKS = 100;

export default function EmptyDatasetPlaceholder(props: Props) {
  const { classes } = useStyles();
  const reduxDispatch = useDispatch();
  const token = useAppSelector(state => state.auth.token);
  const [isUploading, setUploading] = React.useState(false);
  const [filesTotal, setFilesTotal] = React.useState(0);
  const [uploadCounter, setUploadCounter] = React.useState(0);

  const submitFiles = async (files: File[]) => {
    setUploading(true);
    setFilesTotal(files.length);
    const fileChunks = chunk(files, UPLOAD_CHUNKS);
    setUploadCounter(0);
    const localUploadedFiles: Image[] = [];
    let counter = 1;
    for (const filesChunk of fileChunks) {
      const res = await uploadDatasetImages(props.datasetId, filesChunk, token);
      localUploadedFiles.push(...res);
      setUploadCounter(counter++);
    }
    setUploading(false);
    setFilesTotal(0);
    reduxDispatch({ type: "images/upload/fulfilled", payload: localUploadedFiles });

    if (props.onSubmitSuccess) {
      props.onSubmitSuccess();
    }
  };

  return (
    <div>
      <Dropzone
        loading={isUploading && (filesTotal < UPLOAD_CHUNKS * 2)}
        onDrop={submitFiles}
        maxSize={1000 * 1024 ** 2}
        accept={["image/*"]}
        className={classes.root}
      >
        {(isUploading && filesTotal >= UPLOAD_CHUNKS * 2) ?
          <div className={classes.uploadContentWrapper}>
            <UploadingInfo
              total={filesTotal}
              uploaded={uploadCounter * UPLOAD_CHUNKS}
            />
          </div>
          :
          <div className={classes.uploadContentWrapper}>
            <img
              alt=""
              src={uploadIconImageURL}
            />
            <Title
              order={6}
              className={classes.title}
            >
              Drag’n Drop to upload
            </Title>
            <Text
              transform="uppercase"
              className={classes.textOr}
            >
              Or
            </Text>
            <Button
              variant="light"
              mb="md"
            >
              Click to select files
            </Button>
            <Text>Upload images either by Drag ‘n Drop to the drop zone or by selection from your local environment</Text>
          </div>
        }

      </Dropzone>
    </div>
  )
}
