import {createStyles, Group, Text, Title} from '@mantine/core';
import logoImageURL from '../assets/images/logo-white.svg';

const useStyles = createStyles((theme) => ({
  card: {
    background: '#CED7DF',
    padding: theme.spacing.sm / 1.5,
    paddingTop: theme.spacing.md,
  },
  cardActive: {
    background: '#428BDF',
    color: '#fff',
  },
  cardTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing.sm,
  },
  cardContent: {
    height: 101,
    color: "#3E4247",
  },
  cardContentActive: {
    height: 120,
    color: '#fff',
  },
}));

const contentData = [
  {
    title: 'Image Labelling',
    content: 'End user identifies and labels particles in each respective image',
  },
  {
    title: 'Model Training',
    content: 'Training loss monitored to evaluate training progress for desired duration',
  },
  {
    title: 'Inferencing New Data',
    content: 'Perform classification on new image data with previously trained AI model',
  },
  {
    title: 'Reporting',
    content: 'Generate PDFs and visualize particle characteristics and AI outputs',
  },
];

interface Props {
  activeIndex?: number;
};

export default function ProcessingWorkflow({activeIndex}: Props) {
  const {classes, cx} = useStyles();

  return (
    <Group grow>
      {contentData.map(({content, title}, index) =>
        <div
          key={index}
          className={cx(classes.card, activeIndex === index && classes.cardActive)}
        >
          <Title
            className={classes.cardTitle}
            order={5}
          >
            {title}
          </Title>
          <Text
            className={cx(classes.cardContent, activeIndex === index && classes.cardContentActive)}
          >
            {content}
          </Text>
          <Group
            position={activeIndex === index ? "apart" : "right"}
          >
            {activeIndex === index &&
              <img
                src={logoImageURL}
                alt=""
              />
            }
            <Text style={{alignSelf: 'flex-end'}}>{index + 1}</Text>
          </Group>
        </div>
      )}
    </Group>
  )
}
