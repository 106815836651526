import {
  Button,
  Center,
  createStyles,
  Group,
  Image,
  Modal,
  ScrollArea,
  Skeleton,
  Text
} from '@mantine/core';
import React from 'react';
import chevronIconURL from '../assets/icons/chevron-left.svg';
import chevronRightIconURL from '../assets/icons/chevron-right.svg';
import EmptyDatasetPlaceholder from './EmptyDatasetPlaceholder';


const useStyles = createStyles((theme) => ({
  root: {
    paddingLeft: 105,
    paddingRight: 28,
  },
  uploadButton: {
    position: 'absolute',
    left: 28,
    top: 2,
    backgroundColor: '#F5F5F5',
    width: 72,
    height: 72,
    borderRadius: theme.radius.xs,
    border: '1px solid #E7EAEE',
    boxSizing: 'border-box',
    fontSize: 36,
    fontWeight: 200,
    cursor: 'pointer',
    zIndex: 2,
  },
  relative: {
    position: 'relative',
  },
  image: {
    cursor: 'pointer',
  },
  activeImage: {
    border: `solid 2px ${theme.colors.blue[5]}`
  },
  imageWrapper: {
    position: 'relative',
    border: 'solid 2px transparent',
    width: 76,
    height: 76,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  button: {
    position: 'absolute',
    bottom: '34%',
    zIndex: 10,
    height: 76,
    top: 0,
    color: '#000',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    }
  },
  buttonLeft: {
    left: 0,
    paddingLeft: 0,
    zIndex: 2,
  },
  buttonRight: {
    zIndex: 11,
    right: 0,
    paddingRight: 0,
  },
  imageIdWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: 'rgba(0,0,0,.5)',
    color: '#fff',
    zIndex: 1,
    borderBottomLeftRadius: 5,
    fontSize: 16,
    cursor: 'pointer',
  }
}));

interface Props {
  datasetId: number;
  images: {
    id: number;
    file: string;
    thumbnail: string;
  }[];
  onImageClick: (imageId: number) => void;
  activeImage: number;
  isLoading?: boolean;
  onScrollEnd?: () => void;
}

function ImagesGallery({activeImage, isLoading = false, onScrollEnd = () => { }, ...props}: Props) {
  const {classes, cx} = useStyles();
  const scrollRoot = React.useRef<HTMLDivElement>(null);
  const [isUploadModalOpen, setUploadModalOpen] = React.useState(false);
  const handleUploadModalClose = () => setUploadModalOpen(false);
  const handleUploadButtonClick = () => setUploadModalOpen(true);

  const handleRightClick = () => {
    if (scrollRoot.current) {
      const elem = scrollRoot.current;
      elem.scrollTo({
        left: elem.scrollLeft + elem.getBoundingClientRect().width - 40,
        behavior: 'smooth',
      });
    }
  };

  const handleLeftClick = () => {
    if (scrollRoot.current) {
      const elem = scrollRoot.current;
      elem.scrollTo({
        left: elem.scrollLeft - elem.getBoundingClientRect().width - 40,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <div className={classes.relative}>
        <ScrollArea
          viewportRef={scrollRoot}
          onScrollPositionChange={position => {
            if (scrollRoot.current) {
              if ((scrollRoot.current.scrollWidth - position.x) < 1400 && !isLoading) {
                onScrollEnd();
              }
            }
          }}
        >
          <Group className={classes.root}>
            <Group align={"center"} noWrap spacing={0}>
              {props.images.map(({id, file}, index) =>
                <div
                  key={id}
                  className={
                    cx(
                      classes.imageWrapper,
                      (activeImage === id || (activeImage === 0 && index === 0)) && classes.activeImage
                    )}
                >
                  <Text
                    size="xs"
                    component='div'
                    className={classes.imageIdWrapper}
                    onClick={() => {
                      props.onImageClick(id)
                    }}
                  >
                    {index + 1}
                  </Text>
                  <Image
                    className={classes.image}
                    onClick={() => {
                      props.onImageClick(id)
                    }}
                    src={file}
                    withPlaceholder
                    height={72}
                    placeholder={<Skeleton
                      width={72}
                      height={72}
                    />}
                  />
                </div>
              )}
            </Group>
          </Group>
        </ScrollArea>
        <div className={classes.uploadButton} onClick={handleUploadButtonClick}>
          <Center style={{height: 64}}>+</Center>
        </div>
        <Button
          radius={0}
          className={cx(classes.button, classes.buttonLeft)}
          onClick={handleLeftClick}
          size="xs"
          variant="subtle"
        >
          <img src={chevronIconURL} alt="" />
        </Button>
        <Button
          radius={0}
          className={cx(classes.button, classes.buttonRight)}
          onClick={handleRightClick}
          size="xs"
          variant="subtle"
        >
          <img src={chevronRightIconURL} alt="" />
        </Button>
      </div>

      <Modal
        opened={isUploadModalOpen}
        onClose={handleUploadModalClose}
      >
        <EmptyDatasetPlaceholder
          datasetId={props.datasetId}
          onSubmitSuccess={handleUploadModalClose}
        />
      </Modal>
    </>
  );
}

export default ImagesGallery;
