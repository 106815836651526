import {Alert, Button, createStyles, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import React from 'react';
import {logAuthClick} from '../app/features/analytics';
import {login} from '../app/features/auth/slice';
import {useAppDispatch} from '../app/hooks';


const useStyles = createStyles((theme) => ({
  root: {

  },
  textInput: {
    marginBottom: theme.spacing.md
  },
  formLevelError: {
    marginBottom: theme.spacing.md
  }
}));

interface Props {
  onSubmitSuccess: () => void;
}

export function LoginForm(props: Props) {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: {
      username: value => !value,
      password: value => !value,
    },
  });
  const [serverError, setServerError] = React.useState<string>('');

  const handleFormSubmit = (values: {username: string, password: string}) => {
    logAuthClick();
    dispatch(login(values)).then(res => {
      if (res.meta.requestStatus === 'rejected') {
        setServerError(res.payload);
      } else {
        props.onSubmitSuccess();
      }
    })
  };

  return (
    <form onSubmit={form.onSubmit(handleFormSubmit)}>
      <TextInput
        className={classes.textInput}
        label="Username"
        error={form.errors.username && 'Username is required.'}
        value={form.values.username}
        onChange={(event) => form.setFieldValue('username', event.currentTarget.value)}
      />

      <TextInput
        className={classes.textInput}
        label="Password"
        type="password"
        error={form.errors.password && 'Password is required.'}
        value={form.values.password}
        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
      />
      {serverError &&
        <Alert
          className={classes.formLevelError}
          color="red"
        >
          {serverError}
        </Alert>
      }
      <Button type="submit">Submit</Button>
    </form>
  );
}

export default LoginForm;
