import {
  Center,
  Col,
  createStyles,
  Grid,
  Modal
} from '@mantine/core';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as analytics from '../app/features/analytics';
import {DatasetType} from '../app/features/datasets/slice';
import {useAppSelector, useIsAuthenticated} from '../app/hooks';
import * as urls from '../urls';
import ElabJournalExperiment from './ElabJournalExperiment';
import FormElabLogin from './FormElabLogin';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: theme.spacing.md
  },
}));

export default function PageElabImport() {
  const {classes} = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const {experimentId, sectionId} = useParams<{experimentId?: string, sectionId?: string}>();
  const hasElabAccess = useAppSelector(state => state.auth.has_elab_access);
  const [isElabLoginOpen, setElabLoginOpen] = React.useState(true);

  const redirectToDatasets = (datasetId: number, type: string) => {
    navigate(urls.getDatasetsURL(type as DatasetType, datasetId + ''));
  };

  const handleElabModalClose = () => setElabLoginOpen(false);
  React.useEffect(() => {
    analytics.logPageVisit({
      eventName: analytics.ELAB_IMPORT_PAGE
    });
  }, []);

  return (
    <Grid className={classes.root}>
      {(!isAuthenticated || !hasElabAccess) &&
        <Modal
          opened={isElabLoginOpen}
          onClose={() => {
            if (isAuthenticated && hasElabAccess) {
              setElabLoginOpen(false);
            }
          }}
          title="Create mpVision account"
        >
          <FormElabLogin
            onSubmitSuccess={handleElabModalClose}
          />
        </Modal>
      }
      <Col span={4}></Col>
      <Col span={6}>
        <Center style={{width: 400}}>
          {isAuthenticated && hasElabAccess &&
            <ElabJournalExperiment
              onImportSuccess={redirectToDatasets}
              experimentId={parseInt(experimentId!, 10)}
              sectionId={sectionId ? parseInt(sectionId, 10) : undefined}
            />
          }
        </Center>
      </Col>
    </Grid>
  );
}
