import {AspectRatio, Box, Divider, Text, Title} from '@mantine/core';
import ProcessingWorkflow from './ProcessingWorkflow';


export default function DocumentationInference() {
  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        The process of inference refers to applying an AI model (neural network) to new images after it has been fully trained on labelled images.  After a model has been trained, it can be exported and applied in real time to images that it has not yet seen as long as the images are representative of the data on which the model has been trained.
      </Text>
      <Text size="sm" mb="md">
        Each inference is accompanied by a report that captures the data output from the AI model.
      </Text>
      <AspectRatio ratio={16 / 9}>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube-nocookie.com/embed/WNFHWLuf_ps?controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
      <Divider my="sm" variant="dashed" />

      <Title
        order={5}
        style={{fontWeight: 500}}
        mb="xs"
      >
        Initial Processing Workflow
      </Title>
      <Text size="sm" mb="lg">
        Process workflow from initial image upload to final report generation. Each stage must be performed consecutively during initial software usage.
      </Text>
      <Box mb="lg">
        <ProcessingWorkflow
          activeIndex={2}
        />
      </Box>
    </>
  )
}
