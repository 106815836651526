import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface HistogramData {
  values: number[];
  bucket_ranges: number[];
}

export interface HistogramDataDict {
  combined: {
    ecd: HistogramData;
    max_feret: HistogramData;
  };
  by_classification: {
    [key: string]: ({
      classification: string;
    } & HistogramData)[];
  }
}
export interface ClassificationStats {
  classification: string;
  count: number;
  percentage: number;
  avg_area: number;
  avg_ecd: number;
  avg_feret: number;
  avg_aspect: number;
  avg_circularity: number;
  stdev_ecd: number;
  stdev_feret: number;
  stdev_area: number;
  stdev_aspect_ratio: number;
  stdev_circularity: number;
}

export interface Intersection {
  classification: string;
  children: {
    classification: string;
    average: number;
    total: number;
  }[];
}

export interface ParticleStats {
  date_started: string;
  model: {
    id: number;
    title: string;
  };
  dataset: {
    id: number;
    title: string;
    date: string;
  };

  summary: {
    classification: string;
    avg_true_positive: number;
    std_true_positive: number;
    avg_false_positive: number;
    std_false_positive: number;
    avg_false_negative: number;
    std_false_negative: number;
    avg_true_negative: number;
    std_true_negative: number;
    avg_accuracy: number;
    std_accuracy: number;
  }[];
  labels_data: {
    id: number;
    url: string;
    calibration_results: {
      classification: string;
      true_positive: number;
      false_positive: number;
      false_negative: number;
      true_negative: number;
      accuracy: number;
    }[],
    inference_results: {
      id: number;
      top: number;
      left: number;
      width: number;
      height: number;
      title: string;
    }[],
    labels: {
      id: number;
      top: number;
      left: number;
      width: number;
      height: number;
      title: string;
    }[];
  }[];
};

const loadCalibrationReport = createAsyncThunk(
  "calibration/report",
  async ({sessionId}: {sessionId: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/sessions/${sessionId}/calibration/report/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          return res;
        });
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'calibrations',
  initialState: {
    summary: [],
    date_started: '',
    model: {
      id: 0,
      title: ''
    },
    dataset: {
      id: 0,
      title: '',
      date: ''
    },
    labels_data: [],
  } as ParticleStats,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadCalibrationReport.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

export {loadCalibrationReport};

export default slice.reducer;
