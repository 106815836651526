export const THEME_COLORS = [
  '#FFB340',
  '#544E94',
  '#FF4040',
  '#CE007C',
  '#40C057',
  '#AE3EC9',
  '#D6336C',
  '#F0BBDD',
  '#3BC9DB',
  '#38D9A9',
  '#A9E34B',
  '#FFD43B',
  '#FFA94D',
  '#F08C00',
];
