import {createStyles, Image, Table, Text} from '@mantine/core';
import imageURL from '../assets/images/training-chart.jpg';

const useStyles = createStyles((theme) => ({
  table: {
    border: '1px solid #CED7DF',
    marginTop: theme.spacing.lg,
    '> thead': {
      backgroundColor: '#F5F5F5',
    },
    '& thead tr th': {
      padding: theme.spacing.sm,
    },
    '& tbody tr td': {
      padding: theme.spacing.sm,
      borderRight: '1px solid #CED7DF'
    }
  }
}));

export default function DocumentationLoss() {
  const {classes} = useStyles();

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        The model's prediction error is a combination of both localisation and classification errors, and this is called the loss. There are two losses generated during the training process: training loss and validation loss. The training loss is the error generated on the training set that the end user has labelled, and the validation loss is the error generated on labelled images ‘held out’ from the training set that the model has not been trained on. Validation exposes the model to these 'unseen' images on which it has not trained to test its performance. This approach ensures the generalisability of the AI to future data. The loss values should approach 0 over time.
      </Text>
      <Text size="sm">
        Each inference is accompanied by a report that captures the data output from the AI model.
      </Text>
      <Table
        className={classes.table}
      >
        <thead>
          <tr>
            <th>Chart Trend</th>
            <th>Meaning</th>
          </tr>
        </thead>
        <tbody>
          <tr >
            <td>Training Loss {">>"} Validation Loss and/or Training Loss is consistently decreasing</td>
            <td>
              <Text
                color="yellow"
              >
                Underfitting
              </Text>
            </td>
          </tr>
          <tr >
            <td>Validation Loss {">>"} Training Loss and/or Validation Loss begins increasing</td>
            <td>
              <Text
                color="red"
              >
                Overfitting
              </Text>
            </td>
          </tr>
          <tr >
            <td>Validation Loss ~ Training Loss and/or both have reached consistent minimum after loss decrease</td>
            <td>
              <Text
                color="blue"
              >
                Generalizable Model
              </Text>
            </td>
          </tr>
        </tbody>
      </Table>

      <Image
        src={imageURL}
        alt=""
        mb="md"
        mt="md"
      />
      <Text mb="lg">
        The model training progress may be visualised in the loss chart while the neural network is learning the image labels.
      </Text>
    </>
  )
}
