import {Alert, Button, createStyles, Select, Text} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useEffect, useState} from 'react';
import {DatasetType, loadDatasets} from '../app/features/datasets/slice';
import {createTrainingDataset} from '../app/features/inference/slice';
import {useAppDispatch} from '../app/hooks';
import * as urls from '../urls';

const useStyles = createStyles(theme => ({

  infoWrapper: {

    borderRight: 'dashed 1px #D6DBE1'
  },
  formWrapper: {
  },
  logo: {
    marginLeft: -20,
    marginBottom: -20,
  }
}));

interface Props {
  reportId: number;
  imageId?: number;
}

export default function FormInferenceImageImport(props: Props) {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const [datasets, setDatasets] = useState<{id: string; title: string}[]>([]);
  const [newDataset, setNewDataset] = useState<{id: '0', title: string}>({id: '0', title: ''});

  const [createdDataset, setCreatedDataset] = useState<{id: number; title: string} | null>(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(loadDatasets()).then(res => setDatasets(res.payload));
  }, []);

  const form = useForm({
    initialValues: {
      dataset: {
        'id': '-1',
        'title': '',
      },
      reportId: props.reportId,
    },
    validate: {
      dataset: value => value.title.trim().length === 0,
    }
  });
  const handleSubmit = (values: {dataset: {id: string; title: string;}; reportId: number}) => {
    setLoading(true);
    dispatch(createTrainingDataset({
      sessionId: values.reportId,
      datasetTitle: values.dataset.title,
      datasetId: values.dataset.id,
      imageId: props.imageId
    })).then(res => {
      setLoading(false);
      setCreatedDataset(res.payload);
    });
  };

  return (
    <div>
      {createdDataset ?
        <div>
          <Alert>
            Dataset {createdDataset.title} now contains annotations based on this inference report.
          </Alert>
          <Button
            fullWidth
            variant="light"
            size="sm"
            mt="md"
            component={'a'}
            href={urls.getDatasetsURL(DatasetType.training, createdDataset.id)}
          >
            Go to Dataset
          </Button>
        </div>
        :
        <div className={classes.formWrapper}>
          <Text
            size="sm"
            mt="lg"
            color="#3E4247"
            mb="lg"
          >
            This form will import inference results as annotation to training dataset with provided title.
          </Text>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Select
              searchable
              creatable
              mb="sm"
              getCreateLabel={(query) => `+ Create ${query} dataset`}
              onCreate={(query) => {
                const item = {value: '0', label: query};
                setNewDataset({id: '0', title: query});
                form.setFieldValue('dataset', {
                  'id': '0',
                  'title': query
                });
                return item;
              }}
              data={datasets.concat([newDataset]).map(ds => ({
                value: '' + ds.id,
                label: ds.title
              }))}
              value={form.values.dataset.id}
              label="Select or create dataset"
              placeholder="Type existing dataset name"
              nothingFound="No datasets matching provided query"
              onChange={v => {
                const [ds] = datasets.filter(ds => '' + ds.id === v);
                if (ds) {
                  form.setFieldValue('dataset', {
                    id: v as string,
                    title: ds.title
                  });
                }
              }}
            />
            <Button
              fullWidth
              loading={isLoading}
              color="green"
              type="submit"
            >
              Create Annotations
            </Button>
          </form>
        </div>
      }

    </div>
  )
}
