import {Card, createStyles} from '@mantine/core';
import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {loadUsers} from '../app/features/admin/slice';
import {useAppDispatch} from '../app/hooks';
const useStyles = createStyles(theme => ({

}));

export default function Console() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadUsers());
  }, []);
  return (
    <div>
      <Card>

      </Card>
      <Outlet />
    </div>
  )
}
