import {
  createStyles, Text
} from '@mantine/core';
import React from 'react';
import {AxisOptions, Chart} from "react-charts";



interface ChartData {
  label: string;
  secondaryAxisId?: string;
  data: {
    primary: number | string;
    secondary: number;
  }[];
}

interface Props {
  data: ChartData[];
  onPointSelect: (value: number) => void;
  selectedPoint: number;
}

const useStyles = createStyles(theme => ({
  root: {
    cursor: 'pointer',
    height: 400,
    position: 'relative',
  },
  exportFormWrapper: {
    padding: theme.spacing.sm,
    backgroundColor: '#F5F5F5',
    border: '1px solid #E7EAEE',
    borderRadius: 1,
    opacity: 1,
  },
  tooltip: {
    position: 'absolute',
  }
}));

export default function TrainingChart({data, onPointSelect}: Props) {
  const {classes} = useStyles();

  const [clickedCoords, setClickedCoords] = React.useState<null | {x: number; y: number, index: number}>(null)
  React.useEffect(() => {
    if (!clickedCoords) {
      return;
    }
  }, [clickedCoords]);

  const primaryAxis = React.useMemo<
    AxisOptions<typeof data[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );
  const secondaryAxes = React.useMemo<
    AxisOptions<typeof data[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: 'line',
      }
    ],
    []
  );

  return (
    <div
      className={classes.root}
    >

      <Chart
        options={{
          data: data,
          primaryAxis,
          tooltip: {
            show: true,
            invert: true,
            render: props => {
              return (
                <div
                  className={classes.exportFormWrapper}
                >
                  <Text>
                    <strong>Click to export model {props.focusedDatum?.primaryValue}</strong>
                  </Text>
                  {props.focusedDatum?.tooltipGroup?.map((group, index) =>
                    <Text key={index}>
                      {group.seriesLabel}: {group.secondaryValue.toFixed(2)}
                    </Text>
                  )}
                </div>
              )
            }
          },
          secondaryAxes,
          onClickDatum: (datum) => {
            if (datum?.originalDatum) {
              onPointSelect(datum.originalDatum.primary as number)
            }
            if (datum && datum.element) {
              setClickedCoords(() => ({
                x: datum.element!.getBoundingClientRect().left!,
                y: datum.element!.getBoundingClientRect().top!,
                index: datum.index
              }));
            };
          },
          onFocusDatum: (datum) => {

          },
        }}
      />
      {clickedCoords &&
        <div
          className={classes.tooltip}
          style={{
            left: clickedCoords.x - 25,
            top: 10,
            width: 2,
            backgroundColor: 'green',
            height: '90%'
          }}
        >

        </div>
      }
    </div>
  );
}
