import {Card, Center, createStyles} from '@mantine/core';
import React from 'react';
import {deleteLabel, editLabel, loadLabels} from '../app/features/labels/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import LabelsListForm from './LabelsListForm';

const useStyles = createStyles(theme => ({
  root: {
  },
  card: {
    width: '50%',
  }
}))

export default function PageLabels() {
  const {classes} = useStyles();
  const labels = useAppSelector(state => state.labels);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(loadLabels());
  }, [dispatch]);

  const handleLabelUpdate = ((data: {title: string; id: number}) => {
    return dispatch(editLabel(data));
  });
  const handleLabelDelete = (labelId: number) => dispatch(deleteLabel(labelId));

  return (
    <div className={classes.root}>
      <Center>
        <Card
          withBorder
          className={classes.card}
        >
          <LabelsListForm
            labels={labels}
            onLabelSubmit={handleLabelUpdate}
            onLabelDelete={handleLabelDelete}
          />
        </Card>
      </Center>
    </div>
  )
}
