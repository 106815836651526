import {Badge, createStyles} from '@mantine/core';
import infoIconURL from '../assets/icons/info.svg';

const useStyles = createStyles(theme => ({
  root: {
    textTransform: 'initial',
    fontWeight: 500,
    height: 36,
    cursor: 'pointer',
    paddingLeft: 8,
    background: '#544E94',
    color: '#fff',
  },
  icon: {
    verticalAlign: 'middle',
    marginBottom: 2,
    width: 16.67,
  },
}));

interface Props {
  onClick: () => void;
  ml?: number;
  mr?: number;
}


export default function ButtonQuickGuide(props: Props) {
  const {classes} = useStyles();
  return (
    <Badge
      size="lg"
      ml={props.ml}
      mr={props.mr}
      className={classes.root}

      leftSection={<img
        src={infoIconURL}
        alt=""
        className={classes.icon}
      />}
      onClick={props.onClick}
    >
      Quick Guide
    </Badge>
  )
}
