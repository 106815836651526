import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';


export interface TrainingSessionDetails {
  checkpoints: {
    filename: string;
    timestamp: number;
    step: number;
    loss: {
      loss: 0.4861290156841278,
      step: 2131
    }[];
    images: {
      filename: string;
      path: string;
      step: number;
      sample: number;
      url: string;
      timestamp: number;
    }[];
  }[];

  validation: {
    step: number;
    loss: number;
  }[];

  loss: {
    step: number;
    loss: number;
  }[];

  map: {
    step: number;
    loss: number;
  }[];
  is_running: boolean;
}

const loadSessionDetails = createAsyncThunk(
  "trainingSessions/details/load",
  async (sessionId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/training/sessions/details/${sessionId}/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);


const slice = createSlice({
  name: 'trainingSessionDetails',
  initialState: null as (TrainingSessionDetails | null),
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadSessionDetails.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

export {loadSessionDetails};

export default slice.reducer;
