import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';
import {addImageLabel, updateImageLabel} from '../imageLabels/slice';

interface Dataset {
  id: number;
  title: string;
  source: string;
  timestamp: string;
  predictor_enabled: boolean;
  type: 'INFERENCE' | 'TRAINING';
  unique_labels_count: number;
};

const loadDatasetDetails = createAsyncThunk(
  "datasetDetails/load",
  async (datasetId: number | string, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/datasets/${datasetId}`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const uploadImages = createAsyncThunk(
  "datasetDetails/uploadImages",
  async (data: {datasetId: number, images: File[]}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    const formData = new FormData();
    data.images.map(fl => formData.append("files", fl));

    return fetch(`/api/datasets/${data.datasetId}/images`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: formData
    });
  }
);

const slice = createSlice({
  name: 'datasetDetails',
  initialState: {
    id: 0,
    title: '',
    source: '',
    timestamp: '',
    type: 'INFERENCE',
    predictor_enabled: false,
    unique_labels_count: 0,
  } as Dataset,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadDatasetDetails.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(addImageLabel.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload.dataset,
      };
    });
    builder.addCase(updateImageLabel.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload.dataset,
      }
    });
  }
});

export {loadDatasetDetails, uploadImages};

export default slice.reducer;
