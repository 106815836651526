import {
  Button,
  Group,
  Popover,
  Text
} from '@mantine/core';
import {useState} from 'react';
import * as analytics from '../app/features/analytics';
import {startInference} from '../app/features/inference/slice';
import {goToNextStep, TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch} from '../app/hooks';
import InferenceStartForm from './InferenceStartForm';
import Tooltip from './Tooltip';

interface Props {
  modelsList: {
    id: number;
    title: string;
  }[];
  labels: {
    id: number;
    title: string;
  }[];
  disabled?: boolean;
  disableTooltip?: boolean;
  datasetId: number;
}

export default function InferenceStartControls(props: Props) {
  const [opened, setOpened] = useState(false);
  const dispatch = useAppDispatch();
  const handleStart = (modelId: number) => {
    analytics.logButtonClick({eventName: analytics.INFERENCE_START});
    dispatch(startInference({
      modelId,
      datasetId: props.datasetId,
    })).then(() => {
      dispatch(goToNextStep(TutorialComponent.SELECT_AI_MODEL));
    });
  };
  return (
    <Tooltip
      tutorialComponent={TutorialComponent.CLICK_START_INFERENCE_BUTTON}
      disabled={props.disableTooltip || opened}
      target={
        <Group>
          <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            position="bottom"
            width={240}
            withArrow
            transition="pop-top-right"
          >
            <Popover.Target>
              <Button
                onClick={() => {
                  setOpened((o) => !o);
                  dispatch(goToNextStep(TutorialComponent.CLICK_START_INFERENCE_BUTTON));
                }}
              >
                Start Inference
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Tooltip
                tutorialOnly
                position='right-start'
                offset={20}
                tutorialComponent={TutorialComponent.SELECT_AI_MODEL}
                target={
                  <div >
                    <InferenceStartForm
                      disabled={props.disabled}
                      labels={props.labels}
                      initialValues={{
                        model: '0'
                      }}
                      modelsList={props.modelsList}
                      onCancel={() => setOpened(false)}
                      onSubmit={(data) => {
                        handleStart(parseInt(data.model));
                        setOpened(false);
                      }}
                    />
                  </div>
                }
                content={
                  <Text size="sm">
                    Now you can select the AI model you just trained from the dropdown menu. To start the AI inference, select Confirm.
                  </Text>
                }
              />
            </Popover.Dropdown>
          </Popover>
        </Group>
      }
      content={
        <>
          <Text size="sm">
            To start applying your AI model to a new inference dataset, select Start Inference.
          </Text>
        </>
      }
    />
  );
}
