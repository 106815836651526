import {Button, createStyles, Group, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';

interface Props {
  initialValues?: {
    title: string;
  };
  onSubmit: (data: {title: string}) => Promise<any>;
  onCancelClick?: () => void;
}

const useStyles = createStyles(theme => ({
  formControls: {
    marginTop: theme.spacing.sm,
  }
}));

export default function LabelEditForm(props: Props) {
  const {classes} = useStyles();

  const form = useForm({
    initialValues: {
      title: "",
      ...props.initialValues
    },
    validate: {
      title: value => !value
    }
  });

  return (
    <div>
      <form onSubmit={form.onSubmit(props.onSubmit)}>
        <TextInput
          label="Label Title"
          placeholder='Input label title'
          {...form.getInputProps('title')}
        />
        <Group
          className={classes.formControls}
        >
          <Button
            type="submit"
          >
            Save
          </Button>
          {props.onCancelClick &&
            <Button
              type="button"
              variant="filled"
              color="gray"
              onClick={props.onCancelClick}
            >
              Cancel
            </Button>
          }
        </Group>
      </form>
    </div>
  )
}
