import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';
import {impersonate} from '../admin/slice';


export enum SubscriptionType {
  paid = 'paid',
  free = 'free',
};

interface AuthInfo {
  token: string;
  user_id: number;
  has_elab_access: boolean;
  subscription_details: {
    show_onboarding: boolean;
    subscription: SubscriptionType;
    trial_period_days_left: number;
  };
};

interface AuthCredentials {
  username: string;
  password: string;
};

const login = createAsyncThunk(
  "auth/login",
  (authCredentials: AuthCredentials, {rejectWithValue}) => {
    return apiClient.post('/api/auth/login', {
      email: authCredentials.username,
      password: authCredentials.password
    }).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("Wrong username or password.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const elabLogin = createAsyncThunk(
  "auth/elabLogin",
  (_: undefined, {rejectWithValue}) => {
    return apiClient.post('/api/auth/elab/login').then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("Wrong username or password.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const getAuthInfo = createAsyncThunk(
  "auth/getInfo",
  (_: undefined, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return apiClient.get('/api/auth/check', {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 401) {
        return rejectWithValue({
          error: true,
          reason: 'unauthenticated'
        })
      } else if (res.status === 403) {
        return rejectWithValue("Wrong username or password.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const toggleOnboardingPopup = createAsyncThunk(
  "auth/toggleOnboardingPopup",
  (_: undefined, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return apiClient.post('/api/auth/toggle-onboarding-popup/', {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 401) {
        return rejectWithValue({
          error: true,
          reason: 'unauthenticated'
        })
      } else if (res.status === 403) {
        return rejectWithValue("Wrong username or password.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const pricingRequest = createAsyncThunk(
  "auth/pricingRequest",
  (data: {email: string; name: string, requestType: string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return apiClient.post('/api/auth/pricing-request', data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 401) {
        return rejectWithValue({
          error: true,
          reason: 'unauthenticated'
        })
      } else if (res.status === 403) {
        return rejectWithValue("Wrong username or password.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    user_id: 0,
    has_elab_access: false,
    subscription_details: {
      subscription: SubscriptionType.free,
      trial_period_days_left: 14,
    },
  } as AuthInfo,
  reducers: {
    logout: () => {
      return {
        token: '',
        user_id: 0,
        has_elab_access: false,
        subscription_details: {
          subscription: SubscriptionType.free,
          show_onboarding: false,
          trial_period_days_left: 14,
        }
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
    builder.addCase(elabLogin.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });


    builder.addCase(getAuthInfo.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
    builder.addCase(toggleOnboardingPopup.fulfilled, (state, action) => {
      state.subscription_details.show_onboarding = false;
    });
    builder.addCase(getAuthInfo.rejected, (state, action) => {
      return {
        token: '',
        user_id: 0,
        has_elab_access: false,
        subscription_details: {
          show_onboarding: false,
          subscription: SubscriptionType.free,
          trial_period_days_left: 0,
        }
      }
    });
    // ADMIN ACTIONS
    builder.addCase(impersonate.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
  }
});

export const {logout} = slice.actions;
export {login, getAuthInfo, elabLogin, pricingRequest, toggleOnboardingPopup};

export default slice.reducer;
