import {createStyles} from '@mantine/core';


interface Props {
  top: number;
  left: number;
  width: number;
  height: number;
  imageURL: string;
  imageId: number;
  zoomOnHover?: boolean;
  boundingBoxPadding?: number;
  onMouseEnter?: () => void;
}


const useStyles = createStyles(theme => ({
  imageZoom: {
    '&:hover': {
      transform: 'scale(3)',
    }
  },
}));


export default function ClassificationPreview({zoomOnHover = true, boundingBoxPadding = 0, ...props}: Props) {
  const {classes, cx} = useStyles();

  return (
    <div
      className={cx(zoomOnHover && classes.imageZoom)}
      onMouseEnter={props.onMouseEnter}
      style={{
        cursor: 'pointer',
        width: props.width + boundingBoxPadding * 2,
        height: props.height + boundingBoxPadding * 2,
        backgroundImage: `url(${props.imageURL})`,
        backgroundPosition: `-${props.left - boundingBoxPadding}px -${props.top - boundingBoxPadding}px`,
        backgroundRepeat: 'no-repeat',
      }}
    >

    </div>
  );
}
