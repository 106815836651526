import { ActionIcon, Button, Card, createStyles, RangeSlider, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import closeIconImageURL from '../assets/icons/close-dark.svg';

const useStyles = createStyles(theme => ({
  filtersWrapper: {
    position: 'relative'
  },
  removeButton: {
    position: 'absolute',
    top: theme.spacing.sm * 2,
    right: 4,
    opacity: .5,
    "&:hover": {
      opacity: .8,
    }
  },
  title: {
    backgroundColor: "#f0f0f0",
    padding: theme.spacing.xs
  }
}));

type RangeValue = [number, number];

export interface ParticlesFilter {
  key: string;
  ecd: RangeValue;
  perimeter: RangeValue;
  area: RangeValue;
  mean: RangeValue;
  std: RangeValue;
  aspectratio: RangeValue;
  circularity: RangeValue;
};

export interface FormValues {
  canny: RangeValue;
  filters: ParticlesFilter[];
}

interface Props {
  onChange: (values: FormValues) => void;
  showCanny?: boolean;
  initialValues?: FormValues;
}

export default function ParticlesFilterForm(props: Props) {
  const { classes } = useStyles();

  const unit = '(px)';

  const form = useForm<FormValues>({
    initialValues: props.initialValues
  });

  return (
    <form onSubmit={form.onSubmit(props.onChange)}>
      {!!props.showCanny &&
        <>
          <Text mb="sm">Sensitivity Threshold</Text>
          <RangeSlider
            {...form.getInputProps('canny')}
            min={0}
            max={200}
            mb="lg"
          />
        </>
      }
      {form.values.filters.map((filter, index) =>
        <Card
          key={filter.key}
          mt="md"
          className={classes.filtersWrapper}
        >
          {index > 0 &&
            <ActionIcon
              className={classes.removeButton}
              onClick={() => {
                form.removeListItem('filters', index)
              }}
            >
              <img
                alt=""
                src={closeIconImageURL}
              />
            </ActionIcon>
          }
          <Card.Section>
            <Title className={classes.title} order={5}>
              Filters for Report
            </Title>
            <Text mb="xs" mt="lg">ECD {unit}</Text>
            <RangeSlider
              minRange={1}
              min={0}
              max={255}
              {...form.getInputProps(`filters.${index}.ecd`)}
            />
            <Text mb="xs" mt="lg">Perimeter {unit}</Text>
            <RangeSlider
              minRange={1}
              min={0}
              max={8000}
              {...form.getInputProps(`filters.${index}.perimeter`)}
            />
            <Text mb="xs" mt="lg">Area {unit}</Text>
            <RangeSlider
              minRange={1}
              min={0}
              max={8000}
              {...form.getInputProps(`filters.${index}.area`)}
            />
            <Text mb="xs" mt="lg">Intensity Mean {unit}</Text>
            <RangeSlider
              minRange={1}
              min={0}
              max={255}
              {...form.getInputProps(`filters.${index}.mean`)}
            />
            <Text mb="xs" mt="lg">Intensity Standard Deviation {unit}</Text>
            <RangeSlider
              minRange={1}
              min={0}
              max={255}
              {...form.getInputProps(`filters.${index}.std`)}
            />
            <Text mb="xs" mt="lg">Aspect Ratio</Text>
            <RangeSlider
              {...form.getInputProps(`filters.${index}.aspectratio`)}
              min={0}
              max={1}
              minRange={.1}
              step={.1}
              label={v => v.toFixed(2)}
            />
            <Text mb="xs" mt="lg">Circularity</Text>
            <RangeSlider
              {...form.getInputProps(`filters.${index}.circularity`)}
              min={0}
              minRange={.1}
              max={1}
              step={.1}
              label={v => v.toFixed(2)}
            />
          </Card.Section>
        </Card>
      )}
      <Button
        mt="lg"
        fullWidth
        onClick={() => {
          props.onChange(form.values);
        }}
      >
        Apply Filters
      </Button>
    </form>
  )
}
