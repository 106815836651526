import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export const DATASET_TYPES = [
  ['TRAINING', 'Training'],
  ['INFERENCE', 'Inference'],
  ['CALIBRATION', 'Calibration',]
];

export enum DatasetType {
  training = 'TRAINING',
  inference = 'INFERENCE',
  calibration = 'CALIBRATION',
}

export interface Dataset {
  id: number;
  title: string;
  import_in_progress: boolean;
  source: string;
  dataset_title: string;
  timestamp: string;
  type: DatasetType;
};


const saveDataset = createAsyncThunk(
  "datasets/add",
  async (data: Partial<Dataset>, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post('/api/datasets/', data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const updateDataset = createAsyncThunk(
  "datasets/update",
  async (data: ({id: number} & Partial<Dataset>), {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return apiClient.patch(`/api/datasets/${data.id}/`, data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadDatasets = createAsyncThunk(
  "datasets/load",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/datasets/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const deleteDataset = createAsyncThunk(
  "datasets/delete",
  async (datasetId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.remove(`/api/datasets/${datasetId}/`, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'datasets',
  initialState: [] as Dataset[],
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(saveDataset.fulfilled, (state, action) => {
      state.unshift(action.payload);
    });
    builder.addCase(loadDatasets.fulfilled, (state, action) => {
      return action.payload;
    });

    builder.addCase(updateDataset.fulfilled, (state, action) => {
      return state.map(ds => ds.id === action.payload.id ? action.payload : ds);
    });
    builder.addCase(deleteDataset.fulfilled, (state, action) => {
      return state.filter(ds => ds.id !== action.payload.id);
    });
  }
});

export {saveDataset, loadDatasets, updateDataset, deleteDataset};

export default slice.reducer;
