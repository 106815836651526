import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';


export interface HistogramData {
  values: number[];
  bucket_ranges: number[];
}

export interface HistogramDataDict {
  combined: {
    ecd: HistogramData;
    max_feret: HistogramData;
  };
  by_classification: {
    [key: string]: ({
      classification: string;
    } & HistogramData)[];
  }
}
export interface ClassificationStats {
  classification: string;
  count: number;
  percentage: number;
  avg_area: number;
  avg_ecd: number;
  avg_feret: number;
  avg_aspect: number;
  avg_circularity: number;
  stdev_ecd: number;
  stdev_feret: number;
  stdev_area: number;
  stdev_aspect_ratio: number;
  stdev_circularity: number;
}

export interface Intersection {
  classification: string;
  children: {
    classification: string;
    average: number;
    total: number;
  }[];
}

export interface ParticleStats {
  histograms: HistogramDataDict;
  data_by_classifications: ClassificationStats[];
  intersections: Intersection[];
  date_started: string;
  model: {
    id: number;
    title: string;
  };
  dataset: {
    title: string;
    date: string;
  };
  images: {
    id: number;
    url: string;
    original_image: string;
  }[];

};

const loadSessionsComparison = createAsyncThunk(
  "inference/session/comparison",
  async ({sessions}: {sessions: number[]}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return apiClient.get(`/api/inference/sessions/compare?${sessions.map(s => 'session=' + s).join('&')}`, undefined,
      state.auth.token).then(res => {
        if (res.status === 200) {
          return res.json().then(res => {
            return res;
          });
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

const getDefaultState = (): ParticleStats => ({
  images: [],
  histograms: {
    combined: {
      ecd: {
        values: [],
        bucket_ranges: [],
      },
      max_feret: {
        values: [],
        bucket_ranges: [],
      },
    },
    by_classification: {
      ecd: [],
      max_feret: []
    }
  },
  data_by_classifications: [],
  intersections: [],
  date_started: '',
  model: {
    id: 0,
    title: ''
  },
  dataset: {
    title: '',
    date: ''
  },
});

const slice = createSlice({
  name: 'reportsComparison',
  initialState: {
    sessionA: getDefaultState(),
    sessionB: getDefaultState(),
    all_stats: [] as {
      id: number;
      x: number;
      y: number;
      classification: string;
      width: number;
      height: number;
      is_stuck: boolean;
      ecd: number;
      max_feret: number;
      area: number;
      aspect_ratio: number;
      perimeter: number;
      image: string;
    }[],
  },
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadSessionsComparison.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

export {loadSessionsComparison};

export default slice.reducer;
