import {ContextModalProps} from '@mantine/modals';
import FormInferenceImageImport from './FormInferenceImageImport';


export default function InferenceReportConvertionModal({innerProps}: ContextModalProps<{reportId: number}>) {
  return (
    <FormInferenceImageImport
      reportId={innerProps.reportId}
    />
  )
}
