import {Button, createStyles, Group, keyframes, Popover, Tabs, Text} from '@mantine/core';
import {clamp, useDebouncedState, useHotkeys, useMove, useResizeObserver} from '@mantine/hooks';
import {useEffect, useMemo, useState} from 'react';
import {addImageLabel, Filters, loadImageLabels, loadPredictions, savePredictions} from '../app/features/imageLabels/slice';
import {Label} from '../app/features/labels/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import LabelForm from './LabelForm';
import ObjectsFilter from './ObjectsFilter';
import PredictorLabelsList, {LabelProps} from './PredictorLabelsList';

const animatedBorderHeight = 1;
const animatedBorderWidth = 10;
const animationSpeed = 3000;
const animatedBorderColors = ['black', 'white'];

export const lassoBorder = keyframes(`
0% {
  background-position: 0 0, 50px 100%, 0 50px, 100% 0;
}
100% {
  background-position: 50px 0, 0 100%, 0 0, 100% 50px;
}`);


const useStyles = createStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    flex: 1,
  },
  zoomCanvas: {
    cursor: 'zoom-in',
  },
  drawRect: {
    position: 'absolute',
    animation: `${lassoBorder} ${animationSpeed}ms infinite linear`,
    backgroundImage: `linear-gradient(90deg, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%), linear-gradient(90deg, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%), linear-gradient(0, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%), linear-gradient(0, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%)`,
    backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
    backgroundSize: `${animatedBorderWidth}px ${animatedBorderHeight}px, ${animatedBorderWidth}px ${animatedBorderHeight}px, ${animatedBorderHeight}px ${animatedBorderWidth}px, ${animatedBorderHeight}px ${animatedBorderWidth}px`,
  },
  boxLabel: {
    position: 'absolute',
    bottom: '100%',
    left: 0,
  },
  labelRect: {
    position: 'absolute',
    border: `solid 1px ${theme.colors.blue[4]}`,
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.colors.blue[8],
    }
  },
  warningLabel: {
    borderColor: theme.colors.red[8],
  },
  predictedLabel: {
    borderColor: theme.colors.orange[5],
  },
  predictedLabelText: {
    backgroundColor: theme.colors.orange[5],
    color: '#fff',
  },
  rectZooming: {
    pointerEvents: 'none',
  },
  labelText: {
    display: 'inline-block',
    position: 'absolute',
    bottom: '100%',
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
  },
  image: {
  },
  filtersWrapper: {
    width: 240,
  },
  tabPanel: {
    overflow: 'auto',
    padding: theme.spacing.sm,
    border: `solid 1px ${theme.colors.gray[3]}`,
    borderTop: 'none',
  },
  activeTabButton: {
    backgroundColor: '#F5F5F5',
    color: '#000',
  }
}));

interface Props {
  height: number | string;
  labels: Label[];
  zoom?: number;
  onImageDimensionsChange?: (size: {width: number; height: number}) => void;
  image: {
    id: number;
    file: string;
  };
  dataset: {
    id: number;
  };
  disableSearch?: boolean;
  onZoomChange: (zoom: number) => void;
  showToolbox?: boolean;
  visibleLabels?: number[];
}

export default function AnnotationCanvas({
  image,
  dataset,
  labels,
  disableSearch,
  showToolbox = true,
  zoom = 1,
  onZoomChange,
  onImageDimensionsChange = () => { },
  visibleLabels = [],
  ...props
}: Props) {
  const {classes, cx} = useStyles();
  const dispatch = useAppDispatch();

  const {
    imageLabels: {
      imageLabels,
      dataset: {
        predictorProgress
      }
    },

  } = useAppSelector(state => state);

  const [value, setValue] = useState({x: 0, y: 0});
  const {ref, active} = useMove(setValue);
  const [viewportRef, viewportRect] = useResizeObserver();
  const [startCoords, setStartCoords] = useState({x: 0, y: 0});
  const [activeLabel, setActiveLabel] = useState('');
  const [isZooming, setZooming] = useState(false);
  const [isLoadingLabels, setLoadingLabels] = useState(false);
  const [highlightedLabel, setHighlightedLabel] = useState('');
  const [zoomCenter, setZoomCenter] = useDebouncedState([0, 0], 200);
  const [recentlyUsedLabel, setRecentlyUsedLabel] = useState('0');
  const [activeTab, setActiveTab] = useState('filters');

  const [tempRect, setTempRect] = useState<
    {
      top: number;
      left: number;
      width: number;
      height: number;
      label?: string;
    } | null
  >(null);

  const [filters, setFilters] = useState<Filters>({
    sensitivity: [20, 49],
    ecd: [
      [0, 255],
    ],
    perimeter: [[0, 16000]],
    area: [[0, 16000]],
    intencity_std: [[0, 255]],
    intencity_mean: [[0, 255]],
    aspectratio: [[0, 1]],
    circularity: [[0, 1]],
  });


  const handleLabelSubmitSuccess = (labelId: string) => {
    setRecentlyUsedLabel(labelId);
    setTempRect(null);
    setActiveLabel('');
  };

  const handleLabelSelect = (positionalId: string) => {
    setActiveLabel(positionalId);
  };
  const handleLabelSelectCancel = () => {
    setActiveLabel('');
  };

  useEffect(() => {
    setZoomCenter([
      (viewportRect.width / 2) / ref.current.scrollWidth,
      (viewportRect.height / 2) / ref.current.scrollHeight,
    ]);
  }, [viewportRect]);
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const annotatorLabels = useMemo(() => {
    if (imageSize === null) {
      return []
    };

    return imageLabels.filter(
      il => !il.is_deleted &&
        (visibleLabels.length > 0 ? visibleLabels.indexOf(il.label) !== -1 : true)).map(il => {
          const lbl = labels.find(l => l.id === il.label);
          return {
            ...il,
            labelTitle: lbl?.title,
            top: (il.top / imageSize.height),
            left: (il.left / imageSize.width),
            width: (il.width / imageSize.width),
            height: (il.height / imageSize.height),

            absTop: il.top,
            absLeft: il.left,
            absWidth: il.width,
            absHeight: il.height,
          };
        })
  }, [imageLabels, imageSize, labels, visibleLabels]);

  const getMouseCoords = (e: React.MouseEvent<HTMLDivElement>): [number, number] => {
    if (e.currentTarget) {
      const x = e.clientX;
      const y = e.clientY;
      const rect = ref.current.getBoundingClientRect();
      const _x = clamp((x - rect.left) / rect.width, 0, 1);
      const _y = clamp((y - rect.top) / rect.height, 0, 1);
      return [_x, _y];
    };
    return [0, 0];
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const [_x, _y] = getMouseCoords(e);
      setStartCoords({
        x: _x,
        y: _y
      });
      setValue({
        x: _x,
        y: _y,
      });
    };
  };

  useEffect(() => {
    let isMounted = true;
    const img = new Image();
    onZoomChange(1);
    img.onload = function () {
      if (isMounted) {
        setImageSize({
          width: img.width,
          height: img.height,
        });
        onImageDimensionsChange(img);
      }
    };
    img.src = image.file;
    return () => {
      isMounted = false;
    };
  }, [image.file]);

  const handlePredictionsLoad = () => {
    return dispatch(loadPredictions({
      dataset: dataset.id,
      imageId: image.id,
      filters,
    }));
  };

  const handlePredictionsSave = () => {
    return dispatch(savePredictions({
      dataset: dataset.id,
      imageId: image.id,
      imageLabels: imageLabels.filter(il => il.is_predicted),
    }));
  };

  useEffect(() => {
    setLoadingLabels(true);
    const request = dispatch(loadImageLabels({
      dataset: dataset.id,
      imageId: image.id,
      disable_search: disableSearch,
      filters,
    }));
    request.then(() => {
      setLoadingLabels(false);
    });
    return () => {
      request.abort();
    };
  }, [filters, image.id]);

  const scrollToPoint = (point: number[], behavior?: string) => {
    viewportRef.current.scrollTo({
      left: viewportRef.current.scrollWidth * point[0] - (viewportRect.width / 2),
      top: viewportRef.current.scrollHeight * point[1] - (viewportRect.height / 2),
      behavior,
    });
  };

  useEffect(() => {
    scrollToPoint(zoomCenter);
  }, [zoom]);

  const handlePredictorHover = (il: LabelProps) => {
    setHighlightedLabel(il.positional_id);
    setTimeout(() => {
      scrollToPoint([il.left + il.width / 2, il.top + il.height / 2]);
    }, 0);
    onZoomChange(
      .125 / Math.max(il.width, il.height)
    );
  };

  const toggleZoomMode = () => {
    setZooming(!isZooming);
  };

  const handleZoomIn = () => {
    onZoomChange(zoom + 1);
  };

  const handleZoomOut = () => {
    if (zoom !== 1) {
      onZoomChange(zoom - 1);
    }
  };

  const handleReset = () => {
    onZoomChange(1)
  };

  useHotkeys([
    ['mod+Equal', handleZoomIn],
    ['mod+Add', handleZoomIn],
  ]);

  useHotkeys([
    ['mod+-', handleZoomOut],
    ['mod+Substract', handleZoomOut],
  ]);

  useHotkeys([
    ['mod+0', handleReset],
    ['mod+Zero', handleReset],
  ]);

  useHotkeys([
    ['z', toggleZoomMode],
  ]);
  const handleTempBoxCancel = () => {
    setTempRect(null);
  };

  useEffect(() => {
    if (!active) {
      if (isZooming) {
        if (
          Math.abs(startCoords.y - value.y) < .003 ||
          Math.abs(startCoords.x - value.x) < .003
        ) {
          handleZoomIn();
          setTimeout(() => {
            scrollToPoint([startCoords.x, startCoords.y]);
          }, 0);
        }
        else {
          const [width, height] = [
            value.x - startCoords.x,
            value.y - startCoords.y,
          ]
          setTimeout(() => {
            scrollToPoint([
              startCoords.x + (value.x - startCoords.x) / 2,
              startCoords.y + (value.y - startCoords.y) / 2,
            ]);
          }, 0);
          onZoomChange(
            .8 / Math.max(width, height)
          );
        }
      } else {
        if (
          Math.abs(startCoords.y - value.y) > .003 ||
          Math.abs(startCoords.x - value.x) > .003
        ) {
          const coords = {
            top: startCoords.y,
            left: startCoords.x,
            width: (value.x - startCoords.x),
            height: (value.y - startCoords.y),
            label: recentlyUsedLabel,
          };
          setTempRect(coords);
        }
      }
    }
  }, [active]);

  return (
    <Group
      spacing={8}
    >
      <div
        ref={viewportRef}
        className={cx(classes.root, isZooming && classes.zoomCanvas)}
        onMouseDown={handleMouseDown}
        style={{
          height: props.height,
          flexGrow: 2,
        }}
        onScroll={e => {
          const sL = (e.currentTarget.scrollLeft + viewportRect.width / 2) / e.currentTarget.scrollWidth;
          const sT = (e.currentTarget.scrollTop + viewportRect.height / 2) / e.currentTarget.scrollHeight;
          setZoomCenter([
            sL,
            sT,
          ]);
        }}
      >
        <div
          ref={ref}
          style={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <img
            src={image.file}
            alt=""
            className={classes.image}
            style={{
              pointerEvents: 'none',
              width: `${viewportRect.width * zoom}px`,
            }}
          />
          {active &&
            <div
              className={classes.drawRect}
              style={{
                top: `${startCoords.y * 100}%`,
                left: `${startCoords.x * 100}%`,
                width: `${(value.x - startCoords.x) * 100}%`,
                height: `${(value.y - startCoords.y) * 100}%`,
              }}
            >
              {value.x - startCoords.x > 0 && value.y - startCoords.y > 0 &&
                <Text
                  className={classes.boxLabel}
                  size="xs"
                >
                  {Math.round((value.x - startCoords.x) * imageSize!.width)}x{Math.round((value.y - startCoords.y) * imageSize!.height)}
                </Text>
              }
            </div>
          }
          {annotatorLabels.map(label => (
            <div
              title={`ECD: ${(label.ecd || 0).toFixed(2)} (um)\nArea: ${(label.area || 0).toFixed(2)} (px)`}
              key={label.positional_id}
              className={cx(
                classes.labelRect,
                highlightedLabel === label.positional_id && classes.drawRect,
                label.is_predicted && classes.predictedLabel,
                isZooming && classes.rectZooming,
              )}
              style={{
                top: label.top * 100 + '%',
                left: label.left * 100 + '%',
                width: label.width * 100 + '%',
                height: label.height * 100 + '%',
              }}
              onClick={(e) => {
                if ((e.metaKey || e.shiftKey) && recentlyUsedLabel) {
                  dispatch(addImageLabel({
                    dataset: dataset.id,
                    imageId: image.id,
                    imageLabel: {
                      width: label.absWidth,
                      height: label.absHeight,
                      top: label.absTop,
                      left: label.absLeft,
                      label: recentlyUsedLabel,
                    },
                  }));
                } else {
                  handleLabelSelect(label.positional_id);
                }
              }}
            >
              {label.labelTitle &&
                <Text
                  className={cx(classes.labelText, label.is_predicted && classes.predictedLabelText)}
                  size="xs"
                  style={{
                    fontSize: '80%',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {label.labelTitle}{label.is_predicted ? '?' : ''}
                </Text>
              }
              {label.positional_id === activeLabel &&
                <Popover
                  withArrow
                  withinPortal
                  width={200}
                  position="bottom-end"
                  positionDependencies={[zoomCenter]}
                  defaultOpened={true}
                  onClose={handleLabelSelectCancel}
                >
                  <Popover.Target>
                    <div style={{width: '100%', height: '100%', }} />
                  </Popover.Target>
                  <Popover.Dropdown>
                    <LabelForm
                      onSubmitSuccess={handleLabelSubmitSuccess}
                      labels={labels}
                      imageId={image.id}
                      datasetId={dataset.id}
                      initialValues={{
                        width: label.absWidth,
                        height: label.absHeight,
                        top: label.absTop,
                        left: label.absLeft,
                        positional_id: label.positional_id,
                        label: label.label || recentlyUsedLabel,
                      }}
                    />
                  </Popover.Dropdown>
                </Popover>
              }
            </div>
          ))}
          {tempRect !== null &&
            <div
              className={cx(
                classes.labelRect,
                isZooming && classes.rectZooming,
              )}
              style={{
                top: tempRect.top * 100 + '%',
                left: tempRect.left * 100 + '%',
                width: tempRect.width * 100 + '%',
                height: tempRect.height * 100 + '%',
              }}

            >
              <Popover
                withArrow
                withinPortal
                width={200}
                position="bottom-end"
                positionDependencies={[zoomCenter]}
                defaultOpened={true}
                onClose={handleTempBoxCancel}
              >
                <Popover.Target>
                  <div style={{width: '100%', height: '100%', }} />
                </Popover.Target>
                <Popover.Dropdown>
                  <LabelForm
                    onSubmitSuccess={handleLabelSubmitSuccess}
                    labels={labels}
                    imageId={image.id}
                    datasetId={dataset.id}
                    initialValues={{
                      positional_id: `0:0:0:0`,
                      width: tempRect.width * imageSize!.width,
                      height: tempRect.height * imageSize!.height,
                      top: tempRect.top * imageSize!.height,
                      left: tempRect.left * imageSize!.width,
                      label: recentlyUsedLabel,
                    }}
                  />
                </Popover.Dropdown>
              </Popover>
            </div>
          }
        </div>
      </div>

      {showToolbox &&
        <div
          className={classes.filtersWrapper}
        >
          <Button.Group
          >
            <Button
              fullWidth
              onClick={() => setActiveTab('filters')}
              variant="default"
              className={cx(activeTab === 'filters' && classes.activeTabButton)}
            >
              Filters
            </Button>
            <Button
              fullWidth
              onClick={() => setActiveTab('predictor')}
              variant="default"
              className={cx(activeTab === 'predictor' && classes.activeTabButton)}
            >
              Predictor
            </Button>
          </Button.Group>
          <Tabs
            value={activeTab}
            defaultValue="filters"
          >
            <Tabs.Panel
              value="filters"
              p={8}
              className={classes.tabPanel}
              style={{
                height: viewportRect.height - 60,
              }}
            >
              <ObjectsFilter
                initialValues={filters}
                onChange={setFilters}
              />
            </Tabs.Panel>
            <Tabs.Panel
              value="predictor"
              pt="xs"
              className={classes.tabPanel}
              style={{
                height: viewportRect.height - 40,
              }}
              onMouseLeave={() => {
                setHighlightedLabel('');
              }}
            >
              <PredictorLabelsList
                predictorProgress={predictorProgress}
                height={viewportRect.height - 150}
                imageLabels={annotatorLabels}
                labels={labels}
                onLabelSubmitSuccess={setRecentlyUsedLabel}
                onHover={handlePredictorHover}
                imageId={image.id}
                datasetId={dataset.id}
                imageURL={image.file}
                recentLabel={recentlyUsedLabel}
                loadPredictions={handlePredictionsLoad}
                savePredictions={handlePredictionsSave}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      }
    </Group>
  )
}
