import {
  ActionIcon,
  Alert,
  Card,
  createStyles,
  Group,
  Image,
  Modal,
  Skeleton,
  Text,
  Title
} from '@mantine/core';
import React from 'react';
import {useParams} from 'react-router-dom';
import * as analytics from '../app/features/analytics';
import {completeTutorial, goToNextStep, startTutorial, TutorialComponent} from '../app/features/quick-guide/slice';
import {exportCheckpointModel, loadModelsList} from '../app/features/training/modelsList';
import {loadSessionDetails} from '../app/features/training/sessionDetails';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import infoIconImageURL from '../assets/icons/info-dark.svg';
import ExportModelButton from './ExportModelButton';
import ExportModelForm from './ExportModelForm';
import Tooltip from './Tooltip';
import TrainingChart from './TrainingChart';


const useStyles = createStyles(theme => ({
  root: {
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
  },
  controls: {
    paddingBottom: theme.spacing.sm,
  },
  alertControls: {
    marginTop: theme.spacing.sm
  },
  imageWrapper: {
    position: 'relative',
    marginBottom: theme.spacing.sm
  },
  imageDivider: {
    position: 'absolute',
    left: 'calc(50% - 3px)',
    top: 0,
    width: 3,
    height: '100%',
    backgroundColor: theme.colors.blue[4],
    zIndex: 1,
  },
  validationImagesWrapper: {
    marginTop: theme.spacing.md
  },
  loader: {
    marginTop: theme.spacing.md
  },
  skeleton: {
    marginBottom: theme.spacing.xs,
  },
  chartCard: {
  },
  validationImagesTitles: {
    marginBottom: theme.spacing.md
  },
  infoIcon: {
    display: 'inline-block',
    marginLeft: 4,
    verticalAlign: 'text-top',
  }
}));

export default function PageTrainingSession() {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const {sessionId = '1'} = useParams<{sessionId: string}>();
  const [selectedPoint, setPoint] = React.useState(0);
  const [isLoading, setLoading] = React.useState(true);
  const [isExportModalOpen, setExportModalOpen] = React.useState(false);
  const [isModelExported, setModelExported] = React.useState(false);

  const chartData = useAppSelector(state => state.trainingSessionDetails ? state.trainingSessionDetails?.validation.map(l => ({
    step: l.step,
    validation: l.loss,
    map: state.trainingSessionDetails?.map.find(v => v.step === l.step)?.loss!,
    loss: state.trainingSessionDetails?.loss.find(v => v.step === l.step)?.loss!
  })) : []);

  const checkpoints = useAppSelector(state => state.trainingSessionDetails?.checkpoints || []);
  const isRunning = useAppSelector(state => state.trainingSessionDetails?.is_running);
  const handleExportStart = () => {
    setExportModalOpen(true);
    dispatch(goToNextStep(TutorialComponent.CLICK_EXPORT_MODEL_BUTTON));
  };

  const handleExportModalClose = () => {
    setExportModalOpen(false);
  };

  const exportModel = (title: string) => {
    analytics.logButtonClick({eventName: analytics.EXPORT_MODEL});
    return dispatch(exportCheckpointModel({
      title,
      checkpoint: selectedPoint,
      session: parseInt(sessionId, 10)
    })).then(() => {
      setModelExported(true);
    });
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadModelsList());
    dispatch(loadSessionDetails(
      parseInt(sessionId, 10)
    )).then((res) => {
      setLoading(false);
      if (res.payload.checkpoints.length) {
        setPoint(res.payload.checkpoints[0].step);
      }
      dispatch(completeTutorial('training-sessions-list'));
      dispatch(startTutorial('training-session'));
    });
  }, [sessionId, dispatch]);
  return (
    <div className={classes.root}>
      <Modal
        opened={isExportModalOpen}
        onClose={handleExportModalClose}
      >
        <ExportModelForm
          onSubmit={exportModel}
          isModelExported={isModelExported}
        />
      </Modal>
      {isLoading &&
        <div className={classes.loader}>
          <Skeleton
            width={309}
            height={36}
            className={classes.skeleton}
          />
          {[1, 2, 3, 4, 5, 6].map(i =>
            <Skeleton
              key={i}
              radius={0}
              height={20}
              className={classes.skeleton}
            />
          )}
        </div>
      }
      {!isLoading && chartData.length > 0 &&
        <>
          {isRunning &&
            <Alert color="yellow">This training session is still in progress.</Alert>
          }
          <Tooltip
            tutorialOnly
            tutorialComponent={TutorialComponent.VIEW_TRAINING_CHART}
            position="top-start"
            learnMoreLink='q-training-chart'
            content={
              <>
                <Text size="sm">
                  Click directly on different points on the chart to evaluate the model’s
                  performance over time.
                  Choose a model to export by clicking on the
                  chart and selecting ‘Export model’ button on top left corner.
                </Text>
              </>
            }
            target={
              <Card
                className={classes.chartCard}
                shadow="md"
              >
                <Group align="flex-end" className={classes.controls}>
                  <ExportModelButton
                    disabled={!selectedPoint}
                    onClick={handleExportStart}
                    selectedPoint={selectedPoint}
                  />
                </Group>

                <TrainingChart
                  data={[
                    {
                      label: "Validation",
                      data: chartData.filter(d => d.validation).map(d => ({
                        primary: '' + d.step,
                        secondary: d.validation,
                      }))
                    },
                    {
                      label: "Loss",
                      data: chartData.filter(d => d.validation).map(d => ({
                        primary: '' + d.step,
                        secondary: d.loss
                      }))
                    },
                    {
                      label: "Mean Average Precision",
                      data: chartData.filter(d => d.validation).map(d => ({
                        primary: '' + d.step,
                        secondary: d.map,
                      }))
                    }
                  ]}
                  selectedPoint={selectedPoint}
                  onPointSelect={(point: any) => {
                    setPoint(parseInt(point, 10));
                    analytics.logButtonClick({eventName: analytics.CHECKPOINT_BUTTON});
                    dispatch(goToNextStep(TutorialComponent.VIEW_TRAINING_CHART));
                  }}
                />
              </Card>}
          />
        </>
      }
      {checkpoints.filter(checkpoint => checkpoint.step === selectedPoint).map(checkpoint =>
        <div key={checkpoint.step} className={classes.validationImagesWrapper}>
          <Card>
            <Group position="apart" className={classes.validationImagesTitles}>
              <div>
                <Title order={4}>
                  Training AI Output
                  <Tooltip
                    tutorialOnly
                    tutorialComponent={TutorialComponent.VALIDATION_IMAGE_INFO}
                    learnMoreLink='q-validation-images'
                    target={
                      <ActionIcon variant="transparent" className={classes.infoIcon} size="sm">
                        <img src={infoIconImageURL} alt="" />
                      </ActionIcon>
                    }
                    content={
                      <>
                        <Text size="sm">
                          Review each pair of validation images; they should become nearly identical as the training progresses.
                        </Text>
                      </>
                    }
                  />
                </Title>
                <Text color="#3E4247">
                  Machine learning output scores
                </Text>
              </div>
              <div>
                <Title order={4}>
                  Labelled Validation Images
                </Title>
                <Text>
                  Provided annotations
                </Text>
              </div>
            </Group>
            <Tooltip
              tutorialOnly
              tutorialComponent={TutorialComponent.VIEW_VALIDATION_IMAGES}
              learnMoreLink="q-validation-images"
              onNextClick={() => {
                dispatch(goToNextStep(TutorialComponent.VIEW_VALIDATION_IMAGES));
              }}
              target={
                <div>
                  {checkpoint.images.map((image, index) =>
                    <div
                      key={index}
                      className={classes.imageWrapper}
                      id={(index === 0 && "validation-images-wrapper") || undefined}
                    >
                      <div className={classes.imageDivider} />
                      <Image
                        src={image.url}
                      />
                    </div>
                  )}
                </div>
              }
              content={
                <>
                  <Text size="sm">
                    Review each pair of validation images; they should become nearly
                    identical as the training progresses.
                  </Text>
                </>
              }
            />
          </Card>
        </div>
      )}
    </div>
  );
}
