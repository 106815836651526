import {createStyles} from '@mantine/core';
import {useResizeObserver, useViewportSize} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';


const useStyles = createStyles(theme => ({
  root: {
    overflow: 'auto',
    position: 'relative',
    width: '100%',
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  }
}));

interface Props {
  src: string;
  showOriginal?: boolean;
  onScaleChange?: (scale: number) => void;
  zoom?: number;
  height?: string | number;
  onLoad?: () => void;
  children?: React.ReactNode;
  lockScroll?: boolean;
  onSizeChange?: (size: {width: number; height: number}) => void;
  onZoomChange: (zoom: number) => void;
  zoomCenter?: "viewport-center" | [number, number];
  onImageSizeChange?: (size: {width: number; height: number}) => void;
};
const noop = () => { };

export default function ImageZoom({src, zoom = 1, zoomCenter = 'viewport-center', onImageSizeChange = noop, ...props}: Props) {
  const {classes} = useStyles();
  const [rootRef, rootRect] = useResizeObserver();
  const [imageRef, imageRect] = useResizeObserver();

  const viewport = useViewportSize();
  // const imageSize = useImageSize(src);
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    let isMounted = true;
    const img = new Image();
    img.onload = function () {
      if (isMounted)
        setImageSize({
          width: img.width,
          height: img.height
        });
      onImageSizeChange({
        width: img.width,
        height: img.height
      });
    }
    img.src = src;
    return () => {
      isMounted = false;
    }
  }, [src]);

  useEffect(() => {
    if (imageSize !== null) {
      if (props.onLoad) {
        props.onLoad();
      }
      const scale = imageSize.width / imageRef.current.width;
      if (props.onScaleChange && scale !== 0) {
        props.onScaleChange(scale);
      }

      if (props.onSizeChange) {
        props.onSizeChange({
          width: imageRef.current.width,
          height: imageRef.current.height,
        });
      }
    }
  }, [imageSize]);

  useEffect(() => {
    if (imageSize) {
      const scale = imageSize.width / imageRef.current.width;
      if (props.onScaleChange && scale !== 0) {
        props.onScaleChange(scale);
      }
    }
  }, [viewport, rootRect]);

  const zoomToVieportCenter = () => {

    const sT = (rootRef.current.scrollTop / imageRect.height) + ((rootRect.height / imageRect.height) / 2);
    const sL = (rootRef.current.scrollLeft / imageRect.width) + ((rootRect.width / imageRect.width) / 2);

    imageRef.current.style.width = `${100 * zoom}%`;

    rootRef.current.scrollTo({
      top: (imageRef.current.height * sT) - rootRect.height / 2,
      left: (imageRef.current.width * sL) - rootRect.width / 2,
    });
  };

  const zoomToCoordinates = () => {
    const [x, y] = zoomCenter as [number, number];
    imageRef.current.style.width = `${100 * zoom}%`;

    rootRef.current.scrollTo({
      top: imageRef.current.height * y - rootRect.height / 2,
      left: imageRef.current.width * x - rootRect.width / 2,
    });
  };

  useEffect(() => {
    if (zoomCenter === 'viewport-center') {
      zoomToVieportCenter();
    } else {
      zoomToCoordinates();
    }

    if (props.onSizeChange) {
      props.onSizeChange({
        width: imageRef.current.width,
        height: imageRef.current.height,
      });
    }

    if (props.onScaleChange) {
      if (imageSize) {
        const scale = imageSize.width / imageRef.current.width;
        if (scale !== 0) {
          props.onScaleChange(scale);
        }
      }
    }
  }, [zoom, zoomCenter]);


  return (
    <div
      className={classes.root}
      ref={rootRef}
      id="js-image-zoom"
      style={{
        height: props.height,
        overflow: props.lockScroll ? 'hidden' : undefined,
      }}
    >
      <img
        ref={imageRef}
        className={classes.image}
        src={src}
        alt=""
      />
      {props.children}
    </div>
  )
}
