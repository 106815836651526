import {Card, createStyles, Grid, Group, Table, Text, Title} from '@mantine/core';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Bar, BarChart, CartesianGrid, ErrorBar, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {loadSessionsComparison} from '../app/features/inference/comparison';
import {useAppDispatch} from '../app/hooks';
import {THEME_COLORS} from '../utils/misc';
import InfoIcon from './InfoIcon';
import ParticlesPieChart from './ParticlesPieChart';

interface Histogram {
  range: string;
  value: number;
};

interface HistogramsState {
  ecd: Histogram[];
  max_feret: Histogram[];
};


export interface ClassificationStats {
  classification: string;
  count: number;
  avg_area: number;
  avg_ecd: number;
  avg_feret: number;
  avg_aspect: number;
  avg_circularity: number;
  stdev_ecd: number;
  stdev_feret: number;
  stdev_area: number;
  stdev_aspect_ratio: number;
  stdev_circularity: number;
}

const useStyles = createStyles(theme => ({

}));

export default function PageCompaceReports() {
  const {compareToken = ''} = useParams<{compareToken: string}>();
  const sessionIdx = compareToken!.split('-');
  const dispatch = useAppDispatch();
  const {classes} = useStyles();
  const [isLoadingData, setLoadingData] = useState(true);

  const [barData, setBarData] = useState<{name: string;[key: string]: number | string}[]>([]);
  const [pieData, setPieData] = useState<{name: string; value: number}[]>([]);
  const [histogramsData, setHistogramsData] = useState<HistogramsState>({ecd: [], max_feret: []});
  const [tableData, setTableData] = useState<ClassificationStats[]>([]);

  useEffect(() => {
    if (sessionIdx.length >= 2) {
      setLoadingData(true);
      dispatch(loadSessionsComparison({sessions: sessionIdx.map(sess => parseInt(sess, 10))})).then(res => {
        const barRes = res.payload.bar as (string | number)[][];

        const barLabels = barRes.map(v => v[0] as string);
        const barValues = barRes.map(([_, ...v]) => v as number[]);

        const sessions: {id: number; title: string}[] = res.payload.sessions;

        const barData: {name: string;[key: string]: number | string}[] = sessions.map((s, sessionIndex) => {
          let res: {[key: string]: number | string} = {};
          barLabels.forEach((label, clsIndex) => {
            res[label] = barValues[clsIndex][sessionIndex];
          });

          return {
            name: s.title,
            ...res,
          };
        });
        setLoadingData(false);
        setPieData(res.payload.pie.map(([name, value]: [string, number]) => ({
          name,
          value,
        })));
        setHistogramsData(res.payload.histograms);
        setBarData(barData);
        setTableData(res.payload.data_by_classification);
      });
    };
  }, []);

  if (barData.length === 0) {
    return <div></div>
  }
  return (
    <Grid>
      <Grid.Col span={12}>
        <Group
          align={'left'}
          style={{
            flexWrap: 'nowrap'
          }}
        >
          <Card>
            <Title
              order={3}
              mb="md"
            >
              Comparing Reports
              <InfoIcon />
            </Title>
            <div style={{width: '100%', overflowX: 'auto'}}>
              <Table>
                <thead>
                  <tr>
                    <th>Classification</th>
                    <th>Amount</th>
                    <th>ECD Avg (um)</th>
                    <th>ECD Stdev (um)</th>
                    <th>Max Feret Avg (px)</th>
                    <th>Max Feret Stdev (px)</th>
                    <th>Aspect Ratio Avg</th>
                    <th>Aspect Ratio Stdev</th>
                    <th>Area Avg (px)</th>
                    <th>Area Stdev (px)</th>
                    <th>Circularity Avg</th>
                    <th>Circularity Stdev</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(data =>
                    <tr key={data.classification}>
                      <td>{data.classification}</td>
                      <td>{data.count}</td>
                      <td>{data.avg_ecd.toFixed(2)}</td>
                      <td>{data.stdev_ecd.toFixed(2)}</td>
                      <td>{data.avg_feret.toFixed(2)}</td>
                      <td>{data.stdev_feret.toFixed(2)}</td>
                      <td>{data.avg_aspect.toFixed(2)}</td>
                      <td>{data.stdev_aspect_ratio.toFixed(2)}</td>
                      <td>{data.avg_area.toFixed(2)}</td>
                      <td>{data.stdev_area.toFixed(2)}</td>
                      <td>{data.avg_circularity.toFixed(2)}</td>
                      <td>{data.stdev_circularity.toFixed(2)}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card>
          <Card style={{maxWidth: 240}}>
            <Text mb="md">
              Standard Deviation of Particles
            </Text>
            <ParticlesPieChart
              showPercentage={false}
              width={'60%'}
              data={pieData}
              classificationsSorted={pieData.map(v => v.name)}
            />
          </Card>
        </Group>
      </Grid.Col>

      <Grid.Col span={12}>
        <Card style={{height: 470}}>
          <Text
            mb="md"
          >
            Classification Percentages per Report
          </Text>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              height={400}
              data={barData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                // @ts-ignore
                formatter={(value, name, props) => value.toFixed(2)}
              />
              <Legend />
              {Object.keys(barData[0]).filter(k => k !== 'name').map((k, index) => (
                <Bar
                  key={k}
                  dataKey={k}
                  stackId="a"
                  fill={THEME_COLORS[index]}
                >
                  <ErrorBar dataKey={k} width={4} strokeWidth={2} stroke="green" direction="y" />
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Grid.Col>

      <Grid.Col span={6}>
        <Card style={{height: 550}}>
          <Text mb="md">
            Average ECD for All Reports
          </Text>
          <ResponsiveContainer width={'100%'} height={500}>
            <BarChart
              data={histogramsData.ecd}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
              />
              <YAxis
                domain={[0, 'auto']}
              />
              <Tooltip />
              <Bar
                dataKey={'value'}
                fill={THEME_COLORS[0]}
              >
                <ErrorBar
                  dataKey="std"
                  width={4}
                  strokeWidth={2}
                  stroke="green"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Grid.Col>
      <Grid.Col span={6}>
        <Card style={{height: 550}}>
          <Text mb="md">
            Average Max Feret for All Reports
          </Text>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              height={500}
              data={histogramsData.max_feret}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="range" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey={'value'}
                label="Max Feret"
                fill={THEME_COLORS[0]}
              >
                <ErrorBar
                  dataKey="std"
                  width={4}
                  strokeWidth={2}
                  stroke="green"
                />
              </Bar>

            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Grid.Col>
    </Grid >
  );
}
