import {Button, createStyles, Group, Text} from '@mantine/core';
import React from 'react';
import LabelEditForm from './LabelEditForm';

interface Props {
  labels: {
    id: number;
    title: string;
  }[];
  onLabelSubmit: (data: {title: string, id: number}) => Promise<any>;
  onLabelDelete: (labelId: number) => Promise<any>;
}

const useStyles = createStyles(theme => ({
  label: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    borderBottom: `solid 1px ${theme.colors.gray[4]}`
  }
}));

export default function LabelsListForm(props: Props) {
  const {classes} = useStyles();
  const [labelId, setLabelId] = React.useState(0);

  const onEditButtonClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const labelId = parseInt(e.currentTarget.value);
    setLabelId(labelId);
  }

  const onCancelEdit = () => {
    setLabelId(0);
  };

  const handleLabelSubmit = (data: {title: string}) => {
    return props.onLabelSubmit({
      title: data.title,
      id: labelId,
    }).then(() => {
      setLabelId(0);
    });
  };

  const handleLabelDelete = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    props.onLabelDelete(parseInt(e.currentTarget.value)).then(() => {
      setLabelId(0);
    });
  };

  return (
    <div>
      {props.labels.map(label =>
        <div
          key={label.id}
          className={classes.label}
        >
          {label.id === labelId ?
            <div>
              <LabelEditForm
                initialValues={{
                  title: props.labels.find(l => l.id === labelId)?.title || "",
                }}
                onSubmit={handleLabelSubmit}
                onCancelClick={onCancelEdit}
              />
            </div>
            :
            <Group position="apart">
              <Text>
                {label.title}
              </Text>
              <Group>
                <Button
                  size="xs"
                  variant="subtle"
                  value={label.id}
                  onClick={onEditButtonClick}

                >
                  Edit
                </Button>
                <Button
                  size="xs"
                  variant="subtle"
                  color="red"
                  value={label.id}
                  onClick={handleLabelDelete}
                >
                  Delete
                </Button>
              </Group>
            </Group>
          }

        </div>
      )}
    </div>
  );
}
