import {MantineThemeOverride} from '@mantine/core';

export const theme: MantineThemeOverride = {
  fontFamily: 'Roboto',
  globalStyles: theme => ({
    body: {
      backgroundColor: '#CED7DF',
    }
  }),
  headings: {
    fontWeight: 'normal',
  },
  fontSizes: {

  },
  components: {
    Grid: {
      defaultProps: {
        gutter: 12
      }
    },
    Button: {
      styles: (theme) => ({
        root: {
          fontWeight: 400
        }
      })
    },
    Card: {
      styles: () => ({
        root: {
          overflow: 'inherit'
        }
      })
    },
    Text: {
      defaultProps: {
        size: 'sm'
      },
      styles: () => ({
        root: {
          lineHeight: 1.42
        }
      })
    },
    Modal: {
      defaultProps: {
        overlayColor: '#f0f0f0',
        overlayOpacity: 0.55,
        overlayBlur: 3,
      }
    }
  },
  defaultRadius: 2,
  colors: {
    blue: [
      "#E7F5FF",
      "#D0EBFF",
      "#A5D8FF",
      "#74C0FC",
      "#4DABF7",
      "#339AF0",
      "#428BDF",
      "#428BDF",
      "#1971C2",
      "#1864AB",
    ],
    green: [
      '#EBFBEE',
      '#D3F9D8',
      '#B2F2BB',
      '#8CE99A',
      '#69DB7C',
      '#51CF66',
      '#40C057',
      '#4E944F',
      '#2F9E44',
      '#2B8A3E',
    ],
    gray: [
      '#F8F9FA',
      '#F1F3F5',
      '#E9ECEF',
      '#DEE2E6',
      '#CED4DA',
      '#ADB5BD',
      '#868E96',
      '#495057',
      '#343A40',
      '#212529'
    ],
    muted: [
      '#428BDF',
      '#428BDF',
      '#428BDF',
      '#428BDF',
      '#428BDF',
      '#428BDF',
      '#E7EAEE',
      '#428BDF',
      '#428BDF',
      '#428BDF'
    ],
  }
};

export default theme;
