import {Center, Image, Text} from '@mantine/core';
import imageURL from '../assets/images/mean-formula.svg';

export default function DocumentationMean() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        Metric used to evaluate the performance of image processing algorithms. mAP represents the sum of up the average precision scores across all the classification classes and dividing the result by the total number of classes. Its value is between 0 and 1, with 1 representing perfect performance.
      </Text>
      <Text size="sm">
        Each inference is accompanied by a report that captures the data output from the AI model.
      </Text>
      <Center>
        <Image
          src={imageURL}
          alt=""
          mb="md"
          mt="xl"
        />
      </Center>
    </>
  )
}
