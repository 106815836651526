import {ActionIcon, Button, Center, createStyles, Group, Popover, Progress, Text, useMantineTheme} from '@mantine/core';
import {useInterval} from '@mantine/hooks';
import memoize from 'memoize-one';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {areEqual, FixedSizeList as List} from 'react-window';
import {loadDatasetDetails} from '../app/features/datasets/details';
import {ImageLabel} from '../app/features/imageLabels/slice';
import {Label} from '../app/features/labels/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import editIconImageURL from '../assets/icons/edit.svg';
import {THEME_COLORS} from '../utils/misc';
import ClassificationPreview from './ClassificationPreview';
import LabelForm from './LabelForm';

const useStyles = createStyles(theme => ({
  label: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    marginBottom: theme.spacing.sm,
    borderTop: `solid 1px #ccc`
  },
  labelPreview: {
    width: 30,
    height: 30,
  },
  progressWrapper: {
    marginBottom: 10,
  }
}));

export interface LabelProps {
  label: number;
  title: string;

  top: number;
  left: number;
  width: number;
  height: number;

  absTop: number;
  absLeft: number;
  absWidth: number;
  absHeight: number;

  positional_id: string;
  is_predicted: boolean;
}

interface Props {
  imageLabels: (ImageLabel & {
    absTop: number;
    absLeft: number;
    absWidth: number;
    absHeight: number;
  })[];
  predictorProgress: number;
  labels: Label[];
  imageId: number;
  imageURL: string;
  datasetId: number;
  onPredictorButtonClick?: () => void;
  onHover?: (item: LabelProps) => void;
  height?: number;
  recentLabel?: string | number;
  onLabelSubmitSuccess?: (labelId: string) => void;
  loadPredictions: () => Promise<any>;
  savePredictions: () => Promise<any>;
}

interface RowProps {
  items: LabelProps[];
  imageURL: string;
  labels: Label[];
  datasetId: number;
  imageId: number;
  recentLabel?: string | number;
  onHover?: (item: LabelProps) => void;
  onLabelSubmitSuccess?: (labelId: string) => void;
}
const Row = memo(({data, index, style, }: {data: RowProps; index: number; style: React.CSSProperties}) => {
  const theme = useMantineTheme();
  // Data passed to List as "itemData" is available as props.data
  const {
    items,
    imageURL,
    datasetId,
    imageId,
    labels,
    onLabelSubmitSuccess,
    recentLabel = '0',
    onHover = () => { }
  } = data;
  const il = items[index];
  const {classes} = useStyles();

  return (
    <div style={style}>
      <Group
        noWrap
        key={il.positional_id}
        className={classes.label}
        position="apart"
        style={{padding: 10}}
      >
        <Group
          noWrap
        >
          <Center className={classes.labelPreview}>
            <ClassificationPreview
              onMouseEnter={() => onHover(items[index])}
              zoomOnHover={false}
              imageURL={imageURL}
              top={il.absTop}
              left={il.absLeft}
              width={il.absWidth}
              height={il.absHeight}
              imageId={imageId}
            />
          </Center>
          <Text
            color={il.is_predicted ? theme.colors.orange[5] : undefined}
            lineClamp={1}
          >
            {il.title}{il.is_predicted ? '?' : ''}
          </Text>
        </Group>

        <Popover
          withArrow
          withinPortal
          position="bottom-end"
        >
          <Popover.Target>
            <ActionIcon>
              <img src={editIconImageURL} alt="" />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <LabelForm
              labels={labels}
              datasetId={datasetId}
              imageId={imageId}
              onSubmitSuccess={onLabelSubmitSuccess}
              initialValues={{
                top: il.absTop,
                left: il.absLeft,
                width: il.absWidth,
                height: il.absHeight,
                positional_id: il.positional_id,
                label: il.label || recentLabel,
              }}
            />
          </Popover.Dropdown>
        </Popover>
      </Group >
    </div >
  );
}, areEqual);

const createItemData = memoize((data: RowProps) => (data));

export default function ImageLabelsForm({height = 400, ...props}: Props) {
  const theme = useMantineTheme();
  const {classes} = useStyles();
  const {
    datasetDetails
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const [hasPredictions, setHasPredictions] = useState(false);
  const [loadingPredictions, setLoadingPredictions] = useState(false);

  const handlePredictionsLoad = () => {
    setLoadingPredictions(true);
    setHasPredictions(false);
    props.loadPredictions().then(() => {
      setLoadingPredictions(false);
      setHasPredictions(true);
    });
  };

  const handlePredictionsSave = () => {
    setLoadingPredictions(true);
    props.savePredictions().then(() => {
      setLoadingPredictions(false);
      setHasPredictions(false);
    });
  };

  const imageLabels = useMemo(
    () => {
      return props.imageLabels.map(il => {
        const labelIndex = props.labels.findIndex(l => l.id === il.label);
        return {
          ...il,
          color: labelIndex !== -1 ? THEME_COLORS[labelIndex] : theme.colors.blue[5],
          label: il.label,
          title: labelIndex === -1 ? '' : props.labels[labelIndex].title,
        }
      });
    },
    [props.imageLabels]
  );
  useEffect(() => {
    setLoadingPredictions(false);
    setHasPredictions(false);
  }, [props.imageId]);

  const itemData = createItemData({
    items: imageLabels,
    imageURL: props.imageURL,
    datasetId: props.datasetId,
    imageId: props.imageId,
    labels: props.labels,
    onHover: props.onHover,
    onLabelSubmitSuccess: props.onLabelSubmitSuccess,
    recentLabel: props.recentLabel,
  });

  const predictorProgress = (
    datasetDetails.predictor_enabled ? 100 : props.predictorProgress * 100
  );

  const interval = useInterval(() => dispatch(loadDatasetDetails(props.datasetId)), 1000 * 60);

  useEffect(() => {
    if (props.predictorProgress === 1 && !datasetDetails.predictor_enabled) {
      interval.start();
    }
    return interval.stop;
  }, [datasetDetails.predictor_enabled, props.predictorProgress]);

  const isTrainingPredictor = props.predictorProgress === 1 && !datasetDetails.predictor_enabled;

  return (
    <div>
      <div className={classes.progressWrapper}>
        <Text mb="sm">Predictor Progress - {predictorProgress.toFixed(1)}%</Text>
        <Progress value={predictorProgress} />
      </div>
      <List
        height={height}
        itemCount={imageLabels.length}
        itemData={itemData}
        itemSize={55}
        width={'100%'}
      >
        {Row}
      </List>
      {hasPredictions ?
        <Button
          fullWidth
          loading={loadingPredictions}
          onClick={handlePredictionsSave}
          color="green"
          variant="light"
        >
          Save Predictions
        </Button>
        :
        isTrainingPredictor ?
          <Button
            fullWidth
            loading
            color="yellow"
            variant="filled"
          >
            Setting Up Predictor
          </Button>
          :
          <Button
            fullWidth
            loading={loadingPredictions}
            disabled={!datasetDetails.predictor_enabled}
            color={(isTrainingPredictor && 'yellow') || undefined}
            onClick={handlePredictionsLoad}
          >
            Run Predictor
          </Button>
      }
    </div>
  )
}
