import {
  Alert,
  Button,
  Center,
  createStyles,
  Grid,
  LoadingOverlay,
  Modal,
  Text,
  TextInput
} from '@mantine/core';
import {useForm} from '@mantine/form';
import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import * as analytics from '../app/features/analytics';
import {checkElabAccess} from '../app/features/elab/slice';
import {useAppDispatch, useIsAuthenticated} from '../app/hooks';
import * as urls from '../urls';


const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: theme.spacing.md
  },
}));

export default function PageElabCheckAccess() {
  const {classes} = useStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [experimentId, sectionId, hostname] = [
    searchParams.get('experimentId'),
    searchParams.get('sectionId'),
    searchParams.get('hostname')
  ];
  const validSearchParams = !!(experimentId && sectionId && hostname);

  const form = useForm({
    initialValues: {
      hostname: hostname!
    },
    validate: {
      hostname: (value) => !value ? 'This field is required' : null
    }
  })

  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(checkElabAccess()).then(res => {
        if (res.payload.hasAccess) {
          navigate(urls.getElabImportPage(experimentId!, sectionId!));
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [dispatch, isAuthenticated, experimentId, sectionId]);

  React.useEffect(() => {
    analytics.logEvent(analytics.ELAB_CHECK_ACCESS_PAGE);
  }, []);

  const handleFormSubmit = (values: {hostname: string}) => {
    analytics.logButtonClick({
      eventName: analytics.ELAB_AUTH_START
    });

    window.open(
      "https://"
      + hostname +
      '/members/fn/externalAuth/?rootVar=MP_VISION&state=' +
      experimentId + '_' + sectionId,
    );
  };

  return (
    <Grid className={classes.root}>
      {validSearchParams ?
        <>
          <LoadingOverlay
            visible={isLoading}
          />
          {!isLoading &&
            <Modal
              opened
              onClose={() => { }}
            >
              <form onSubmit={form.onSubmit(handleFormSubmit)}>
                <Text>Welcome!</Text>
                <Text>
                  In order to use mpVision,
                  we need you to allow access to your experiment sections in eLabJournal for importing images.
                </Text>
                <TextInput
                  label="Please, confirm that this is the valid URL for your eLabJournal"
                  mt="sm"
                  mb="sm"
                  placeholder="Enter eLabJournal hostname"
                  {...form.getInputProps('hostname')}
                />
                <Text
                  mb="sm"
                >
                  Replace the address above if it is incorrect.

                </Text>
                <Text
                  mb="sm"
                >
                  After you grant permission,
                  we will automatically create an mpVision account for you.
                </Text>
                <Button
                  fullWidth
                  type="submit"
                >
                  Authorize eLab Access
                </Button>
              </form>
            </Modal>
          }
        </>
        :
        <Grid.Col span={12}>
          <Center>
            <Alert
              color="yellow"
            >
              404 Page Not Found
            </Alert>
          </Center>
        </Grid.Col>
      }
    </Grid>
  );
}
