import { Anchor, Button, Group, LoadingOverlay, Image as MantineImage, Modal, Popover, Table, createStyles, keyframes } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { compareObjects } from '../app/features/admin/slice';
import { InferenceObjectStats, loadInferenceImageStats } from '../app/features/inference/stats';
import { useAppDispatch } from '../app/hooks';
import ClassificationPreview from './ClassificationPreview';
import FormInferenceImageImport from './FormInferenceImageImport';

interface Props {
  stats: InferenceObjectStats[];
  datasetId: number;
  hideClassification?: boolean;
  sessionId: number;
}

interface FullFrameProps {
  top: number;
  left: number;
  width: number;
  height: number;
  imageURL: string;
  imageId: number;
  sessionId: number;
}


export const lassoBorder = keyframes(`
0% {
  background-position: 0 0, 50px 100%, 0 50px, 100% 0;
}
100% {
  background-position: 50px 0, 0 100%, 0 0, 100% 50px;
}`);

const animatedBorderHeight = 1;
const animatedBorderWidth = 10;
const animationSpeed = 3000;
const animatedBorderColors = ['black', 'white'];

interface ImageSize {
  width: number;
  height: number;
}
const useStyles = createStyles(theme => ({
  imagePreview: {
    position: 'relative'
  },
  relative: {
    position: 'relative',
  },
  imageWrapper: {
    position: 'relative',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  rect: {
    position: 'absolute',
    border: `solid 1px ${theme.colors.red[5]}`,
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.colors.green[4]
    }
  },
  rectActive: {
    zIndex: 100,
    borderColor: theme.colors.yellow[6],
    '&:hover': {
      borderColor: theme.colors.yellow[6],
    }
  },
  animateBorder: {
    animation: `${lassoBorder} ${animationSpeed}ms infinite linear`,
    backgroundImage: `linear-gradient(90deg, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%), linear-gradient(90deg, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%), linear-gradient(0, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%), linear-gradient(0, ${animatedBorderColors[0]} 50%, ${animatedBorderColors[1]} 50%)`,
    backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
    backgroundSize: `${animatedBorderWidth}px ${animatedBorderHeight}px, ${animatedBorderWidth}px ${animatedBorderHeight}px, ${animatedBorderHeight}px ${animatedBorderWidth}px, ${animatedBorderHeight}px ${animatedBorderWidth}px`,
  },
  label: {
    display: 'inline-block',
    position: 'absolute',
    bottom: '100%',
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
    fontSize: '50%',
    whiteSpace: 'nowrap'
  }
}));

function FullFrameView(props: FullFrameProps) {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const [stats, setStats] = useState<InferenceObjectStats[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [relCoords, setRelCoords] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  } | null>(null)
  const ref = useRef<HTMLImageElement>(null);

  const [compareStats, setCompareStats] = useState<number[]>([]);
  useEffect(() => {
    if (compareStats.length === 2) {
      dispatch(compareObjects({ bboxA: compareStats[0], bboxB: compareStats[1] }));
    }
  }, [compareStats]);

  useEffect(() => {
    setLoading(true);
    dispatch(loadInferenceImageStats({
      sessionId: props.sessionId,
      imageId: props.imageId
    })).then(res => {
      setStats(res.payload);
      setLoading(false);
    })
  }, [props.imageId, props.sessionId]);
  useEffect(() => {
    let isMounted = true;
    const img = new Image();
    img.onload = function () {
      if (isMounted) {
        setImageSize(img);
        setRelCoords({
          top: (props.top / img.height) * 100,
          left: (props.left / img.width) * 100,
          width: (props.width / img.width) * 100,
          height: (props.height / img.height) * 100,
        });

        setImageSize({
          width: img.width,
          height: img.height,
        });
      }
    };
    img.src = props.imageURL;
    return () => {
      isMounted = false;
    };
  }, [props.imageURL]);


  return (
    <div className={classes.imagePreview}>
      <LoadingOverlay
        visible={isLoading}
      />
      <Group
        mb="sm"
      >
        <Popover width={400} withArrow shadow="md">
          <Popover.Target>
            <Button
              size="xs"
            >
              Export to training dataset
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <FormInferenceImageImport
              reportId={props.sessionId}
              imageId={props.imageId}
            />
          </Popover.Dropdown>
        </Popover>
      </Group>
      <div className={classes.imageWrapper}>
        <div className={classes.relative}>
          <MantineImage
            imageRef={ref}
            src={props.imageURL}
          />
          {relCoords &&
            <div
              className={cx(classes.rect, classes.animateBorder)}
              style={{
                top: relCoords.top + '%',
                left: relCoords.left + '%',
                width: relCoords.width + '%',
                height: relCoords.height + '%',
              }}
            >
            </div>
          }
          {imageSize !== null && stats.map(stat =>
            <div
              key={stat.id}
              className={cx(classes.rect, compareStats.indexOf(stat.id) !== -1 && classes.rectActive)}
              style={{
                top: (stat.y / imageSize!.height) * 100 + '%',
                left: (stat.x / imageSize!.width) * 100 + '%',
                width: (stat.width / imageSize!.width) * 100 + '%',
                height: (stat.height / imageSize!.height) * 100 + '%',
                borderColor: stat.classification === 'Germinating' ? '#ff4040' : "#544e94",
              }}
              onClick={() => {
                const index = compareStats.indexOf(stat.id);

                if (index !== -1) {
                  setCompareStats([]);
                } else if (compareStats.length < 2) {
                  setCompareStats([...compareStats, stat.id]);
                } else {
                  setCompareStats([stat.id]);
                }
              }}
            >
              {(stat.classification !== 'Germinating' && stat.classification !== "Non-germinating") &&
                <div className={classes.label}>{stat.classification}</div>
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function InferenceObjectsTable(props: Props) {
  const [previewObj, setPreviewObj] = useState(0);

  const rows = props.stats.map(stat => (
    <tr key={stat.id}>
      <td>
        <ClassificationPreview
          key={stat.id}
          imageURL={stat.image}
          top={stat.y}
          left={stat.x}
          width={stat.width}
          height={stat.height}
          imageId={stat.image_id}
        />
      </td>
      {!props.hideClassification &&
        <td>
          {stat.classification}
        </td>
      }
      <td>
        {stat.width}/{stat.height}
      </td>
      <td>
        {stat.x}/{stat.y}/{stat.x + stat.width}/{stat.height + stat.y}
      </td>
      <td>
        {stat.ecd.toFixed(2)}
      </td>
      <td>
        {stat.area.toFixed(2)}
      </td>
      <td>
        {stat.max_feret.toFixed(2)}
      </td>
      <td>
        {stat.perimeter.toFixed(2)}
      </td>
      <td>
        <Anchor
          href={`/inference/${props.datasetId}/${stat.image_id}/`}
          target="_blank"
          onClick={(e: React.SyntheticEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setPreviewObj(stat.id);
          }}
        >
          id: {stat.image_id} (click to preview)
        </Anchor>
      </td>
    </tr>
  ));

  const handleModalClose = () => {
    setPreviewObj(0);
  };
  const previewData = props.stats.filter(stat => stat.id === previewObj)[0];
  return (
    <div>
      <Modal
        fullScreen
        opened={!!previewData}
        onClose={handleModalClose}
      >
        {previewData &&
          <FullFrameView
            top={previewData.y}
            left={previewData.x}
            imageURL={previewData.image}
            imageId={previewData.image_id}
            sessionId={props.sessionId}
            {...previewData}
          />
        }
      </Modal>
      <Table>
        <thead>
          <tr>
            <th>Object Image</th>
            {!props.hideClassification &&
              <th>Classification</th>
            }
            <th>Size(px): w/h</th>
            <th>Coordinates(px): x1, y1, x2, y2</th>
            <th>ECD(um)</th>
            <th>Area(px)</th>
            <th>Max Feret(px)</th>
            <th>Perimeter(px)</th>
            <th>Frame</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </div>
  )
}
