import {createAsyncThunk, createSlice, nanoid, PayloadAction} from '@reduxjs/toolkit';

interface Session {
  id: number;
  session: number;
  created: number;
  inferenceStart: number;
  inferenceEnd: number;
  results: any;
};

const saveSession = createAsyncThunk(
  "sessions/add",
  async (data: Omit<Session, 'id'>) => {
    const ret = await new Promise((resolve, reject) => {
      resolve({id: nanoid(), ...data});
    });
    return ret as Session;
  }
);

const slice = createSlice({
  name: 'sessions',
  initialState: [] as Session[],
  reducers: {
    add: (state, action: PayloadAction<Session>) => {
      state.push(action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(saveSession.fulfilled, (state, action) => {
      state.push(action.payload);
    });
  }
});

export const {add} = slice.actions;
export {saveSession};

export default slice.reducer;
