import {createSlice} from '@reduxjs/toolkit';
import {getAuthInfo, login} from '../auth/slice';


export enum TutorialComponent {
  CLICK_CREATE_DATASET = 0,
  SET_DATASET_NAME,
  UPLOAD_FILES,
  DRAW_BOUNDING_BOX,
  SELECT_LABEL_TITLE,
  FINISH_LABELLING,

  OPEN_TRAINING_SESSIONS_LIST,
  CLICK_VIEW_TRAINING_SESSION,

  VIEW_TRAINING_CHART,
  VIEW_VALIDATION_IMAGES,
  CLICK_EXPORT_MODEL_BUTTON,

  VALIDATION_IMAGE_INFO,

  CREATE_INFERENCE_DATASET,
  CLICK_START_INFERENCE_BUTTON,
  SELECT_AI_MODEL,
  CLICK_REPORTS_SECTION,
  CLICK_VIEW_INFERENCE_SESSION,
  REVIEW_REPORT_DATA,
};

const TUTORIALS = {
  'training': [
    TutorialComponent.CLICK_CREATE_DATASET,
    TutorialComponent.SET_DATASET_NAME,
    TutorialComponent.UPLOAD_FILES,
    TutorialComponent.DRAW_BOUNDING_BOX,
    TutorialComponent.SELECT_LABEL_TITLE,
    TutorialComponent.FINISH_LABELLING,
  ],
  'training-sessions-list': [
    TutorialComponent.OPEN_TRAINING_SESSIONS_LIST,
    TutorialComponent.CLICK_VIEW_TRAINING_SESSION,
  ],
  'training-session': [
    TutorialComponent.VIEW_TRAINING_CHART,
    TutorialComponent.VIEW_VALIDATION_IMAGES,
    TutorialComponent.CLICK_EXPORT_MODEL_BUTTON,
  ],
  'inference-dataset': [
    TutorialComponent.CLICK_CREATE_DATASET,
    TutorialComponent.SET_DATASET_NAME,
    TutorialComponent.UPLOAD_FILES,
    TutorialComponent.CLICK_START_INFERENCE_BUTTON,
    TutorialComponent.SELECT_AI_MODEL,
    TutorialComponent.CLICK_REPORTS_SECTION,
    TutorialComponent.CLICK_VIEW_INFERENCE_SESSION,
    TutorialComponent.REVIEW_REPORT_DATA,
  ],
};
type TutorialName = keyof (typeof TUTORIALS);

const TUTORIALS_ORDER: TutorialName[] = [
  'training',
  'training-sessions-list',
  'training-session',
  'inference-dataset'
];

export const stepInfo = (activeTutorial: TutorialName, component: TutorialComponent) => (
  `STEP ${TUTORIALS[activeTutorial].indexOf(component) + 1}/${TUTORIALS[activeTutorial].length}`
);


export interface QuickGuide {
  isOpen: boolean;
  isTutorialActive: boolean;
  currentStepNumber: number;
  completedTutorials: TutorialName[];
  activeComponent: TutorialComponent;
  activeTutorial: TutorialName;
  tutorialDisabled: boolean;
};

const slice = createSlice({
  name: 'quickGuide',
  initialState: {
    isOpen: false,
    tutorialDisabled: false,
    isTutorialActive: false,
    activeTutorial: 'training',
    completedTutorials: [],
    currentStepNumber: 0,
    activeComponent: TutorialComponent.CLICK_CREATE_DATASET,
  } as QuickGuide,
  reducers: {
    startTutorial: (state, action: {payload: TutorialName}) => {
      // TODO: simplify this function
      if (state.completedTutorials.indexOf(action.payload) === -1) {
        if (TUTORIALS_ORDER.indexOf(action.payload) === state.completedTutorials.length) {
          if (state.activeTutorial !== action.payload) {
            state.isTutorialActive = true;
            state.activeTutorial = action.payload;
            state.activeComponent = TUTORIALS[action.payload][0];
            state.currentStepNumber = 0;
          } else if (!state.isTutorialActive) {
            state.isTutorialActive = true
            state.activeComponent = TUTORIALS[action.payload][0];
            state.currentStepNumber = 0;
          }
        }
      }
    },
    openQuickGuide: (state) => {
      state.isOpen = true;
    },
    closeQuickGuide: (state) => {
      state.isOpen = false;
    },
    toggleQuickGuide: (state) => {
      state.isOpen = !state.isOpen;
    },
    goToStep: (state, action: {payload: number}) => {
      state.activeComponent = TUTORIALS[state.activeTutorial][action.payload];
    },
    goToNextStep: (state, action: {payload: number}) => {
      const activeTutorial = TUTORIALS[state.activeTutorial];
      if (activeTutorial.indexOf(action.payload) === state.currentStepNumber) {
        state.activeComponent = TUTORIALS[state.activeTutorial][state.currentStepNumber + 1];
        state.currentStepNumber = state.currentStepNumber + 1;
      }
    },
    goToPrevStep: (state) => {
      state.activeComponent = TUTORIALS[state.activeTutorial][state.currentStepNumber - 1];
      state.currentStepNumber = state.currentStepNumber - 1;
    },
    completeTutorial: (state, action: {payload: TutorialName}) => {
      if (state.completedTutorials.indexOf(action.payload) === -1) {
        state.completedTutorials.push(action.payload);
      }
      state.isTutorialActive = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (!action.payload.subscription_details.tutorial_active) {
        state.completedTutorials = TUTORIALS_ORDER.concat([]);
      }
      state.tutorialDisabled = !action.payload.subscription_details.tutorial_active;
    });
    builder.addCase(getAuthInfo.fulfilled, (state, action) => {
      if (!action.payload.subscription_details.tutorial_active) {
        state.completedTutorials = TUTORIALS_ORDER.concat([]);
      }
      state.tutorialDisabled = !action.payload.subscription_details.tutorial_active;
    });
  }
});

export const {
  openQuickGuide,
  closeQuickGuide,
  toggleQuickGuide,
  goToNextStep,
  goToPrevStep,
  completeTutorial,
  startTutorial,
} = slice.actions;

export default slice.reducer;
