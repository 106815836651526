import {createStyles, Table, Text} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  table: {
    border: '1px solid #CED7DF',
    marginTop: theme.spacing.lg,
    '> thead': {
      backgroundColor: '#F5F5F5',
    },
    '& thead tr th': {
      padding: theme.spacing.sm,
    },
    '& tbody tr td': {
      padding: theme.spacing.sm,
      borderRight: '1px solid #CED7DF'
    }
  }
}));

export default function DocumentationCalibration() {
  const {classes} = useStyles();

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        After an AI model has been trained on labelled images, the consistency of its performance can be evaluated at any time.  To do so, a calibration can be conducted by labelling image(s) to create a ‘gold standard’, which are image(s) with known particle identities.  A previously trained model on these specific particle identities can then be evaluated by running the model on the ‘gold standard’ image(s).  The output of this calibration run is a table reporting the accuracy of the model on its classifications as well as its true positives, true negatives, false positives, false negatives, and unidentified.
      </Text>
      <Text size="sm">
        The following definitions describe the calculations in the calibration outputs:
      </Text>
      <Table
        className={classes.table}
      >
        <thead>
          <tr>
            <th>Metric</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          <tr >
            <td>True Positive (TP)</td>
            <td>
              The number of the model’s predictions that are correctly classified for each class.
            </td>
          </tr>
          <tr >
            <td>True Negative (TN)</td>
            <td>
              For a particular class, the True Negative value is a sum of all the other classes' correctly predicted and incorrectly predicted labels.
            </td>
          </tr>
          <tr>
            <td>False Positive (FP)</td>
            <td>
              For a particular class, the False Positive value is the number of that particular class’s predictions that are incorrectly assigned to labelled particles of different classes.
            </td>
          </tr>
          <tr>
            <td>False Negative (FN)</td>
            <td>
              For a particular class, the False Negative value is the number of the incorrect predictions of all other classes that are assigned to that particular class.
            </td>
          </tr>
          <tr>
            <td>Accuracy</td>
            <td>
              Provides the fraction of the total particles in each class that was correctly classified by the model.
              <br />
              Accuracy formula per class: (TP+TN)/(TP+TN+FP+FN)
            </td>
          </tr>
        </tbody>
      </Table>

    </>
  )
}
