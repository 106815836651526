import {createStyles, NavLink} from '@mantine/core';
import {Link, useLocation} from 'react-router-dom';
import arrowRightIconURL from '../assets/icons/arrow-right-active.svg';

const useStyles = createStyles(theme => ({
  root: {
    color: '#3E4247',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&[data-active]:hover': {
      backgroundColor: 'transparent',

    }
  }
}));

interface Props {
  label: string;
  to: string;
  onClick?: () => void;
}

export default function LinkDocumentation(props: Props) {
  const {classes} = useStyles();
  const location = useLocation();

  return (
    <NavLink
      variant="subtle"
      onClick={props.onClick}
      className={classes.root}
      label={props.label}
      component={Link}
      to={props.to}
      rightSection={
        location.pathname === props.to &&
        <img
          src={arrowRightIconURL}
          alt=""
        />
      }
      active={location.pathname === props.to}
    />
  );
}
