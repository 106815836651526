import {Box, Divider, Image, Text, Title} from '@mantine/core';
import labelledImageURL from '../assets/images/labelled-example.jpg';
import outputImageURL from '../assets/images/output-example.jpg';
import ProcessingWorkflow from './ProcessingWorkflow';

export default function DocumentationTrainingValidationSet() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        Each time the AI model makes predictions on the validation set
        during the training, the validation images are displayed to visualise
        the AI performance.  The right images are the ground-truth images
        labelled by the end user, and the left images display the AI's predictions
        on images not exposed to the model during training.  Both the AI's predicted
        identity of the particle as well as the AI's confidence in the prediction,
        called a score, is displayed in the left images.  Validation images are
        generated at time intervals throughout the course of the model training
        and may be accessed by selecting any point on the training chart.
      </Text>

      <Divider my="sm" variant="dashed" />
      <Image
        src={labelledImageURL}
        alt=""
        mb="lg"
        mt="md"
      />
      <Image
        src={outputImageURL}
        alt=""
        mb="md"
        mt="md"
      />
      <Text size="sm" mb="md" mt="sm">
        Every group of validation images reflects the performance of an associated AI model. The right images are the ground-truth labeled images, and the left images display the AI's predictions. Both the AI's predicted identity of the particle as well as the AI's confidence in the prediction, called a score, is displayed in the left images. Validation images are generated at time intervals throughout the course of the model training and may be accessed by clicking at different points in the loss chart.
      </Text>

      <Title
        order={5}
        style={{fontWeight: 500}}
        mb="xs"
      >
        Initial Processing Workflow
      </Title>
      <Text size="sm" mb="lg">
        Process workflow from initial image upload to final report generation. Each stage must be performed consecutively during initial software usage.
      </Text>
      <Box mb="lg">
        <ProcessingWorkflow
          activeIndex={1}
        />
      </Box>
    </>
  )
}
