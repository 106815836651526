import {
  ActionIcon,
  Anchor,
  Card,
  Center,
  createStyles,
  Group,
  Image,
  Text,
  Title
} from '@mantine/core';
import {Link} from 'react-router-dom';
import {closeQuickGuide} from '../app/features/quick-guide/slice';
import {useAppDispatch} from '../app/hooks';
import introGifURL from '../assets/gif/intro-draw.gif';
import zoomGifURL from '../assets/gif/zoom.gif';

import arrowRightIconURL from '../assets/icons/arrow-right.svg';
import closeIconURL from '../assets/icons/close.svg';
import folderIconURL from '../assets/icons/folder.svg';
import trainingChartImageURL from '../assets/images/training-chart.png';
import ButtonGrayscale from './ButtonGrayscale';
import ButtonTrainingStart from './ButtonTrainingStart';
import ExportModelButton from './ExportModelButton';
import TrainingSessionList from './TrainingSessionsList';
import ZoomControls from './ZoomControls';

const useStyles = createStyles((theme, _params, getRef) => ({
  overflowWrapper: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
  },
  header: {
    background: '#544E94',
    color: '#fff',
    padding: theme.spacing.md,
  },
  section: {
    marginBottom: theme.spacing.xl,
    padding: theme.spacing.sm,
    '&:focus': {
      backgroundColor: '#F5F5F5',
      [`& .${getRef('sectionTitle')}`]: {
        color: '#544E94',
      },
      [`& .${getRef('sectionTitleActiveArrow')}`]: {
        display: 'inline',
      },
    },
  },
  sectionActive: {
    backgroundColor: '#F5F5F5',
  },

  sectionTitle: {
    ref: getRef('sectionTitle'),
    fontWeight: 700
  },
  sectionTitleActive: {
    color: '#544E94',
  },
  sectionTitleActiveArrow: {
    ref: getRef('sectionTitleActiveArrow'),
    display: 'none',
  },
  componentPreview: {
    border: '1px solid #D6DBE1',
    backgroundColor: '#fff',
    paddingTop: 22,
    paddingBottom: 22,
    marginTop: 12,
    marginBottom: 12,
  },
  sectionText: {
    color: '#3E4247',
    lineHeight: 1.42,
  },
  addButton: {
    color: theme.colors.green[6],
    fontSize: 14,
    '&:hover': {
      textDecoration: 'none',
    }
  },
  addButtonIcon: {
    marginBottom: 6,
    marginRight: 6,
  }
}));

export default function QuickGuide() {
  const {classes, cx} = useStyles();
  const dispatch = useAppDispatch();
  const handleCloseButtonClick = () => {
    dispatch(closeQuickGuide());
  };

  return (
    <Card >
      <Card.Section className={classes.header}>
        <Group position="apart">
          <Title
            order={4}
          >
            Quick Guide
          </Title>
          <ActionIcon
            variant="transparent"
            onClick={handleCloseButtonClick}
          >
            <img
              src={closeIconURL}
              alt=""
            />
          </ActionIcon>
        </Group>
      </Card.Section>
      <Card.Section>
        <div className={classes.overflowWrapper}>
          <div className={cx(classes.section)} id="q-grayscale" tabIndex={0}>
            <Title
              order={6}
              className={cx(classes.sectionTitle)}
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              />
              Create a Training Dataset
            </Title>
            <Center
              className={classes.componentPreview}
            >
              <Anchor
                className={classes.addButton}

              >
                <img
                  src={folderIconURL}
                  alt=""
                  className={classes.addButtonIcon}
                />
                Create Dataset Folder
              </Anchor>
            </Center>
            <Text size="sm" className={classes.sectionText}>
              Select Create Dataset Folder under the highlighted Training section.
              To create a dataset for the <Anchor component={Link} to="/documentation/ai/">AI</Anchor> to train on, you will upload images into a folder.
              These images you can then <Anchor component={Link} to="/documentation/labelling/">label</Anchor> to teach the AI what it needs to identify.
              For manual uploading with the browser, do not close the browser tab until uploading is finished.
              The uploader will display the progress in chunks of 100 images.
            </Text>
          </div>
          <div className={cx(classes.section)} id="q-grayscale" tabIndex={0}>
            <Title
              order={6}
              className={cx(classes.sectionTitle)}
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              />
              Labelling a Training Dataset
            </Title>
            <Center
              className={classes.componentPreview}
            >
              <img
                alt=""
                width={'70%'}
                style={{margin: '0 auto'}}
                src={introGifURL}
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              Label as many particles as possible in each image.
              Repeat for every particle in the image. Remember,
              the more particles you label, the more accurately
              your AI model will be trained! Finish <Anchor component={Link} to="/documentation/labelling/">labelling</Anchor>
              {' '} all your images - a minimum of 20 labelled images
              are recommended in a training dataset.
            </Text>

            <Text size="sm" mt="lg" className={classes.sectionText}>
              A labelling helper called the Predictor assists end users with labelling datasets.  The Predictor is an automated labelling tool that will make guesses of unlabelled particles after 200 labels have been manually added in a dataset.  For example, while labelling a dataset, once a total of 200 labelled particles have been assigned a label, it will enable the Predictor button to make guesses for the remaining unlabelled particles.  The predictions can be accepted by selecting ‘Run Predictor’ in the Predictor Label list on the right side of the Annotator screen.  The predictions can also be edited to the correct label if they are incorrectly guessed.
            </Text>
          </div>
          <div className={classes.section}>
            <Title
              order={6}
              className={classes.sectionTitle}
            >
              Zoom In, Out & Fit
            </Title>

            <Center
              className={classes.componentPreview}
            >
              <ZoomControls
                disableTooltip
                onChange={() => { }}
                value={1}
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              The Annotator tool has a variety of zooming functionalities available to assist with labelling a dataset. To zoom in more easily on the uploaded images, hold the Ctrl key, then push '+'. To zoom out, hold the Ctrl key, then push '-'.

            </Text>
            <Center
              className={classes.componentPreview}
            >
              <img
                alt=""
                width={'100%'}
                style={{margin: '0 auto'}}
                src={zoomGifURL}
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              To zoom to a particular point in an image, hover the cursor over the desired viewing area and push the letter 'z'.  The cursor will change to the Zoom icon, and then click to zoom into the area of interest.  The end user can also draw a rectangle with the Zoom icon to automatically zoom to a particular window area.
            </Text>
          </div>
          <div className={cx(classes.section)} id="q-grayscale" tabIndex={0}>
            <Title
              order={6}
              className={cx(classes.sectionTitle)}
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              />
              Convert to Greyscale
            </Title>
            <Center
              className={classes.componentPreview}
            >
              <ButtonGrayscale
                disableTooltip
                imageId={0}
                isGrayscale={false}
                datasetId={0}
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              In the toolbar, easily convert between color (RGB) and grayscale per your visual preference for labelling.
              The selected color scheme during labelling will not impact the AI model.
            </Text>




          </div>
          <div className={classes.section}>
            <Title
              order={6}
              className={classes.sectionTitle}
            >
              Start Training Session
            </Title>
            <Center
              className={classes.componentPreview}
            >
              <ButtonTrainingStart
                datasetId={0}
                disableTooltip
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              After you are finished labelling your entire training dataset, select
              the ’Start <Anchor component={Link} to={'/documentation/training/'}>Training</Anchor> Session’ button located at the top left to begin
              teaching your AI how to recognise your data!
            </Text>
          </div>
          <div className={classes.section} id="training-session-q" tabIndex={0}>
            <Title
              order={6}
              className={classes.sectionTitle}
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              />
              Training Sessions
            </Title>

            <Center
              className={classes.componentPreview}
            >
              <TrainingSessionList
                sessions={[{
                  id: 0,
                  dataset: 0,
                  status: 'In Progress',
                  is_done: false,
                  date_started: '',
                  is_stopped: false,
                  is_running: false,
                }]}
                disableTooltip
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              Select ‘Sessions’ to see a complete list of your training
              sessions in progress. Choose the AI Training Session and select ‘View’
              to monitor how your AI is training on your dataset. But first,
              give the model some time to train initially - the data will be
              collected and displayed over the course of up to six hours.
              The neural network is learning your data!  You will know when
              the AI model is finished training when the status changes from '
              In progress' to 'Done'. Revisit this page, click 'Sessions',
              and check this status again after training finishes.
            </Text>
          </div>
          <div className={classes.section} tabIndex={0} id="q-training-chart">
            <Title
              order={6}
              className={classes.sectionTitle}
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              />
              Loss Chart
            </Title>

            <Center
              className={classes.componentPreview}
            >
              <Image
                src={trainingChartImageURL}
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              The top chart displays three profiles: Training Loss, Validation Loss, and Mean Average Precision.
              The <Anchor to={'/documentation/loss/'} component={Link}>losses</Anchor> represent errors of the AI
              as it makes predictions – you want them to drop lower and lower as the AI continues to train.
              <Anchor to={'/documentation/mean-average-precision/'} component={Link}>The Mean Average Precision</Anchor> represents how well your AI is making predictions on your labelled data – you want this to trend upwards as the AI trains.
            </Text>
          </div>

          <div className={classes.section} id="q-select-ai-model" tabIndex={0}>
            <Title
              order={6}
              className={classes.sectionTitle}
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              /> Selecting AI Model
            </Title>

            <Center
              className={classes.componentPreview}
            >
              <ExportModelButton
                tooltipDisabled
                selectedPoint={1834}
              />
            </Center>
            <Text size="sm" className={classes.sectionText}>
              During the course of the training, you can click directly on different points on the chart to evaluate the model’s performance over time. When you observe the AI has good performance at a specific point during training – low loss values, high mean average precision, and high score values in the images – click on that specific model on the chart and select ‘Export model’ to save your AI model.
            </Text>
          </div>

          <div className={classes.section} tabIndex={0} id="q-validation-images">
            <Title
              order={6}
              className={classes.sectionTitle}
              mb="sm"
            >
              <img
                className={classes.sectionTitleActiveArrow}
                src={arrowRightIconURL}
                alt=""
              /> Validation Images
            </Title>

            <Text size="sm" className={classes.sectionText}>
              Each pair of images (left and right)
              are <Anchor to={'/documentation/validation-images/'} component={Link}>validation images</Anchor> and should
              become nearly identical as the training continues.
              The images on the left display your AI model’s output during
              the course of training. As the model trains, you will see the
              predictions improve over time.
              <Anchor to={'/documentation/score/'} component={Link}>The score</Anchor> values are percentages
              that represent the model’s confidence about each label – the higher
              the percentage, the more confident the model.

              The images on the right represent your labelled data – the gold
              standard from which your AI is learning.
            </Text>
          </div>
        </div>
      </Card.Section>
    </Card>
  )
}
