import {Button, createStyles, Group, LoadingOverlay, Text} from '@mantine/core';
import {useEffect, useState} from 'react';
import {loadImageFolders, loadImportFolders} from '../app/features/dataImport/slice';
import {useAppDispatch} from '../app/hooks';

interface Props {
  value: string[];
  onChange: (value: string) => void;
}

const useStyles = createStyles(theme => ({
  root: {
    border: `solid 1px ${theme.colors.gray[5]}`,
  },
  foldersWrapper: {
    height: 300,
    overflowY: 'auto',
  },

  controls: {
    padding: theme.spacing.sm,
    borderBottom: `solid 1px ${theme.colors.gray[4]}`,
  },
  folder: {
    padding: theme.spacing.xs,
    borderBottom: `solid 1px ${theme.colors.gray[4]}`,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: theme.colors.blue[0],
    },
    "&:last-child": {
      border: 'none'
    }
  },
  activeFolder: {
    backgroundColor: theme.colors.blue[3],
    "&:hover": {
      backgroundColor: theme.colors.blue[3],
    },
  },
}));

export default function FileBrowser(props: Props) {
  const {classes, cx} = useStyles();
  const dispatch = useAppDispatch();
  const [selectedFolder, setSelectedFolder] = useState('');
  const [folders, setFolders] = useState<string[]>([]);
  const [imageFolders, setImageFolders] = useState<{path: string; images: number}[]>([]);

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(loadImportFolders()).then(res => {
      setFolders(res.payload);
      setLoading(false);
    });
  }, []);

  const handleRootFolderClick = (path: string) => {
    setSelectedFolder(path);
    setLoading(true);
    dispatch(loadImageFolders(path)).then(res => {
      setImageFolders(res.payload);
      setLoading(false);
    });
  };

  return (
    <div className={classes.root}>
      <LoadingOverlay visible={isLoading} />
      <Group mb="xs" className={classes.controls}>
        <Button
          size="xs"
          disabled={!selectedFolder}
          onClick={() => {
            setSelectedFolder('');
          }}
        >
          Back
        </Button>
      </Group>
      <div className={classes.foldersWrapper}>
        {!selectedFolder && folders.map((folder, index) =>
          <div
            key={index}
            className={classes.folder}
            onClick={() => {
              handleRootFolderClick(folder);
            }}
          >
            {folder}
          </div>
        )}
        {selectedFolder && imageFolders.map((folder, index) =>
          <div
            key={index}
            className={cx(classes.folder, props.value.indexOf(folder.path) !== -1 && classes.activeFolder)}
            onClick={() => {
              props.onChange(folder.path);
            }}
          >
            <Text>
              {folder.path}
            </Text>
            <Text
              size={'xs'}
              color="blue"
            >
              ({folder.images} images)
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
