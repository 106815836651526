import {Button, Divider, Menu} from '@mantine/core';
import {
  ChevronDown as ChevronDownIcon, LogOut as LogOutIcon,
  Settings as SettingsIcon
} from 'react-feather';
import {logout} from '../app/features/auth/slice';
import {useAppDispatch} from '../app/hooks';

function AccountMenu() {
  const dispatch = useAppDispatch();
  const onLogoutClick = () => {
    dispatch(logout());
  };

  return (
    <Menu
      width={150}
      position="bottom"
    >
      <Menu.Target>
        <Button
          variant="subtle"
          rightIcon={<ChevronDownIcon style={{width: 14}} />}
          style={{color: '#000', fontWeight: 400}}
        >
          Your Account
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Account</Menu.Label>
        <Menu.Item icon={<SettingsIcon />}>Settings</Menu.Item>
        <Divider />
        <Menu.Item
          icon={<LogOutIcon />}
          onClick={onLogoutClick}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default AccountMenu;
