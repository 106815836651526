import {ActionIcon, Group, Popover, createStyles} from '@mantine/core';
import {useEffect, useMemo, useState} from 'react';
import {Filters, loadImageLabels} from '../app/features/imageLabels/slice';
import {Label} from '../app/features/labels/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import editIconImageURL from '../assets/icons/edit.svg';
import ClassificationPreview from './ClassificationPreview';
import LabelForm from './LabelForm';

const useStyles = createStyles(theme => ({
  root: {
    height: 'calc(100vh - 275px)',
    overflowY: 'auto'
  },
  label: {
    marginBottom: 20
  }
}));

interface Props {
  height: number | string;
  labels: Label[];
  zoom?: number;
  onImageDimensionsChange?: (size: {width: number; height: number}) => void;
  image: {
    id: number;
    file: string;
  };
  dataset: {
    id: number;
  };
  disableSearch?: boolean;
  onZoomChange: (zoom: number) => void;
  showToolbox?: boolean;
  visibleLabels?: number[];
}

export default function AnnotationsList({
  image,
  dataset,
  labels,
  disableSearch,
  showToolbox = true,
  zoom = 1,
  onZoomChange,
  visibleLabels = [],
  onImageDimensionsChange = () => { },
  ...props
}: Props) {
  const {classes, cx} = useStyles();
  const dispatch = useAppDispatch();
  const [loadingLabels, setLoadingLabels] = useState(false);

  const [filters, setFilters] = useState<Filters>({
    sensitivity: [20, 49],
    ecd: [
      [0, 255],
    ],
    perimeter: [[0, 16000]],
    area: [[0, 16000]],
    intencity_std: [[0, 255]],
    intencity_mean: [[0, 255]],
    aspectratio: [[0, 1]],
    circularity: [[0, 1]],
  });
  const {
    imageLabels: {
      imageLabels,
    },

  } = useAppSelector(state => state);

  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const annotatorLabels = useMemo(() => {
    if (imageSize === null) {
      return []
    };
    return imageLabels.filter(
      il => !il.is_deleted &&
        visibleLabels.length > 0 ? visibleLabels.indexOf(il.label) !== -1 : true).map(il => {
          const lbl = labels.find(l => l.id === il.label);
          return {
            ...il,
            labelTitle: lbl?.title,
            top: (il.top / imageSize.height),
            left: (il.left / imageSize.width),
            width: (il.width / imageSize.width),
            height: (il.height / imageSize.height),

            absTop: il.top,
            absLeft: il.left,
            absWidth: il.width,
            absHeight: il.height,
          };
        })
  }, [imageLabels, imageSize, labels]);


  useEffect(() => {
    let isMounted = true;
    const img = new Image();
    onZoomChange(1);
    img.onload = function () {
      if (isMounted) {
        setImageSize({
          width: img.width,
          height: img.height,
        });
        onImageDimensionsChange(img);
      }
    };
    img.src = image.file;
    return () => {
      isMounted = false;
    };
  }, [image.file]);

  useEffect(() => {
    setLoadingLabels(true);
    const request = dispatch(loadImageLabels({
      dataset: dataset.id,
      imageId: image.id,
      disable_search: disableSearch,
      filters,
    }));
    request.then(() => {
      setLoadingLabels(false);
    });
    return () => {
      request.abort();
    };
  }, [image.id]);

  return (
    <Group className={classes.root}>
      {annotatorLabels.map(il => (
        <div
          key={il.positional_id}
          className={classes.label}
        >
          <ClassificationPreview
            boundingBoxPadding={10}
            zoomOnHover={false}
            imageURL={image.file}
            top={il.absTop}
            left={il.absLeft}
            width={il.absWidth}
            height={il.absHeight}
            imageId={image.id}
          />
          <Group>
            <div>
              {il.labelTitle}
            </div>

            <Popover
              withArrow
              withinPortal
              position="bottom-end"
            >
              <Popover.Target>
                <ActionIcon>
                  <img src={editIconImageURL} alt="" />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <LabelForm
                  labels={labels}
                  datasetId={dataset.id}
                  imageId={image.id}
                  // onSubmitSuccess={onLabelSubmitSuccess}
                  initialValues={{
                    top: il.absTop,
                    left: il.absLeft,
                    width: il.absWidth,
                    height: il.absHeight,
                    positional_id: il.positional_id,
                    label: il.label,
                  }}
                />
              </Popover.Dropdown>
            </Popover>
          </Group>
        </div>
      ))}
    </Group>
  )
}
