import {Badge, createStyles} from '@mantine/core';
import {openContextModal} from '@mantine/modals';
import {useEffect} from 'react';
import clockIconURL from '../assets/icons/clock.svg';
import errorIconURL from '../assets/icons/error.svg';
import warningIconURL from '../assets/icons/warning.svg';
import {SUBSCRIPTION_MODAL} from './Modals';

const useStyles = createStyles(theme => ({
  root: {
    textTransform: 'initial',
    fontWeight: 500,
    height: 36,
    cursor: 'pointer',
    paddingLeft: 8,
  },
  icon: {
    verticalAlign: 'middle',
    marginBottom: 2,
    width: 16.67,
  },
}));

interface Props {
  daysLeft: number;
  ml?: number;
}


export default function TrialBadge(props: Props) {
  const {classes} = useStyles();
  const getColors = (daysLeft: number) => {
    if (daysLeft > 10) {
      return {
        color: '#fff',
        background: "#4E944F",
      };
    }
    if (daysLeft <= 10 && daysLeft > 5) {
      return {
        color: '#000',
        background: '#FFB340',
      }
    }
    if (daysLeft <= 5) {
      return {
        background: '#FF4040',
        color: '#fff',
      }
    }
    return {
      color: '#fff',
      background: "#4E944F",
    };
  };

  const getIcon = (daysLeft: number) => {
    if (daysLeft > 10) {
      return <img
        src={clockIconURL}
        alt=""
        className={classes.icon}
      />;
    }
    if (daysLeft <= 10 && daysLeft > 5) {
      return <img
        src={warningIconURL}
        alt=""
        className={classes.icon}
      />
    }
    if (daysLeft <= 5) {
      return <img
        src={errorIconURL}
        alt=""
        className={classes.icon}
      />
    }
    return <img
      src={clockIconURL}
      alt=""
      className={classes.icon}
    />;
  };

  const onRequestSubmit = () => {

  };
  useEffect(() => {
    if (props.daysLeft <= 0) {
      openContextModal({
        modal: SUBSCRIPTION_MODAL,
        title: '',
        size: 684,
        innerProps: {
          closable: false
        },
        withCloseButton: false,
        closeOnClickOutside: false,
        closeOnEscape: false,
      });
    }
  }, [props.daysLeft])

  return (
    <Badge
      size="lg"
      ml={props.ml}
      className={classes.root}
      style={{
        ...getColors(props.daysLeft)
      }}
      leftSection={getIcon(props.daysLeft)}
      onClick={() => {
        openContextModal({
          modal: SUBSCRIPTION_MODAL,
          title: '',
          size: 684,
          innerProps: {
            onSubmitSuccess: onRequestSubmit
          }
        });
      }}
    >
      {props.daysLeft > 0 ?

        `You have ${props.daysLeft} days of free trial period`
        :
        'Your trial period is over'
      }
    </Badge>
  )
}
