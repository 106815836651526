import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from "redux-first-history";
import logger from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import adminReducer from './features/admin/slice';
import authReducer from './features/auth/slice';
import dataImportReducer from './features/dataImport/slice';
import datasetDetailsReducer from './features/datasets/details';
import datasetsReducer from './features/datasets/slice';
import elabReducer from './features/elab/slice';
import imageLabelsReducer from './features/imageLabels/slice';
import imagesReducer from './features/images/slice';
import imagingDevicesReducer from './features/imagingDevices';
import calibrationReportReducer from './features/inference/calibration';
import reportsComparisonReducer from './features/inference/comparison';
import inferenceSessionsReducer from './features/inference/slice';
import statisticsReducer from './features/inference/stats';
import labelsReducer from './features/labels/slice';
import quickGuideReducer from './features/quick-guide/slice';
import sessionsReducer from './features/sessions/slice';
import inferenceModelsReducer from './features/training/modelsList';
import sessionDetailsReducer from './features/training/sessionDetails';
import trainingSessionReducer from './features/training/slice';

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed
});

const persistConfig = {
  key: 'mpvl-data',
  whitelist: ['auth', 'quickGuide'],
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  labels: labelsReducer,
  sessions: sessionsReducer,
  images: imagesReducer,
  imageLabels: imageLabelsReducer,
  datasets: datasetsReducer,
  elabSections: elabReducer,
  trainingSessions: trainingSessionReducer,
  trainingSessionDetails: sessionDetailsReducer,
  inferenceModels: inferenceModelsReducer,
  inferenceSessions: inferenceSessionsReducer,
  inferenceStatistics: statisticsReducer,
  datasetDetails: datasetDetailsReducer,
  imagingDevices: imagingDevicesReducer,
  quickGuide: quickGuideReducer,
  importJobs: dataImportReducer,
  calibrationReport: calibrationReportReducer,
  admin: adminReducer,
  reportsComparisonData: reportsComparisonReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "admin/datasets/download/fulfilled"],
      },
    }).concat([thunkMiddleware, routerMiddleware]).concat(process.env.NODE_ENV === 'development' ? [logger] : []),
});

export const persistor = persistStore(store)
export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStoreType = typeof store;

export default store;
