import {Button, Card, createStyles, FileButton, Group, Select, Title} from '@mantine/core';
import {useForm} from '@mantine/form';
import {saveAs} from 'file-saver';
import {useEffect, useState} from 'react';
import {downloadDataset, loadDatasets, uploadDataset} from '../../app/features/admin/slice';
import {useAppDispatch, useAppSelector} from '../../app/hooks';

const useStyles = createStyles(theme => ({
  root: {

  },
  datasetWrapper: {
    border: 'solid 1px #ccc',
    padding: theme.spacing.sm,
    marginBottom: theme.spacing.md,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  dsFormWrapper: {
    marginTop: theme.spacing.md,
    width: 300,
  }
}));

export default function Datasets() {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const [dsFile, setDsFile] = useState<File | null>(null);
  const [uploadingDataset, setUploadingDataset] = useState(false);
  const [downloadingDataset, setDownloadingDataset] = useState(0);
  const [datasetsOffset, setDatasetsOffset] = useState(0);
  const dsForm = useForm({
    initialValues: {
      userId: "",
    }
  });

  useEffect(() => {
    dispatch(loadDatasets(datasetsOffset));
  }, [datasetsOffset]);

  const users = useAppSelector(state => state.admin.users);
  const datasets = useAppSelector(state => {
    return state.admin.datasets.map(ds => ({
      ...ds,
      user: state.admin.users.filter(user => user.id === ds.user),
    }));
  });

  const handleDatasetUpload = (values: {userId: string}) => {
    setUploadingDataset(true);
    dispatch(uploadDataset({
      userId: parseInt(values.userId, 10),
      dsFile: dsFile as File,
    })).then(() => {
      setUploadingDataset(false);
    });
  };

  return (
    <>
      <Card withBorder mb="md">
        <FileButton onChange={setDsFile} accept="application/x-tar">
          {(props) => <Button {...props}>Upload Dataset File</Button>}
        </FileButton>
        {dsFile !== null &&
          <div className={classes.dsFormWrapper}>
            <form onSubmit={dsForm.onSubmit(handleDatasetUpload)}>
              <Select
                label="User"
                data={users.map(user => ({
                  value: user.id + '',
                  label: user.username,
                }))}
                mb="sm"
                {...dsForm.getInputProps('userId')}
              />
              <Button
                type="submit"
                loading={uploadingDataset}
              >
                Save
              </Button>
            </form>
          </div>
        }
      </Card>
      <Card>
        {datasets.map(ds =>
          <div
            key={ds.id}
            className={classes.datasetWrapper}
          >
            <Group position='apart'>
              <div>
                <Title order={5}>{ds.title}</Title>
              </div>
              <div>
                <Button.Group >
                  <Button
                    size="xs"
                    loading={downloadingDataset === ds.id}
                    onClick={() => {
                      setDownloadingDataset(ds.id);
                      dispatch(downloadDataset(ds.id)).then(res => {
                        saveAs(res.payload, `dataset_${ds.id}.${ds.title.replace(' ', '-')}.tar`);
                        setDownloadingDataset(0);
                      });
                    }}
                  >
                    Download
                  </Button>
                </Button.Group>
              </div>
            </Group>
          </div>
        )}
        <Button
          fullWidth
          onClick={() => {
            setDatasetsOffset(datasetsOffset + 100);
          }}
        >
          Load More
        </Button>
      </Card>
    </>
  );
}
