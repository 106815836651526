import { createStyles, Text } from '@mantine/core';
import { useState } from 'react';
import ImageZoom from './ImageZoom';


const useStyles = createStyles(theme => ({
  root: {
    position: 'relative',
  },
  label: {
    position: 'absolute',
    border: `solid 1px ${theme.colors.red[5]}`,
  },
  labelTitle: {
    position: 'absolute',
    bottom: '100%',
    left: 0,
    fontSize: '80%',
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: '#fff',
    whiteSpace: 'nowrap'
  }
}));

interface Props {
  src: string;
  labels: {
    id: number;
    title: string;
    top: number;
    left: number;
    width: number;
    height: number;
  }[];
}

export default function LabelsPreview(props: Props) {
  const { classes } = useStyles();
  const [scale, setScale] = useState(1);
  return (
    <div className={classes.root}>
      <ImageZoom
        zoom={1}
        onZoomChange={() => {
        }}
        onScaleChange={setScale}
        src={props.src}
        height={600}
      />
      {props.labels.map(label =>
        <div
          key={label.id}
          className={classes.label}
          style={{
            top: label.top / scale,
            left: label.left / scale,
            width: label.width / scale,
            height: label.height / scale,
          }}
        >
          <Text
            className={classes.labelTitle}
          >
            {label.title}
          </Text>
        </div>
      )}
    </div>
  )
}
