import {
  Accordion,
  Anchor,
  Button,
  Card, Checkbox, Col,
  Drawer,
  Grid,
  Group,
  Image,
  Tooltip as MantineTooltip,
  Menu,
  Modal, Pagination, Popover, RangeSlider, SimpleGrid, Skeleton,
  Table,
  Text,
  Title,
  createStyles,
  useMantineTheme
} from '@mantine/core';
import {randomId, useDebouncedState, useDocumentTitle} from '@mantine/hooks';
import {openContextModal} from '@mantine/modals';
import React, {useRef, useState} from 'react';
import {
  MoreHorizontal as IconMore
} from 'react-feather';
import {useParams} from 'react-router-dom';
import {useReactToPrint} from "react-to-print";
import {loadInferenceAnomaliesStats, loadInferenceReportStats, loadInferenceSessionReport} from '../app/features/inference/stats';
import {TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import pendingReportImageURL from '../assets/images/report_pending.svg';
import {formatDate} from '../utils/formatting';
import BarStacked from './BarStacked';
import ClassificationPreview from './ClassificationPreview';
import InferenceObjectsTable from './InferenceObjectsTable';
import InfoIcon from './InfoIcon';
import {INFERENCE_REPORT_CONVERT} from './Modals';
import ParticlesFilterForm, {ParticlesFilter} from './ParticlesFilterForm';
import ParticlesPieChart from './ParticlesPieChart';
import Tooltip from './Tooltip';

interface Props {

}

const useStyles = createStyles((theme) => ({
  print: {
    display: 'inline-block',
    width: '10%',
    height: 30,
    border: 'solid 1px #ccc',
    padding: 10,
    margin: 4,
    textAlign: 'center',
    pageBreakInside: 'avoid',
    breakInside: 'avoid',
    '& div': {
      breakInside: 'avoid',
    }
  },
  root: {
    position: 'relative',
  },
  rect: {
    position: 'absolute',
    border: 'solid 1px red',
  },
  pageTitle: {
    display: 'block',
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
  },
  label: {
    position: 'absolute',
    bottom: '100%',
    fontSize: 12,
  },
  classificationsGroup: {
    marginBottom: theme.spacing.md,

  },
  classificationsTable: {
  },
  datasetInfo: {
    marginBottom: theme.spacing.sm
  },
  tableWrapper: {
    marginBottom: theme.spacing.sm
  },
  skeleton: {
    marginBottom: theme.spacing.sm,
  },
  pendingReport: {
  },
  pendingReportAlert: {
    textAlign: 'center',
    marginBottom: theme.spacing.md
  },
  pendingReportImage: {
    display: 'block',
    width: '40%',
    margin: '0 auto'
  },
  reportTitle: {
    // marginBottom: theme.spacing.sm
  },
  intersectionsCard: {
    marginBottom: theme.spacing.md,
  },
  inferenceImagesTab: {
    borderBottom: 0
  },
  inferenceImagesTabOpen: {
    padding: 0,
  },
  inferenceImage: {
    cursor: 'pointer',
  }
}));

const parseFilters = (data: ParticlesFilter) => {
  const ret: any = {};
  Object.keys(data).forEach((key) => {
    // @ts-ignore
    ret[key + '_min'] = data[key][0];
    // @ts-ignore
    ret[key + '_max'] = data[key][1];
  });
  return ret;
}
export default function InferenceReport(props: Props) {
  const {sessionId = '1'} = useParams<{sessionId: string}>();
  const [page, setPage] = useState(1);
  const [anomaliesPage, setAnomaliesPage] = useState(1);
  useDocumentTitle(`Inference Report #${sessionId} | mpVision`);
  const [printDropdownHidden, setPrintDropdownHidden] = useState(false);

  const printComponentRef = useRef<HTMLDivElement>();
  const printPage = useReactToPrint({
    content: () => printComponentRef.current!,
    onAfterPrint: () => setPrintDropdownHidden(false),
  });
  const handlePrint = () => {
    setPrintDropdownHidden(true);
    setTimeout(() => {
      printPage()
    }, 10);
  };
  const {classes, cx} = useStyles();
  const theme = useMantineTheme();
  const [areFiltersOpen, setFiltersOpen] = useState(false);
  const [areFiltersApplied, setFiltersApplied] = useState(false);
  const inferenceResults = useAppSelector(state => state.inferenceStatistics);
  const statsTotal = useAppSelector(state => state.inferenceStatistics.count);
  const anomaliesStats = useAppSelector(state => state.inferenceStatistics.anomalies);
  const [viewingImageId, setImageId] = React.useState(0);
  const [isLoading, setLoading] = React.useState(true);
  const [ecdRange, setEcdRange] = useDebouncedState([5, 200], 300);
  const [filters, setFilters] = React.useState<ParticlesFilter[]>([{
    key: randomId(),
    ecd: [0, 2555],
    perimeter: [0, 2555],
    area: [0, 5000],
    mean: [0, 2555],
    std: [0, 2555],
    aspectratio: [0, 1],
    circularity: [0, 1],
  }]);
  const handleImageModalClose = () => {
    setImageId(0);
  };

  const dispatch = useAppDispatch();
  const handleConvertClick = () => {
    openContextModal({
      modal: INFERENCE_REPORT_CONVERT,
      title: 'Create Training Dataset from this Report',
      innerProps: {
        reportId: sessionId
      }
    });
  }
  React.useEffect(() => {
    setLoading(true);
    setPage(1);
    dispatch(loadInferenceSessionReport({sessionId, filters: parseFilters(filters[0])})).then(() => {
      setLoading(false);
    }).then(() => {
      dispatch(loadInferenceReportStats({sessionId, page, ecdRange}));
    });
  }, [sessionId]);

  const handleFiltersChange = (filters: ParticlesFilter[]) => {
    setLoading(true);
    setPage(1);
    dispatch(loadInferenceSessionReport({sessionId, filters: parseFilters(filters[0])})).then(() => {
      setLoading(false);
    }).then(() => {
      dispatch(loadInferenceReportStats({sessionId, page, ecdRange}));
    });
  };

  React.useEffect(() => {
    setLoading(true);
    setPage(1);
    dispatch(loadInferenceSessionReport({sessionId, filters: parseFilters(filters[0])})).then(() => {
      setLoading(false);
    }).then(() => {
      dispatch(loadInferenceReportStats({sessionId, page, ecdRange}));
    });
  }, [filters]);

  React.useEffect(() => {
    dispatch(loadInferenceReportStats({sessionId, page, ecdRange}));
  }, [page, ecdRange]);

  React.useEffect(() => {
    dispatch(loadInferenceAnomaliesStats({sessionId, page: anomaliesPage}));
  }, [anomaliesPage]);

  const classificationsSorted = inferenceResults.data_by_classifications
    .map(({classification}) => classification).sort();

  const rows = inferenceResults.data_by_classifications.map((data) => {
    return (
      <tr key={data.classification}>
        <td>{data.classification}</td>
        <td>{data.count}</td>
        <td>{data.avg_ecd.toFixed(2)}</td>
        <td>{data.stdev_ecd.toFixed(2)}</td>
        <td>{data.avg_feret.toFixed(2)}</td>
        <td>{data.stdev_feret.toFixed(2)}</td>
        <td>{data.avg_aspect.toFixed(2)}</td>
        <td>{data.stdev_aspect_ratio.toFixed(2)}</td>
        <td>{data.avg_area.toFixed(2)}</td>
        <td>{data.stdev_area.toFixed(2)}</td>
        <td>{data.avg_circularity.toFixed(2)}</td>
        <td>{data.stdev_circularity.toFixed(2)}</td>
      </tr>
    );
  });

  return (
    // @ts-ignore
    <div className={cx(classes.root)} ref={printComponentRef}>
      <Drawer
        position='right'
        opened={areFiltersOpen}
        onClose={() => {
          setFiltersOpen(false);
        }}
        overlayColor={theme.colors.gray[2]}
        overlayOpacity={0.3}
        overlayBlur={0}
      >
        <Card
          style={{
            height: '95vh',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <ParticlesFilterForm
            initialValues={{
              canny: [20, 49],
              filters: filters
            }}
            onChange={(v) => {
              setFiltersApplied(true);
              setFilters(v.filters);
              handleFiltersChange(v.filters)
            }}
          />
        </Card>
      </Drawer>
      {isLoading &&
        <>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(i =>
            <Skeleton
              key={i}
              height={18}
              className={classes.skeleton}
            />
          )}
        </>
      }
      {!isLoading && inferenceResults.data_by_classifications.length === 0 &&
        <div className={classes.pendingReport}>
          <div className={classes.pendingReportAlert}>
            The inference process is still in progress. Results will be populated into this reports when ready.
          </div>
          <Image
            src={pendingReportImageURL}
            className={classes.pendingReportImage}
          />
        </div>
      }
      {!isLoading && inferenceResults.data_by_classifications.length > 0 &&
        <>
          <div>
            <Group
              align={'left'}
              style={{
                flexWrap: 'nowrap'
              }}
              className={classes.classificationsGroup}
            >
              <Card
                className={classes.classificationsTable}
              >
                <Group
                  position='apart'
                >
                  <Group>
                    <Tooltip
                      isFinal
                      tutorialComponent={TutorialComponent.REVIEW_REPORT_DATA}
                      learnMoreLink='q-inference-report'
                      target={
                        <Title
                          order={3}
                          className={classes.reportTitle}
                        >
                          Inference Report
                          <InfoIcon />
                        </Title>
                      }
                      content={
                        <>
                          <Text size="sm">
                            Review tabular statistical data as well as graphic representations of the AI model outputs.  You’re finished!
                          </Text>
                        </>
                      }
                    />
                  </Group>
                  <Group>
                    <Checkbox
                      label="Include stuck particles"
                    />
                    {!printDropdownHidden &&
                      <Popover width={200} position="bottom" withArrow shadow="md">
                        <Popover.Target>
                          <Button size="xs">Download Report</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Group>
                            <Button
                              variant="light"
                              size="xs"
                              onClick={handlePrint}
                            >
                              PDF
                            </Button>
                            <Button
                              // @ts-ignore
                              component="a"
                              target="_blank"
                              variant="light"
                              size="xs"
                              href={`/api/inference/session/${sessionId}/csv-report/`}
                            >
                              CSV
                            </Button>
                            <Button
                              // @ts-ignore
                              component="a"
                              target="_blank"
                              variant="light"
                              size="xs"
                              href={`/api/inference/session/${sessionId}/excel-report/`}
                            >
                              Excel
                            </Button>
                          </Group>
                        </Popover.Dropdown>
                      </Popover>}
                    <Button.Group>
                      <Button
                        size="xs"
                        variant="outline"
                        onClick={() => {
                          setFiltersOpen(true);
                        }}
                      >
                        Filters
                      </Button>
                      <Button
                        size="xs"
                        disabled={!areFiltersApplied}
                        onClick={() => {
                          setFilters([{
                            key: randomId(),
                            ecd: [0, 255],
                            perimeter: [0, 255],
                            area: [0, 500],
                            mean: [0, 255],
                            std: [0, 255],
                            aspectratio: [0, 1],
                            circularity: [0, 1],
                          }]);
                          setFiltersApplied(false);
                        }}
                      >
                        {areFiltersApplied ? 'Reset Filters' : 'Not applied'}
                      </Button>
                    </Button.Group>
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <Button
                          size="xs"
                          variant="filled"
                        >
                          <IconMore size={14} />
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Label>Report Processing</Menu.Label>
                        <Menu.Item
                          onClick={handleConvertClick}
                        >Create training dataset</Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                </Group>
                <Text
                  color="gray"
                  className={classes.datasetInfo}
                >
                  Date: {formatDate(inferenceResults.dataset.date)} /
                  Dataset: <Anchor
                    href={`/inference/${inferenceResults.dataset.id}/`}
                    target="_blank"
                  >
                    {inferenceResults.dataset.title}
                  </Anchor>  /
                  {' '} Model: <Anchor
                    target="_blank"
                    href={`/training/session/${inferenceResults.model.training_session_id}`}
                  >
                    {inferenceResults.model.title} (model id: {inferenceResults.model.checkpoint})
                  </Anchor>
                </Text>
                <div style={{width: '100%', overflowX: 'auto'}}>
                  <Table>
                    <thead>
                      <tr>
                        <th>Classification</th>
                        <th>Amount</th>
                        <th>ECD Avg (um)</th>
                        <th>ECD Stdev (um)</th>
                        <th>Max Feret Avg (px)</th>
                        <th>Max Feret Stdev (px)</th>
                        <th>Aspect Ratio Avg</th>
                        <th>Aspect Ratio Stdev</th>
                        <th>Area Avg (px)</th>
                        <th>Area Stdev (px)</th>
                        <th>Circularity Avg</th>
                        <th>Circularity Stdev</th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                </div>
              </Card>
              <Card style={{maxWidth: 240}}>
                <ParticlesPieChart
                  data={inferenceResults.data_by_classifications.map(dt => ({
                    name: dt.classification,
                    value: dt.count,
                  }))}
                  classificationsSorted={classificationsSorted}
                />
              </Card>
            </Group>
          </div>
          <Grid>
            <Col span={6}>
              <Card>
                <Text>ECD Combined</Text>
                <div style={{height: 400}}>
                  <BarStacked
                    showTooltip={true}
                    data={[inferenceResults.histograms.combined.ecd]}
                  />
                </div>
                <Text mt="lg">ECD By Classifications</Text>
                <div style={{height: 400}}>
                  <BarStacked
                    showTooltip={true}
                    data={inferenceResults.histograms.by_classification.ecd}
                  />
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Text>Max Feret Combined</Text>
                <div style={{height: 400}}>
                  <BarStacked
                    showTooltip={true}
                    data={[inferenceResults.histograms.combined.max_feret]}
                  />
                </div>
                <Text mt="lg">Max Feret By Classifications</Text>
                <div style={{height: 400}}>
                  <BarStacked
                    showTooltip={true}
                    data={inferenceResults.histograms.by_classification.max_feret}
                  />
                </div>
              </Card>
            </Col>
          </Grid>

          <Grid>
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span={12}>
                  {anomaliesStats.count > 0 &&
                    <Card
                      mb="sm"
                    >
                      <Accordion
                        classNames={{
                          item: classes.inferenceImagesTab,
                          content: classes.inferenceImagesTabOpen
                        }}
                      >
                        <Accordion.Item value="inferenced_images">
                          <Accordion.Control>Detected Anomalies</Accordion.Control>
                          <Accordion.Panel style={{padding: 16}}>
                            <Pagination
                              total={Math.ceil(anomaliesStats.count / 100)}
                              page={anomaliesPage}
                              onChange={setAnomaliesPage}
                            />
                            <InferenceObjectsTable
                              hideClassification
                              sessionId={parseInt(sessionId, 10)}
                              stats={anomaliesStats.items}
                              datasetId={inferenceResults.dataset.id}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                    </Card>
                  }
                  <Card
                    mb="sm"
                  >
                    <Accordion
                      classNames={{
                        item: classes.inferenceImagesTab,
                        content: classes.inferenceImagesTabOpen
                      }}
                    >
                      <Accordion.Item value="inferenced_images">
                        <Accordion.Control>View Inferenced Images</Accordion.Control>
                        <Accordion.Panel style={{padding: 16}}>
                          <Group>
                            <div style={{width: 400}}>
                              <Text>
                                ECD Range Filter (um)
                              </Text>
                              <RangeSlider
                                style={{
                                  width: '100%'
                                }}
                                min={5}
                                max={2000}
                                defaultValue={[5, 200]}
                                onChange={value => {
                                  if (value) {
                                    setEcdRange(value);
                                  }
                                }}
                              />
                            </div>
                            <Pagination
                              total={Math.ceil(statsTotal / 100)}
                              page={page}
                              onChange={setPage}
                            />
                          </Group>
                          <InferenceObjectsTable
                            sessionId={parseInt(sessionId, 10)}
                            stats={inferenceResults.all_stats}
                            datasetId={inferenceResults.dataset.id}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  </Card>
                  {inferenceResults.all_stats.filter(stat => stat.is_stuck).length > 0 &&
                    <Card
                      mb="sm"
                    >
                      <Accordion
                        classNames={{
                          item: classes.inferenceImagesTab,
                          content: classes.inferenceImagesTabOpen
                        }}
                      >
                        <Accordion.Item value="inferenced_images">
                          <Accordion.Control>Stuck Particles</Accordion.Control>
                          <Accordion.Panel style={{padding: 16}}>
                            <SimpleGrid cols={16}>
                              {inferenceResults.all_stats.filter(stat => stat.is_stuck).map(stat =>
                                <div key={stat.id}>
                                  {stat.classification}
                                  <MantineTooltip
                                    label={
                                      <div>
                                        <div>ECD: {stat.ecd.toFixed(2)}</div>
                                        <div>AREA: {stat.area.toFixed(2)}</div>
                                        <div>MAX FERET: {stat.max_feret.toFixed(2)}</div>
                                        <div>PERIMETER: {stat.perimeter.toFixed(2)}</div>
                                      </div>
                                    }
                                  >
                                    <div>
                                      <ClassificationPreview
                                        key={stat.id}
                                        imageURL={stat.image}
                                        top={stat.y}
                                        left={stat.x}
                                        width={stat.width}
                                        height={stat.height}
                                        imageId={stat.image_id}
                                      />
                                    </div>
                                  </MantineTooltip>
                                </div>
                              )}
                            </SimpleGrid>
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                    </Card>
                  }
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          <Modal
            size="100%"
            onClose={handleImageModalClose}
            opened={viewingImageId !== 0}
          >
            {viewingImageId !== 0 &&
              <Image
                src={inferenceResults.images.filter(img => img.id === viewingImageId)[0].url}
              />
            }
          </Modal>
        </>
      }
    </div>
  );
};
