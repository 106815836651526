
import {Button, createStyles, Text} from '@mantine/core';
import {useState} from 'react';
import {convertImageColor} from '../app/features/images/slice';
import {useAppDispatch} from '../app/hooks';
import Tooltip from './Tooltip';

const useStyles = createStyles(() => ({
  root: {
    backgroundColor: '#E7EAEE',
    fontWeight: 500,
    color: '#808081',
    '&:hover': {
      color: '#808081',
      backgroundColor: '#E7EAEE',
    }
  },
}));


interface Props {
  imageId: number;
  isGrayscale: boolean;
  datasetId: number;

  disableTooltip?: boolean;
}

export default function ButtonGrayscale(props: Props) {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const handleImageColorsConvert = () => {
    if (!props.imageId) {
      return;
    }
    setLoading(true);
    return dispatch(convertImageColor({
      imageId: props.imageId,
      datasetId: props.datasetId,
      mode: props.isGrayscale ? 'color' : 'grayscale',
    })).then(() => setLoading(false));
  };

  return (
    <Tooltip
      disabled={props.disableTooltip}
      learnMoreLink="q-grayscale"
      target={
        <Button
          size="xs"
          className={classes.root}
          loading={loading}
          onClick={handleImageColorsConvert}
        >
          Convert to {props.isGrayscale ? "RGB" : "grayscale"}
        </Button>
      }
      content={
        <>
          <Text size="sm" transform="uppercase" mb="sm">
            Greyscale / RGB
          </Text>

          <Text size="sm">
            Choose either Color or Grayscale to select the optimal color scheme for outlining of the particles in your image.
          </Text>

        </>
      }
    />
  )
}
