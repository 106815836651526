import {Center, Image, Progress, Text} from '@mantine/core';
import uploadIconImageURL from '../assets/icons/upload.svg';

interface Props {
  total: number;
  uploaded: number;
}

export default function UploadingInfo(props: Props) {
  return (
    <div>
      <Center>
        <Image
          alt=""
          src={uploadIconImageURL}
          mb="lg"
          width={50}
        />
      </Center>
      <Text align="center" px="lg" mb="lg" sx={{pointerEvents: 'none'}}>
        Uploading files: {props.uploaded} / {props.total}
      </Text>
      <Progress value={(props.uploaded / props.total) * 100} animate />
    </div>
  );
}
