import {Box, Divider, Text, Title} from '@mantine/core';
import ProcessingWorkflow from './ProcessingWorkflow';

export default function DocumentationTraining() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        While the AI is training, it makes predictions on the identity and location of
        individual objects in each image. Localisation refers to the location of an object
        with coordinates, and classification refers to what the object is (e.g. a cell or protein).
        The model's prediction error is a combination of both localisation and classification errors,
        and this is called the loss. Over time, the AI continues to correct its misclassifications as
        it continues to learn from each labeled image. The model's loss therefore decreases over the
        course of the training and is monitored in the Training Loss Curve. The training loss values
        should approach 0 over time.
      </Text>
      <Text size="sm" mb="md" mt="sm">
        During the course of the AI training, 20% of the labeled images are selected for model validation.
        These selected images comprise a separate validation data set. Validation exposes the neural network
        to these 'unseen' validation images on which it has not trained. This approach ensures the generalisability
        of the AI to future data. The performance on the AI is monitored with the same loss heuristic as the training,
        which is depicted in the Validation Loss curve. The validation loss values should approach 0 over time.
      </Text>
      <Divider my="sm" variant="dashed" />
      <Title
        order={5}
        style={{fontWeight: 500}}
        mb="xs"
      >
        Initial Processing Workflow
      </Title>
      <Text size="sm" mb="lg">
        Process workflow from initial image upload to final report generation. Each stage must be performed consecutively during initial software usage.
      </Text>
      <Box mb="lg">
        <ProcessingWorkflow
          activeIndex={1}
        />
      </Box>
    </>
  )
}
