import {
  Anchor,
  Card, createStyles,
  Grid,
  Group,
  Image, Skeleton,
  Table,
  Text,
  Title
} from '@mantine/core';
import React from 'react';
import {Helmet} from 'react-helmet';
import {Link, useParams} from 'react-router-dom';
import {loadCalibrationReport} from '../app/features/inference/calibration';
import {goToNextStep, TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import pendingReportImageURL from '../assets/images/report_pending.svg';
import {formatDate} from '../utils/formatting';
import InfoIcon from './InfoIcon';
import LabelsPreview from './LabelsPreview';
import Tooltip from './Tooltip';

// const SCALE_COLORS = scaleOrdinal(schemeCategory10).range();

// const COLORS = [
//   'green',
//   'blue',
//   'magenta',
//   'purple',
// ].concat(SCALE_COLORS);

interface Props {

}

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  rect: {
    position: 'absolute',
    border: 'solid 1px red',
  },
  pageTitle: {
    display: 'block',
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
  },
  label: {
    position: 'absolute',
    bottom: '100%',
    fontSize: 12,
  },
  classificationsGroup: {
    marginBottom: theme.spacing.md,
  },
  classificationsTable: {
  },
  datasetInfo: {
    marginBottom: theme.spacing.sm
  },
  tableWrapper: {
    marginBottom: theme.spacing.sm
  },
  skeleton: {
    marginBottom: theme.spacing.sm,
  },
  pendingReport: {
  },
  pendingReportAlert: {
    textAlign: 'center',
    marginBottom: theme.spacing.md
  },
  pendingReportImage: {
    display: 'block',
    width: '40%',
    margin: '0 auto'
  },
  reportTitle: {
    marginBottom: theme.spacing.sm
  },
  intersectionsCard: {
    marginBottom: theme.spacing.md,
  },
  inferenceImagesTab: {
    borderBottom: 0
  },
  inferenceImagesTabOpen: {
    padding: 0,
  },
  inferenceImage: {
    cursor: 'pointer',
  }
}));

export default function CalibrationReport(props: Props) {
  const {classes} = useStyles();
  const inferenceResults = useAppSelector(state => state.calibrationReport);
  const [isLoading, setLoading] = React.useState(true);
  const {sessionId = '1'} = useParams<{sessionId: string}>();

  const sessionIdN = parseInt(
    sessionId,
    10
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadCalibrationReport({sessionId: sessionIdN})).then(() => {
      setLoading(false);
    });
    dispatch(goToNextStep(TutorialComponent.CLICK_VIEW_INFERENCE_SESSION));
  }, [dispatch, sessionId]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Calibration Report | mpVision</title>
      </Helmet>

      {isLoading &&
        <>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(i =>
            <Skeleton
              key={i}
              height={18}
              className={classes.skeleton}
            />
          )}
        </>
      }

      {!isLoading && inferenceResults.labels_data.length === 0 &&
        <div className={classes.pendingReport}>
          <div className={classes.pendingReportAlert}>
            Calibration process is still in progress. Results will be populated into this reports when ready.
          </div>
          <Image
            src={pendingReportImageURL}
            className={classes.pendingReportImage}
          />
        </div>
      }

      {!isLoading && inferenceResults.labels_data.length > 0 &&
        <>
          <Card
            className={classes.classificationsTable}
            mb="sm"
          >
            <Group
              position='apart'
            >
              <div>
                <Tooltip
                  isFinal
                  tutorialComponent={TutorialComponent.REVIEW_REPORT_DATA}
                  learnMoreLink='q-inference-report'
                  target={
                    <Title
                      order={3}
                      className={classes.reportTitle}
                    >
                      Calibration Report for model "{inferenceResults.model.title}"
                      <InfoIcon />
                    </Title>
                  }
                  content={
                    <>
                      <Text size="sm">
                        Review tabular statistical data as well as graphic representations of the AI model outputs.
                      </Text>
                    </>
                  }
                />
              </div>
            </Group>
            <Text
              color="gray"
              className={classes.datasetInfo}
            >
              Dataset: {inferenceResults.dataset.title}  / Date: {formatDate(inferenceResults.dataset.date)}
            </Text>

            <div style={{width: '100%', overflowX: 'auto', }}>
              <Table>
                <thead>
                  <tr>
                    <th>Classes</th>
                    <th>Accuracy (Avg)</th>
                    <th>Accuracy (Stdev)</th>

                    <th>True Positive (Avg)</th>
                    <th>True Positive (Stdev)</th>

                    <th>True Negative (Avg)</th>
                    <th>True Negative (Stdev)</th>

                    <th>False Positive (Avg)</th>
                    <th>False Positive (Stdev)</th>

                    <th>False Negative (Avg)</th>
                    <th>False Negative (Stdev)</th>
                  </tr>
                </thead>
                <tbody>
                  {inferenceResults.summary.map(data =>
                    <tr key={data.classification}>
                      <td>{data.classification}</td>

                      <td>{data.avg_accuracy.toFixed(2)}</td>
                      <td>{data.std_accuracy.toFixed(2)}</td>

                      <td>{data.avg_true_positive}</td>
                      <td>{data.std_true_positive}</td>

                      <td>{data.avg_true_negative}</td>
                      <td>{data.std_true_negative}</td>

                      <td>{data.avg_false_positive}</td>
                      <td>{data.std_false_positive}</td>

                      <td>{data.avg_false_negative}</td>
                      <td>{data.std_false_negative}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card>

          <Grid>
            <Grid.Col span={12}>
              {inferenceResults.labels_data.map((calibration_data, index) =>
                <Card
                  key={index}
                  mb="sm"
                >
                  <Text
                    mb="md"
                  >

                  </Text>
                  <div style={{width: '100%', overflowX: 'auto', backgroundColor: '#f0f0f0'}}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Classes</th>
                          <th>Accuracy</th>
                          <th>True Positive</th>
                          <th>True Negative</th>
                          <th>False Positive</th>
                          <th>False Negative</th>
                        </tr>
                      </thead>
                      <tbody>
                        {calibration_data.calibration_results.map((data) => {
                          return (
                            <tr key={data.classification}>
                              <td>{data.classification}</td>
                              <td>{data.accuracy.toFixed(2)}</td>
                              <td>{data.true_positive}</td>
                              <td>{data.true_negative}</td>
                              <td>{data.false_positive}</td>
                              <td>{data.false_negative}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Grid>
                    <Grid.Col span={6}>
                      <Title
                        order={4}
                        mb="md"
                        mt="md"
                      >
                        Calibration AI Output
                        (<Anchor component={Link} to={`/calibration/${inferenceResults.dataset.id}/${calibration_data.id}/`}>
                          Image #{calibration_data.id}
                        </Anchor>)
                      </Title>
                      <LabelsPreview
                        src={calibration_data.url}
                        labels={calibration_data.inference_results}
                      />

                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Title
                        order={4}
                        mb="md"
                        mt="md"
                      >
                        Labelled Calibration Images (<Anchor component={Link} to={`/calibration/${inferenceResults.dataset.id}/${calibration_data.id}/`}>Image #{calibration_data.id}</Anchor>)
                      </Title>
                      <LabelsPreview
                        src={calibration_data.url}
                        labels={calibration_data.labels}
                      />
                    </Grid.Col>
                  </Grid>
                </Card>
              )}
            </Grid.Col>
          </Grid>

        </>
      }
    </div>
  );
};
