import {Badge, Button, Card, Center, Checkbox, Col, createStyles, Grid, Group, Select, Table, TextInput, Title} from '@mantine/core';
import {DateRangePicker} from '@mantine/dates';
import React, {useState} from 'react';
import {Search} from 'react-feather';
import {Link, useNavigate} from 'react-router-dom';
import * as analytics from '../app/features/analytics';
import {loadInferenceSessions} from '../app/features/inference/slice';
import {goToNextStep, TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import * as urls from '../urls';
import {formatDate} from '../utils/formatting';

const useStyles = createStyles(theme => ({
  root: {
  },
  filtersTitle: {
    marginBottom: theme.spacing.md,
  },
  filtersWrapper: {
    height: 'calc(100vh - 100px)'
  },
  overflowWrapper: {
    height: 'calc(100vh - 100px)',
    overflow: 'auto'
  }
}));

export default function PageInferenceReports() {
  const {classes, cx} = useStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [reportsToCompare, setCompareReports] = useState<number[]>([]);
  const sessions = useAppSelector(state => state.inferenceSessions);

  const [value, setValue] = React.useState<[Date | null, Date | null]>([
    new Date(2021, 11, 1),
    new Date(),
  ]);

  React.useEffect(() => {
    dispatch(loadInferenceSessions());
    analytics.logPageVisit({eventName: analytics.INFERENCE_REPORT});
    dispatch(goToNextStep(TutorialComponent.CLICK_REPORTS_SECTION));
  }, [dispatch]);

  const handleCompareClick = () => {
    navigate(urls.getCompareReportsURL(reportsToCompare))
  };

  return (
    <Grid className={classes.root} columns={24}>
      <Col span={5}>
        <Card
          className={classes.filtersWrapper}
        >
          <Title
            order={4}
            className={classes.filtersTitle}
          >
            Reports Filters
          </Title>
          <TextInput
            icon={<Search width={16} />}
            label="Search"
            placeholder='Type keyword'
            mb="md"
          />
          <DateRangePicker
            label="Date Range"
            placeholder="Pick dates range"
            value={value}
            onChange={setValue}
            mb="lg"
          />
          <Select
            label='Report Status'
            data={[
              {
                label: 'All',
                value: '0'
              },
              {
                label: 'In progress',
                value: '1',
              },
              {
                label: 'Done',
                value: '2'
              }
            ]}
            defaultValue={'0'}
          />
        </Card>
      </Col>
      <Col span={19} >
        <Card className={classes.overflowWrapper}>
          <Table highlightOnHover id="inference-report-table">
            <thead>
              <tr>
                <th>Session ID</th>
                <th>Status</th>
                <th>Start Time</th>
                <th>Completion Time</th>
                <th>Images Count</th>
                <th>Type</th>
                <th>Dataset</th>
                <th>Source</th>
                <th></th>
                <th>
                  <Button
                    fullWidth
                    size="xs"
                    variant="light"
                    disabled={reportsToCompare.length < 2}
                    onClick={handleCompareClick}
                  >
                    Compare
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody >
              {sessions.map((session, index) => {
                return (
                  <tr key={session.id} className={cx(index === 0 && "first-inference-report")}>
                    <td>{session.id}</td>
                    <td>
                      {session.stopped !== null &&
                        <Badge
                          variant="dot"
                          color={'gray'}
                        >
                          Stopped
                        </Badge>
                      }
                      {session.stopped === null &&
                        <Badge
                          variant="dot"
                          color={session.finished === null ? 'yellow' : 'blue'}
                        >
                          {session.finished === null ? 'In Progress' : 'Done'}
                        </Badge>
                      }
                    </td>
                    <td>{formatDate(session.created)}</td>
                    <td>{formatDate(session.inference_end)}</td>
                    <td>{session.images_count}</td>
                    <td>{session.dataset_type}</td>
                    <td>{session.dataset_title}</td>
                    <td>{session.dataset_source}</td>
                    <td>
                      <Group>
                        <Button
                          disabled={session.finished === null || session.stopped !== null}
                          size="xs"
                          component={Link}
                          to={urls.getInferenceReportURL(session.id, session.dataset_type === 'CALIBRATION')}
                        >
                          View
                        </Button>
                      </Group>
                    </td>
                    <td>
                      <Center>
                        <Checkbox
                          value={session.id}
                          checked={reportsToCompare.indexOf(session.id) !== -1}
                          onChange={e => {
                            if (e.currentTarget.checked) {
                              setCompareReports([parseInt(e.currentTarget.value, 10), ...reportsToCompare]);
                            } else {
                              setCompareReports(reportsToCompare.filter(n => n !== parseInt(e.currentTarget.value, 10)))
                            }
                          }}
                        />
                      </Center>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Grid>
  );
}

