import { Button, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import * as analytics from '../app/features/analytics';
import { elabLogin } from '../app/features/auth/slice';
import { useAppDispatch } from '../app/hooks';

const useStyles = createStyles(theme => ({
  submitButton: {
    marginTop: theme.spacing.sm,
  }
}));

interface Props {
  onSubmitSuccess: () => void;
}

export default function FormElabLogin(props: Props) {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const form = useForm({
    initialValues: {},
  });

  const handleSubmit = () => {
    dispatch(elabLogin()).then((res: any) => {
      if (res.meta.rejectedWithValue) {
        analytics.logEvent(analytics.ELAB_LOGIN_FAIL);
      } else {
        analytics.logEvent(analytics.ELAB_LOGIN_SUCCESS);
        props.onSubmitSuccess();
      }
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <div>
        <p>
          Welcome to mpVision!
        </p>
        <p>
          We have created an account for you
          and stored required credentials to import
          images from your eLabJournal. Now you can
          start training your AI!
        </p>
      </div>
      <Button
        className={classes.submitButton}
        type="submit"
      >
        Continue
      </Button>
    </form>
  )
}
