import {Button, createStyles, SimpleGrid, Text, TextInput, Title} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useState} from 'react';
import {pricingRequest} from '../app/features/auth/slice';
import {useAppDispatch} from '../app/hooks';
import checkIconImageURL from '../assets/icons/check-large.svg';
import logoImageURL from '../assets/icons/logo-partial-large.svg';


const useStyles = createStyles(theme => ({
  root: {
  },
  infoWrapper: {

    borderRight: 'dashed 1px #D6DBE1'
  },
  formWrapper: {
  },
  logo: {
    marginLeft: -20,
    marginBottom: -20,
  }
}));


export default function SubscriptionModal() {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const [messageSent, setMessageSent] = useState(false);
  const subscriptionForm = useForm({
    initialValues: {
      name: '',
      email: '',
    },
    validate: {
      name: value => value.trim().length === 0,
      email: value => value.trim().length === 0,
    }
  });
  const handleSubmit = (values: {name: string; email: string}) => {
    dispatch(pricingRequest({...values, requestType: 'pricing'})).then(() => {
      setMessageSent(true);
    });
  };

  return (
    <div className={classes.root}>
      {!messageSent ?
        <SimpleGrid cols={2}>
          <div
            className={classes.infoWrapper}
          >
            <Title order={3} mb="lg">
              Activate Subscription
            </Title>
            <Text color="#3E4247" mb="lg">
              To keep using the mpVision after your trial ends, set up a subscription.
            </Text>
            <Text color="#3E4247">
              Please fill the information and our team will contact to you.
            </Text>
            <img
              className={classes.logo}
              src={logoImageURL}
              alt=""
            />
          </div>
          <div className={classes.formWrapper}>
            <form onSubmit={subscriptionForm.onSubmit(handleSubmit)}>
              <TextInput
                {...subscriptionForm.getInputProps('name')}
                placeholder="Enter your name"
                label="Name"
                mb="sm"
              />
              <TextInput
                {...subscriptionForm.getInputProps('email')}
                placeholder="Type your email"
                label="Email"
                mb="md"
              />
              <Button
                fullWidth
                color="green"
                type="submit"
              >
                Submit Information
              </Button>
            </form>
            <Text
              size="xs"
              mt="lg"
              color="#3E4247"
            >
              Copyright © 2022, Modicus Prime LLC
            </Text>
          </div>
        </SimpleGrid>
        :
        <div style={{textAlign: 'center'}}>
          <Title order={3} mb="lg">
            Activate Subscription
          </Title>
          <img
            src={checkIconImageURL}
            alt=""
          />
          <div style={{textAlign: 'center'}}>
            <Text
              size="sm"
              mt="lg"
              mb="lg"
              style={{display: 'inline-block', maxWidth: 400}}
            >
              Thank you for submitting information to Activate the Subscription for your account.
            </Text>
            <Text
              size="sm"
              mb="lg"
              style={{display: 'inline-block', maxWidth: 400}}
            >
              Our team will contact with you shortly, stay tuned!
            </Text>
          </div>
        </div>
      }
    </div>
  )
}
