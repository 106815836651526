import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';
import {startTraining} from '../training/slice';

export interface ImageLabel {
  id: number;
  label: number;
  top: number;
  left: number;
  width: number;
  height: number;
  positional_id: string;
  compact_contour: number[][][];
  datapoints: number;
  is_predicted: boolean;
  loss: number;
  is_deleted: boolean;

  ecd: number;
  perimeter: number;
  area: number;
  mean: number;
  std: number;
  aspectratio: number;
  circularity: number;
};

export interface CannyEdgeBox {
  ecd: number;
  area: number;
  perimeter: number;
  aspectratio: number;
  circularity: number;
  int_min: number;
  coords: [
    number, number, number, number,
  ];
};

type RangeValue = [number, number];

export interface CannyFilters {
  ecd: RangeValue;
  perimeter: RangeValue;
  area: RangeValue;
  mean: RangeValue;
  std: RangeValue;
  aspectratio: RangeValue;
  circularity: RangeValue;
  disable_search: boolean;
}

interface ImageLabelPayload {
  label: number | string;
  top: number;
  left: number;
  width: number;
  height: number;
}

const addImageLabel = createAsyncThunk(
  "imageLabels/add",
  async (data: {dataset: number, imageId: number, imageLabel: Partial<ImageLabelPayload>}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(
      `/api/datasets/${data.dataset}/images/${data.imageId}/labels/add`,
      data.imageLabel,
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const savePredictions = createAsyncThunk(
  "imageLabels/savePredictions",
  async (data: {dataset: number | string, imageId: number | string, imageLabels: Partial<ImageLabel & {label_id: number}>[]}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(
      `/api/datasets/${data.dataset}/images/${data.imageId}/save-predictions`,
      data.imageLabels,
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const updateImageLabel = createAsyncThunk(
  "imageLabels/update",
  async (data: {
    dataset: number,
    imageId: number,
    labelId: number,
    imageLabel: Partial<ImageLabel & {label_id: number}>
  }, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.patch(`/api/datasets/${data.dataset}/images/${data.imageId}/labels/${data.labelId}`,
      data.imageLabel, state.auth.token).then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

const deleteImageLabel = createAsyncThunk(
  "imageLabels/delete",
  async (data: {
    dataset: number,
    imageId: number,
    positional_id: string,
  }, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.remove(`/api/datasets/${data.dataset}/images/${data.imageId}/labels/${data.positional_id}`, state.auth.token).then(res => {
      if (res.status === 200) {
        return data.positional_id;
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

interface ParticlesFilter {
  key: string;
  ecd: RangeValue;
  perimeter: RangeValue;
  area: RangeValue;
  mean: RangeValue;
  std: RangeValue;
  aspectratio: RangeValue;
  circularity: RangeValue;
};

interface ParticlesFilterFormValues {
  canny: RangeValue;
  filters: ParticlesFilter[];
}

const transformCannyValues = (formValues: ParticlesFilterFormValues) => {
  let ret: any = {
    canny_min: formValues.canny[0],
    canny_max: formValues.canny[1],
    filters: [],
  };
  formValues.filters.forEach(filter => {
    const data: any = {};
    Object.keys(filter).forEach((key) => {
      if (key !== 'key') {
        // @ts-ignore
        data[key + '_min'] = filter[key as keyof CannyFilters]![0];
        // @ts-ignore
        data[key + '_max'] = filter[key as keyof CannyFilters]![1];
      }
    });
    ret.filters.push(data);
  });
  return ret;
}

export interface Filters {
  sensitivity: [number, number];
  ecd: [number, number][];
  perimeter: [number, number][];
  area: [number, number][];
  intencity_mean: [number, number][];
  intencity_std: [number, number][];
  aspectratio: [number, number][];
  circularity: [number, number][];
};

const loadImageLabels = createAsyncThunk(
  "imageLabels/load",
  async ({enable_ai, disable_search, ...data}: {
    dataset: number | string;
    imageId: number | string;
    disable_search?: boolean;
    enable_ai?: boolean;
    filters?: Filters;
  }, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return apiClient.post(
      `/api/datasets/${data.dataset}/images/${data.imageId}/labels`,
      {...data.filters, disable_search: disable_search, enable_ai},
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadPredictions = createAsyncThunk(
  "imageLabels/loadPredicions",
  async ({disable_search, enable_ai, ...data}: {
    dataset: number | string;
    imageId: number | string;
    disable_search?: boolean;
    enable_ai?: boolean;
    filters?: Filters;
  }, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(
      `/api/datasets/${data.dataset}/images/${data.imageId}/predictions`,
      {...data.filters, disable_search: disable_search, enable_ai},
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadMaskPredictions = createAsyncThunk(
  "imageLabels/loadPredicions",
  async (data: {image: string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(
      `/api/services/annotatepredict`,
      data,
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'imageLabels',
  initialState: {
    imageLabels: [] as ImageLabel[],
    dataset: {
      predictorProgress: 0,
      readyForTraining: false,
    }
  },
  reducers: {
    add: (state, action: PayloadAction<ImageLabel>) => {
      state.imageLabels.push(action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(addImageLabel.fulfilled, (state, action) => {
      const existingIndex = state.imageLabels.findIndex(il => il.positional_id === action.payload.label.positional_id);
      if (existingIndex !== -1) {
        state.imageLabels[existingIndex] = action.payload.label;
      } else {
        state.imageLabels.push(action.payload.label);
      }
      state.dataset.predictorProgress = action.payload.dataset.predictor_progress;
      state.dataset.readyForTraining = action.payload.dataset.is_training_ready;
    });
    builder.addCase(loadImageLabels.fulfilled, (state, action) => {
      state.imageLabels = action.payload.labels;
      state.dataset.predictorProgress = action.payload.dataset.predictor_progress;
      state.dataset.readyForTraining = action.payload.dataset.is_training_ready;
    });
    builder.addCase(loadPredictions.fulfilled, (state, action) => {
      state.imageLabels = action.payload.labels;
    });
    builder.addCase(loadImageLabels.pending, (state, action) => {
      state.imageLabels = [];
    });
    builder.addCase(updateImageLabel.fulfilled, (state, action) => {
      state.imageLabels = state.imageLabels.map(il => {
        if (il.id === action.payload.label.id) {
          return action.payload.label;
        }
        return il;
      });
      state.dataset.readyForTraining = action.payload.dataset.is_training_ready;
    });
    builder.addCase(savePredictions.fulfilled, (state, action) => {
      state.imageLabels = state.imageLabels.map(il => {
        return {
          ...il,
          is_predicted: false,
        };
      });
      state.dataset.readyForTraining = true;
    });
    builder.addCase(startTraining.fulfilled, (state, action) => {
      state.dataset.readyForTraining = false;
    });
    builder.addCase(deleteImageLabel.fulfilled, (state, action) => {
      state.imageLabels = state.imageLabels.filter(il => il.positional_id !== action.payload);
    });
  }
});

export const {add} = slice.actions;
export {
  addImageLabel,
  loadImageLabels,
  updateImageLabel,
  deleteImageLabel,
  loadPredictions,
  savePredictions,
  loadMaskPredictions
};

export default slice.reducer;
