import {Divider, Group, Image, Text} from '@mantine/core';
import imageURL from '../assets/images/ai-models.jpg';

export default function DocumentationAI() {
  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        AI refers to classes of algorithms that independently tune themselves to interpret real-world data. These algorithms, called models, can be applied to natural language, images, sensors, and more. The group of AI models generally implemented for image recognition is called a convolutional neural network, CNN.
      </Text>
      <Text size="sm">
        A CNN is composed of a series of parameters called weights that are tuned to reduce the error generated by a loss function. The loss function is primarily composed of localisation (based on coordinates) and identity (classification) errors. As the CNN makes predictions on the content of images, it changes its parameters to reduce the errors on its predictions, thus improving its performance over time as it learns to optimise its parameters. The type of AI model that mpVision implements for image processing is a CNN.
      </Text>
      <Divider my="sm" variant="dashed" />
      <Group
        position="apart"
        mb="sm"
        mt="md"
      >
        <Text size="sm">
          Image
        </Text>
        <Text size="sm">
          AI Model
        </Text>
        <Text size="sm">
          Object Detection
        </Text>
      </Group>
      <Image
        src={imageURL}
        alt=""
        mb="md"
      />
    </>
  )
}
