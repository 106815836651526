import {Center, Group} from '@mantine/core';
import {PieChart} from 'react-minimal-pie-chart';
import {THEME_COLORS} from '../utils/misc';

interface Props {
  data: {
    name: string;
    value: number;
  }[];
  classificationsSorted: string[];
  showPercentage?: boolean;
  width?: number | string;
}

export default function ParticlesPieChart({showPercentage = true, width = '100%', ...props}: Props) {
  return (
    <div>
      <Group mb="lg">
        {props.data.map((d, i) =>
          <div key={d.name}>
            <Group>
              <div style={{
                width: 10,
                height: 10,
                backgroundColor: THEME_COLORS[i]
              }} /> {d.name}
            </Group>
          </div>
        )}
      </Group>
      <Center >
        <div style={{width}}>
          <PieChart
            label={({x, y, dx, dy, dataEntry}) => (
              <text
                key={dataEntry.color}
                x={x}
                y={y}
                dx={dx}
                dy={dy}
                dominantBaseline="central"
                textAnchor="middle"
                style={{
                  fontSize: '12px',
                  fontFamily: 'sans-serif',
                  color: '#fff',
                  fill: '#fff '
                }}
              >
                {showPercentage ? `${Math.round(dataEntry.percentage)}%` : dataEntry.value.toFixed(2)}
              </text>
            )}
            labelStyle={{
              fontSize: 8,
              color: '#fff',
            }}
            data={props.data.map((d, i) => ({title: d.name, value: d.value, color: THEME_COLORS[i]}))}
          />
        </div>
      </Center>
    </div>
  );
}
