export const APP_VERSION = process.env.REACT_APP_VERSION || '0.0.0';

export const TKD_CUSTOMER = 'tkd';
export const PROD_CUSTOMER = 'prod';

export enum AppCustomer {
  tkd = 'tkd',
  staging = 'staging',
  prod = 'prod'
};

export const APP_CUSTOMER: AppCustomer = (process.env.REACT_APP_CUSTOMER as AppCustomer) || PROD_CUSTOMER;
export const APP_VERSION_STRING = APP_CUSTOMER !== AppCustomer.prod ? `${APP_CUSTOMER}${APP_VERSION}` : `v${APP_VERSION}`;

export const DISABLE_CANNY_FILTERS = APP_CUSTOMER !== AppCustomer.tkd;
export const SHOW_ONBOARDING_MODAL = APP_CUSTOMER !== AppCustomer.tkd;
