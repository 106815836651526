export const getDefaultHeaders = () => ({
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
})

function get(url: string, queryParams?: {[key: string]: string | number | boolean | number[]}, token?: string) {
  return fetch(url + (queryParams ? '?' + new URLSearchParams(queryParams as any).toString() : ''), {
    method: 'GET',
    cache: 'no-store',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
}

function post(url: string, data?: any, token?: string) {
  return fetch(url, {
    method: 'POST',
    cache: 'no-store',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  })
}

function patch(url: string, data?: any, token?: string) {
  return fetch(url, {
    method: 'PATCH',
    cache: 'no-store',
    headers: {
      ...getDefaultHeaders(),
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
}

function remove(url: string, token?: string) {
  return fetch(url, {
    method: 'DELETE',
    cache: 'no-cache',
    headers: {
      ...getDefaultHeaders(),
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
}

export {get, post, patch, remove};
