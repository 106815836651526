import {
  Anchor,
  Button,
  Group,
  NativeSelect
} from '@mantine/core';
import {useForm} from '@mantine/form';

interface Props {
  onSubmit(values: {model: string;}): void;
  onCancel(): void;

  initialValues: {model: string;};
  modelsList: {
    id: number;
    title: string;
  }[];
}

export default function CalibrationStartForm({initialValues, onSubmit, onCancel, modelsList}: Props) {
  const form = useForm({
    initialValues,
    validate: {
      model: (value) => value === '0',
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <NativeSelect
        required
        label="Model"
        placeholder="Select model to run inference"
        data={[{
          value: '0',
          label: 'Select Model For Calibration'
        }, ...modelsList.map(model => ({
          value: model.id + '',
          label: model.title,
        }))]}
        variant="default"
        {...form.getInputProps('model')}
      />

      <Group position="apart" style={{marginTop: 15}}>
        <Anchor component="button" color="gray" size="sm" onClick={onCancel}>
          Cancel
        </Anchor>
        <Button type="submit" size="sm" >
          Confirm
        </Button>
      </Group>
    </form>
  );
}

