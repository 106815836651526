import {ActionIcon, createStyles, Group, Text} from '@mantine/core';
import originalSizeIconURL from '../assets/icons/original-size.svg';
import zoomInIconURL from '../assets/icons/zoom-in.svg';
import zoomOutIconURL from '../assets/icons/zoom-out.svg';
import Tooltip from './Tooltip';

interface Props {
  onChange: (value: number) => void;
  value: number;
  disableTooltip?: boolean;
}

const useStyles = createStyles(theme => ({
  actionIcon: {
    height: 20,
    minHeight: 20,
    opacity: 1,
    '&:disabled': {
      background: 'transparent',
      border: 'none',
      opacity: .25,
    }
  }
}));

export default function ZoomControls(props: Props) {
  const {classes} = useStyles();
  const handleZoomIn = () => {
    props.onChange(props.value + 1);
  };
  const handleZoomOut = () => {
    const newValue = props.value - 1;
    if (newValue < 1) {
      handleReset();
    } else {
      props.onChange(newValue);
    }
  };
  const handleReset = () => {
    props.onChange(1)
  };

  return (
    <Tooltip
      disabled={true || props.disableTooltip}
      target={
        <div>
          <Group spacing={2}>
            <ActionIcon
              className={classes.actionIcon}
              color="gray"
              variant="transparent"
              onClick={handleZoomIn}
            >
              <img
                src={zoomInIconURL}
                alt=""
              />
            </ActionIcon>

            <ActionIcon
              className={classes.actionIcon}
              color="gray"
              variant="transparent"
              disabled={props.value <= 1}
              onClick={handleZoomOut}
            >
              <img
                src={zoomOutIconURL}
                alt=""
              />
            </ActionIcon>
            <ActionIcon
              className={classes.actionIcon}
              color="gray"
              variant="transparent"
              disabled={props.value === 1}
              onClick={handleReset}
            >
              <img src={originalSizeIconURL} alt="" />
            </ActionIcon>
          </Group>
        </div>
      }
      content={
        <>
          <Text size="sm">
            Zoom into your images to explore content.
          </Text>
        </>
      }
    />
  );
}
