import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface Image {
  id: number;
  dataset: number;
  file: string;
  thumbnail: string;
  is_grayscale: boolean;
};

type RangeValue = [number, number];

export interface CannyFilters {
  ecd: RangeValue;
  perimeter: RangeValue;
  area: RangeValue;
  mean: RangeValue;
  std: RangeValue;
  aspectratio: RangeValue;
  circularity: RangeValue;
}

export const uploadDatasetImages = (datasetId: number, images: File[], token: string) => {
  const formData = new FormData();
  images.forEach(fl => formData.append("files", fl));
  return fetch(`/api/datasets/${datasetId}/images`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: formData
  }).then(res => {
    return res.json();
  });
}

const uploadImages = createAsyncThunk(
  "images/upload",
  async (data: {datasetId: number, images: File[]}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return uploadDatasetImages(data.datasetId, data.images, state.auth.token);
  }
);

const loadDatasetImages = createAsyncThunk(
  "images/add",
  async ({datasetId, page = 1}: {
    datasetId: number | string;
    page?: number;
  }, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/datasets/${datasetId}/images`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const getNextImagesChunk = createAsyncThunk(
  "images/getNextChunk",
  async ({datasetId, page = 1}: {
    datasetId: number | string;
    page?: number;
  }, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/datasets/${datasetId}/images`, {page}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const transformCannyValues = (filters: Partial<CannyFilters>) => {
  const data: any = {};
  Object.keys(filters).forEach((key) => {
    data[key + '_min'] = filters[key as keyof CannyFilters]![0];
    data[key + '_max'] = filters[key as keyof CannyFilters]![1];
  });
  return data;
}

const fetchCannyEdges = createAsyncThunk(
  "images/fetchCannyEdges",
  async ({datasetId, imageId, filters}: {datasetId: number; imageId: number, filters?: Partial<CannyFilters>}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(
      `/api/datasets/${datasetId}/canny-image/${imageId}/`,
      filters ? transformCannyValues(filters) : {},
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const convertImageColor = createAsyncThunk(
  "images/convertColor",
  async ({datasetId, imageId, mode}: {datasetId: number; imageId: number; mode: string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/datasets/${datasetId}/images/${imageId}/convert/${mode}/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const deleteImage = createAsyncThunk(
  "images/delete",
  async ({imageId, datasetId}: {imageId: number, datasetId: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.remove(`/api/datasets/${datasetId}/images/${imageId}/`, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);


const slice = createSlice({
  name: 'images',
  initialState: {
    count: 0,
    items: [] as Image[],
  },
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadDatasetImages.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(getNextImagesChunk.fulfilled, (state, action) => {
      state.items = state.items.concat(action.payload.items);
    });
    builder.addCase(loadDatasetImages.pending, (state, action) => {
      return {
        count: 0,
        items: []
      };
    });

    builder.addCase(uploadImages.fulfilled, (state, action) => {
      state = {
        count: state.count + action.payload.length,
        items: state.items.concat(action.payload)
      }
      return state;
    });
    builder.addCase(convertImageColor.fulfilled, (state, action) => {
      state.items = state.items.map(image => image.id === action.payload.id ? action.payload : image)
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      state.items = state.items.filter(image => action.payload.indexOf(image.id) === -1);
    });
  }
});

export {loadDatasetImages, convertImageColor, deleteImage, fetchCannyEdges, uploadImages, getNextImagesChunk};

export default slice.reducer;
