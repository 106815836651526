import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface InferenceSession {
  id: number;
  dataset: number;
  created: string;
  finished: string;
  started: string;
  stopped: string;
  dataset_title: string;
  dataset_source: string;
  dataset_type: string;
  inference_end: string;
  images_count: number;
  model: {
    id: number;
    title: string;
  }
};


const startInference = createAsyncThunk(
  "inference/start",
  async (data: {datasetId: number; modelId: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/inference/session`, {
      model_id: data.modelId,
      dataset_id: data.datasetId,
    }, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          return res;
        });
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const stopInference = createAsyncThunk(
  "inference/stop",
  async (data: {sessionId: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/inference/session/${data.sessionId}/stop`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          return res;
        });
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const createTrainingDataset = createAsyncThunk(
  "inference/report/createDataset",
  async (data: {sessionId: number; datasetTitle: string; datasetId: string; imageId?: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(
      `/api/inference/session/${data.sessionId}/create-dataset/`,
      {
        dataset: {
          title: data.datasetTitle,
          image: data.imageId,
          id: data.datasetId === '0' ? undefined : data.datasetId,
        }
      },
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          return res;
        });
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadInferenceSessions = createAsyncThunk(
  "inference/report",
  async (_: undefined, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/sessions/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          return res;
        });
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'inferenceSessions',
  initialState: [] as InferenceSession[],
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadInferenceSessions.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(startInference.fulfilled, (state, action) => {
      state.unshift(action.payload);
      return state;
    });
    builder.addCase(stopInference.fulfilled, (state, action) => {
      const index = state.findIndex(sess => sess.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
    });
  }
});

export {startInference, loadInferenceSessions, stopInference, createTrainingDataset};

export default slice.reducer;
