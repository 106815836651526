import {Button, Text} from '@mantine/core';
import {useState} from 'react';
import * as analytics from '../app/features/analytics';
import {startTutorial, TutorialComponent} from '../app/features/quick-guide/slice';
import {startTraining} from '../app/features/training/slice';
import {useAppDispatch} from '../app/hooks';
import trainingIconURL from '../assets/icons/training.svg';
import Tooltip from './Tooltip';

interface Props {
  disableTooltip?: boolean;
  datasetId: number;
  disabled?: boolean;
}

export default function ButtonTrainingStart(props: Props) {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleClick = () => {
    if (!props.datasetId) {
      return;
    }
    analytics.logButtonClick({eventName: analytics.TRAINING_START});

    setLoading(true);
    dispatch(startTraining({
      datasetId: props.datasetId,
    })).then(() => {
      setLoading(false);
      dispatch(startTutorial("training-sessions-list"));
    });
  };

  return (
    <Tooltip
      isFinal
      disabled={props.disableTooltip}
      position="bottom-start"
      isTutoring={false}
      learnMoreLink={'training-session-q'}
      tutorialComponent={TutorialComponent.FINISH_LABELLING}
      target={
        <Button
          loading={isLoading}
          leftIcon={<img src={trainingIconURL} alt="" />}
          onClick={handleClick}
          disabled={props.disabled}
        >
          {props.disabled ? "Edit/Add Labels To Enable Training" : "Start Training Session"}
        </Button>
      }
      content={
        <>
          <Text size="sm">
            After you are finished labelling your entire training dataset with at least 20 images,
            select the ’Start Training Session’ button.
          </Text>
        </>
      }
    />
  )
}
