import {Button, Card, createStyles, Group, Title} from '@mantine/core';
import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {impersonate, loadUsers} from '../../app/features/admin/slice';
import {useAppDispatch, useAppSelector} from '../../app/hooks';

const useStyles = createStyles(theme => ({
  root: {

  },
  datasetWrapper: {
    border: 'solid 1px #ccc',
    padding: theme.spacing.sm,
    marginBottom: theme.spacing.md,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  dsFormWrapper: {
    marginTop: theme.spacing.md,
    width: 300,
  }
}));

export default function Users() {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(loadUsers());
  }, []);

  const users = useAppSelector(state => state.admin.users);


  return (
    <>
      <Card withBorder mb="md">

      </Card>
      <Card>
        {users.map(user =>
          <div
            key={user.id}
            className={classes.datasetWrapper}
          >
            <Group position='apart'>
              <div>
                <Title order={5}>{user.username}</Title>
              </div>
              <div>
                <Button.Group >
                  <Button
                    size="xs"
                    onClick={() => {
                      dispatch(impersonate({userId: user.id})).then(() => {
                        navigate('/')
                      })
                    }}
                  >
                    Impersonate
                  </Button>
                </Button.Group>
              </div>
            </Group>
          </div>
        )}
      </Card>
    </>
  );
}
