import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';
import {CannyFilters} from '../imageLabels/slice';


export interface HistogramData {
  values: number[];
  bucket_ranges: number[];
  label: string;
  data: {
    primary: string;
    secondary: number;
  }[];
}

export interface HistogramDataDict {
  combined: {
    ecd: HistogramData;
    max_feret: HistogramData;

  };
  by_classification: {
    [key: string]: ({
      classification: string;
      label: string;
      data: {
        primary: string;
        secondary: number;
      }[];
    } & HistogramData)[];
  }
}
export interface ClassificationStats {
  classification: string;
  count: number;
  avg_area: number;
  avg_ecd: number;
  avg_feret: number;
  avg_aspect: number;
  avg_circularity: number;
  stdev_ecd: number;
  stdev_feret: number;
  stdev_area: number;
  stdev_aspect_ratio: number;
  stdev_circularity: number;
}

export interface Intersection {
  classification: string;
  children: {
    classification: string;
    average: number;
    total: number;
  }[];
}

export interface InferenceObjectStats {
  id: number;
  x: number;
  y: number;
  classification: string;
  width: number;
  height: number;
  score: number;
  is_stuck: boolean;
  ecd: number;
  max_feret: number;
  area: number;
  aspect_ratio: number;
  perimeter: number;
  image: string;
  image_id: number;
};

export interface ParticleStats {
  histograms: HistogramDataDict;
  data_by_classifications: ClassificationStats[];
  intersections: Intersection[];
  date_started: string;
  model: {
    id: number;
    checkpoint: number;
    training_session_id: number;
    title: string;
  };
  dataset: {
    id: number;
    title: string;
    date: string;
  };
  images: {
    id: number;
    url: string;
  }[];
  all_stats: InferenceObjectStats[],
  anomalies: {
    items: InferenceObjectStats[];
    count: number;
  },
  count: number;
};

const loadInferenceSessionReport = createAsyncThunk(
  "inference/session/report",
  async (data: {sessionId: number | string, filters?: CannyFilters}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/sessions/${data.sessionId}/report/`, data.filters as any,
      state.auth.token).then(res => {
        if (res.status === 200) {
          return res.json().then(res => {
            return res;
          });
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

interface StatsParams {
  sessionId: number | string;
  page?: number;
  ecdRange: number[];
  includeAnomalies?: boolean;
}
const loadInferenceReportStats = createAsyncThunk(
  "inference/session/report/stats",
  async ({sessionId, page = 1, ecdRange, includeAnomalies = false}: StatsParams, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/sessions/${sessionId}/stats/`, {page, ecdMin: ecdRange[0], ecdMax: ecdRange[1], includeAnomalies},
      state.auth.token).then(res => {
        if (res.status === 200) {
          return res.json().then(res => {
            return res;
          });
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

const loadInferenceAnomaliesStats = createAsyncThunk(
  "inference/session/report/stats/anomalies",
  async ({sessionId, page = 1}: Partial<StatsParams>, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/sessions/${sessionId}/stats/anomalies/`, {page},
      state.auth.token).then(res => {
        if (res.status === 200) {
          return res.json().then(res => {
            return res;
          });
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

const loadInferenceImageStats = createAsyncThunk(
  "inference/session/report/stats/image",
  async ({sessionId, imageId}: {sessionId: number; imageId: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/sessions/${sessionId}/stats/image/${imageId}/`, undefined,
      state.auth.token).then(res => {
        if (res.status === 200) {
          return res.json().then(res => {
            return res;
          });
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

const loadInferencedImageObjects = createAsyncThunk(
  "inference/session/image/objects",
  async ({sessionId, imageId}: {sessionId: number | string, imageId: number | string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/inference/session/${sessionId}/image/${imageId}/objects/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          return res;
        });
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'inferenceStatistics',
  initialState: {
    images: [],
    histograms: {
      combined: {
        ecd: {
          values: [],
          bucket_ranges: [],
          label: 'ECD',
          data: []
        },
        max_feret: {
          values: [],
          bucket_ranges: [],
          label: 'ECD',
          data: []
        },
      },
      by_classification: {
        ecd: [],
        max_feret: []
      }
    },
    data_by_classifications: [],
    intersections: [],
    date_started: '',
    model: {
      id: 0,
      title: '',
      training_session_id: 0,
      checkpoint: 0,
    },
    dataset: {
      id: 0,
      title: '',
      date: ''
    },
    all_stats: [],
    anomalies: {
      items: [],
      count: 0
    },
    count: 0,
  } as ParticleStats,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadInferenceSessionReport.fulfilled, (state, action) => {
      return {
        ...action.payload,
        all_stats: state.all_stats,
        count: state.count,
        anomalies: state.anomalies,
      };
    });
    builder.addCase(loadInferenceReportStats.fulfilled, (state, action) => {
      state.all_stats = action.payload.items;
      state.count = action.payload.count;
    });
    builder.addCase(loadInferenceAnomaliesStats.fulfilled, (state, action) => {
      console.log(action.payload)
      state.anomalies = action.payload;
    });
  }
});

export {loadInferenceSessionReport, loadInferencedImageObjects, loadInferenceReportStats, loadInferenceAnomaliesStats, loadInferenceImageStats};

export default slice.reducer;
