import {
  Button,
  createStyles,
  Group, Select, Text,
  TextInput,
  Title
} from '@mantine/core';
import {useForm} from '@mantine/form';
import React from 'react';
import {Dataset, DATASET_TYPES, deleteDataset, saveDataset, updateDataset} from '../app/features/datasets/slice';
import {ImagingDevice} from '../app/features/imagingDevices';
import {useAppDispatch} from '../app/hooks';

const useStyles = createStyles((theme) => ({
  root: {

  },
  textInput: {
  },
  formLevelError: {
    marginBottom: theme.spacing.md
  },
  formTitle: {
    fontWeight: 500,
  }
}));

interface Props {
  initialValues: Partial<Dataset>;
  availableDevices: ImagingDevice[];
  onSubmitSuccess?: () => void;
  onDeleteSuccess?: (datasetId: number) => void;
}

export function DatasetForm({initialValues, availableDevices, onSubmitSuccess, onDeleteSuccess}: Props) {
  const [isSavingDataset, setSavingDataset] = React.useState(false);
  const dispatch = useAppDispatch();

  const {classes} = useStyles();

  const form = useForm({
    initialValues: {
      title: '',
      source: '',
      imaging_device: 0,
      ...initialValues,
    },
    validate: {
      title: value => !value ? "Title is required." : false,
    },
  });

  const handleFormSubmit = (data: Partial<Dataset>) => {
    setSavingDataset(true);
    if (data.id) {
      dispatch(updateDataset(data as Partial<Dataset> & {id: number})).then(onSubmitSuccess);
    } else {
      dispatch(saveDataset(data)).then(onSubmitSuccess);
    }
  };

  const handleDatasetDelete = () => {
    dispatch(deleteDataset(initialValues.id!)).then(() => {
      if (onDeleteSuccess) {
        onDeleteSuccess(initialValues.id!)
      }
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleFormSubmit)}>
      <Title
        align="center"
        order={5}
        className={classes.formTitle}
      >
        {initialValues.id ? 'Edit ' : 'New '} dataset
      </Title>
      <Text align="center" mb="sm">
        {initialValues.id ? 'Change ' : 'Input a unique '} dataset title and select the Submit button.
      </Text>
      <TextInput
        autoFocus
        className={classes.textInput}
        placeholder="Type dataset title..."
        mb="sm"
        {...form.getInputProps('title')}
      />
      <Select
        data={DATASET_TYPES.map(([value, label]) => ({
          label,
          value,
        }))}
        placeholder="Select type of your dataset"
        label="Dataset Type"
        mb="md"
        {...form.getInputProps('type')}
      />
      <Select
        data={availableDevices.map((device) => ({
          label: device.name,
          value: device.id + '',
        }))}
        placeholder="Select imaging device"
        label="Imaging Device"
        mb="md"
      />
      <Group position="apart">
        <Button
          type="submit"
          loading={isSavingDataset}
        >
          Submit
        </Button>
        {initialValues.id &&
          <Button color="red" type="button" onClick={handleDatasetDelete}>Delete dataset</Button>
        }
      </Group>
    </form>
  );
}

export default DatasetForm;
