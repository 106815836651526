import {
  Anchor,
  Button,
  createStyles, Group, Text,
  Title, Tooltip as MantineTooltip
} from '@mantine/core';
import {openContextModal} from '@mantine/modals';
import {Link} from 'react-router-dom';
import {AppCustomer, APP_CUSTOMER, APP_VERSION_STRING} from '../app/configs';
import * as analytics from '../app/features/analytics';
import {SubscriptionType} from '../app/features/auth/slice';
import {toggleQuickGuide, TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch, useAppSelector, useIsAuthenticated} from '../app/hooks';
import docsIconURL from '../assets/icons/docs.svg';
import {default as reportIconURL} from '../assets/icons/report.svg';
import {default as workspaceIconURL} from '../assets/icons/workspace.svg';
import logoURL from '../assets/images/logo.svg';
import * as urls from '../urls';
import AccountMenu from './AccountMenu';
import ButtonQuickGuide from './ButtonQuickGuide';
import OnboardingBadge from './OnboardingBadge';
import Tooltip from './Tooltip';
import TrialBadge from './TrialBadge';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: 14,
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'visible',
  },
  logoLink: {
    marginRight: 20,
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  logoImg: {
    marginRight: 8,
  },
  icon: {
    marginRight: 5,
    verticalAlign: 'middle',
    marginBottom: 1,
  },
  link: {
    color: '#000',
    fontSize: 14,
    '&:hover': {
      textDecoration: 'none',
    }
  },
  statusControls: {
    display: 'flex',
    alignItems: "center"
  }
}));

export function Header() {
  const {classes} = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const subscr = useAppSelector(state => state.auth.subscription_details);
  const handleLoginSuccess = () => {
    window.location.reload();
  };

  const dispatch = useAppDispatch();

  const handleQuickGuideClick = () => {
    dispatch(toggleQuickGuide());
  };
  const openLoginModal = () => {
    analytics.openLoginModal();
    openContextModal({
      modal: 'login',
      title: 'Authenticate',
      innerProps: {
        onSubmitSuccess: handleLoginSuccess
      }
    });
  };

  return (
    <header className={classes.root}>
      <div
        className={classes.card}
      >
        <Group>
          <MantineTooltip label={APP_VERSION_STRING} withArrow>
            <Anchor component={Link} className={classes.logoLink} to={'/'}>
              <Title order={4} style={{fontWeight: 700}}>
                <img
                  src={logoURL}
                  width={23}
                  className={classes.logoImg}
                  alt=""
                />
                mpVision
              </Title>
            </Anchor>
          </MantineTooltip>
          {isAuthenticated &&
            <Group spacing={27}>
              <Anchor
                component={Link}
                to={urls.getWorkspaceURL()}
                className={classes.link}
              >
                <img
                  src={workspaceIconURL}
                  alt=""
                  className={classes.icon}
                />
                Workspace
              </Anchor>
              <Tooltip
                tutorialOnly
                tutorialComponent={TutorialComponent.CLICK_REPORTS_SECTION}
                target={
                  <Anchor
                    id="inference-reports-link"
                    component={Link}
                    to={urls.INFERENCE_REPORTS_PAGE}
                    className={classes.link}
                  >
                    <img
                      src={reportIconURL}
                      alt=""
                      className={classes.icon}
                    />
                    Reports
                  </Anchor>
                }
                content={
                  <Text>
                    Review the status of your AI Inference and access the final Report by selecting ‘Reports’.
                  </Text>
                }
              />

              {APP_CUSTOMER !== AppCustomer.tkd && subscr.subscription === SubscriptionType.free &&
                <>
                  <TrialBadge daysLeft={subscr.trial_period_days_left} />
                  <OnboardingBadge />
                </>
              }

            </Group>
          }
        </Group>
        <div className={classes.statusControls}>
          {isAuthenticated &&
            <>
              <Anchor
                component={Link}
                to={urls.DOCUMENTATION_HOME}
                className={classes.link}
              >
                <img
                  alt=""
                  src={docsIconURL}
                  className={classes.icon}
                />
                Documentation
              </Anchor>
              <ButtonQuickGuide
                ml={24}
                mr={4}
                onClick={handleQuickGuideClick}
              />
            </>
          }
          {isAuthenticated ?
            <AccountMenu />
            :
            <Button
              size="xs"
              onClick={openLoginModal}
              variant="subtle"
            >
              Login
            </Button >
          }
        </div>
      </div>
    </header>
  )
}

export default Header;
