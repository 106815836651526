import {
  Button,
  Card,
  Center,
  createStyles, Group,
  LoadingOverlay,
  MultiSelect,
  Popover,
  SegmentedControl,
  Space
} from "@mantine/core";
import {useEffect, useState} from 'react';
import {Grid as IconGrid, Image as IconList} from 'react-feather';
import {useNavigate, useParams} from 'react-router-dom';
import {DISABLE_CANNY_FILTERS} from '../app/configs';
import {loadDatasetDetails} from '../app/features/datasets/details';
import {getNextImagesChunk, loadDatasetImages} from '../app/features/images/slice';
import {loadSessions} from '../app/features/training/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import * as urls from '../urls';
import AnnotationCanvas from './AnnotationCanvas';
import AnnotationsList from './AnnotationsList';
import ButtonGrayscale from './ButtonGrayscale';
import ButtonTrainingStart from './ButtonTrainingStart';
import EmptyDatasetPlaceholder from './EmptyDatasetPlaceholder';
import ImagesGallery from './ImagesGallery';
import TrainingSessionList from './TrainingSessionsList';
import ZoomControls from './ZoomControls';

const useStyles = createStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 100px)',
  },
  controls: {

  },
  ctaControls: {

  },
  placeholderWrapper: {
    height: 'calc(100vh - 127px)',
  },
  annotatorWrapper: {
    position: 'relative',
  },
  uploadPlaceholder: {
    maxWidth: 440,
  },
  drawBox: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  rect: {
    position: 'absolute',
    border: `solid 1px ${theme.colors.blue[5]}`,
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.colors.green[5]
    }
  },
  cannyFilters: {
    width: 240,
  },
  toolboxTabPanel: {
    border: 'solid 1px #ccc',
    borderTop: 'none',
    padding: theme.spacing.sm,
    height: 'calc(100vh - 275px)',
    overflow: 'auto',
  },
  imageObject: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    marginBottom: theme.spacing.sm,
    borderTop: `solid 1px #ccc`
  }
}));

export default function TrainingDataset() {
  const {classes} = useStyles();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {datasetId = '0', imageId = ''} = useParams<{datasetId: string; imageId: string}>();
  const [imagesPage, setImagesPage] = useState(1);
  const [viewMode, setViewMode] = useState<'image' | 'list'>('image');

  const imagesCount = useAppSelector(state => state.images.count);
  const {
    labels,
    trainingSessions,
    imageLabels: {
      dataset: {
        readyForTraining
      }
    }
  } = useAppSelector(state => state);
  const images = useAppSelector(state => state.images.items.filter(img => img.dataset === parseInt(datasetId, 10)));

  const activeImage = useAppSelector(state => {
    if (imageId) {
      const activeImageId = parseInt(imageId, 10);
      return state.images.items.find((img) => img.id === activeImageId) || (
        state.images.items.length ? state.images.items[0] : undefined
      );
    }
    return undefined;
  });

  const activeImageId = 0;

  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showTollbox, setShowToolbox] = useState(!DISABLE_CANNY_FILTERS);
  const [isFetchingNextChunk, setFetchingNextChunk] = useState(false);

  useEffect(() => {
    dispatch(loadSessions());
  }, []);

  const [visibleLabels, setVisibleLabels] = useState<number[]>([]);

  useEffect(() => {
    setLoading(true);
    const loadImages = dispatch(loadDatasetImages({datasetId}));
    const loadDetails = dispatch(loadDatasetDetails(datasetId));
    loadImages.then((imagesResponse) => {
      setLoading(false);
      if (imagesResponse.payload && imagesResponse.payload.items.length && !imageId) {
        navigate(urls.getWorkspaceURL('training', datasetId, imagesResponse.payload.items[0].id), {replace: true});
      }
    });
    return () => {
      loadDetails.abort();
      loadImages.abort();
    };
  }, [datasetId, dispatch]);

  useEffect(() => {
    let loadImagesChunk: any = undefined;
    if (imagesPage !== 1) {
      setFetchingNextChunk(true);
      const loadImagesChunk = dispatch(getNextImagesChunk({datasetId, page: imagesPage}));
      loadImagesChunk.then(() => {
        setFetchingNextChunk(false);
      })
    }
    return () => {
      if (loadImagesChunk) {
        loadImagesChunk.abort();
      }
    };
  }, [imagesPage]);

  useEffect(() => {
    if (!imageId && images.length) {
      navigate(urls.getWorkspaceURL('training', datasetId, images[0].id), {replace: true});
    }
  }, [images, imageId, navigate, loading]);

  const handleToolboxToggle = () => {
    setShowToolbox(!showTollbox);
  };

  const handleImageClick = (imageId: number) => {
    navigate(urls.getWorkspaceURL('training', datasetId, imageId));
  };

  const handleZoomChange = (value: number) => setZoom(value);

  const annotatorImageId = activeImageId || (images.length > 0 ? images[0].id : 0);

  if (loading) {
    return (
      <Card
        className={classes.root}
      >
        <LoadingOverlay visible={!!loading} overlayBlur={2} />
      </Card>
    );
  }

  if (!activeImage) {
    return (
      <Card
        className={classes.root}
      >
        <Center className={classes.placeholderWrapper}>
          <div style={{maxWidth: 440}} className={classes.uploadPlaceholder}>
            <EmptyDatasetPlaceholder
              datasetId={parseInt(datasetId, 10)}
            />
          </div>
        </Center>
      </Card>
    );
  }
  return (
    <Card
      className={classes.root}
    >
      <Group
        className={classes.controls}
        position="apart"
        mb="xs"
      >
        <Group
          className={classes.ctaControls}
        >
          <ButtonTrainingStart
            datasetId={parseInt(datasetId, 10)}
            disabled={!readyForTraining}
          />
          <TrainingSessionList
            sessions={trainingSessions.filter(ts => ts.dataset === parseInt(datasetId, 10))}
          />
          <Popover width={300} position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Button
                variant="subtle"
              >
                Filters
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <MultiSelect
                width={100}
                data={labels.map(label => ({
                  value: label.id + '',
                  label: label.title
                }))}
                onChange={value => {
                  setVisibleLabels(value.map(v => parseInt(v, 10)))
                }}
                mb="sm"
                label="Show labels (leave empty to show all)"
                placeholder="Select labels to show"
              />

            </Popover.Dropdown>
          </Popover>
        </Group>
        <Group>

          <SegmentedControl
            onChange={v => {
              setViewMode(v as any);
            }}
            value={viewMode}
            data={[
              {
                value: 'image',
                label: (
                  <Center>
                    <IconList size="1rem" />
                  </Center>
                ),
              },
              {
                value: 'list',
                label: (
                  <Center>
                    <IconGrid size="1rem" />
                  </Center>
                ),
              },
            ]}
          />
          <ZoomControls
            onChange={handleZoomChange}
            value={zoom}
          />
          <ButtonGrayscale
            datasetId={parseInt(datasetId, 10)}
            imageId={annotatorImageId}
            isGrayscale={activeImage!.is_grayscale}
          />
          <Button
            variant={showTollbox ? "outline" : "light"}
            color={showTollbox ? "blue" : "gray"}
            onClick={handleToolboxToggle}
            hidden={DISABLE_CANNY_FILTERS}
            size="xs"
          >
            Toolbox
          </Button>
        </Group>
      </Group>
      <div className={classes.annotatorWrapper}>
        {viewMode === 'image' &&
          <AnnotationCanvas
            showToolbox={showTollbox}
            disableSearch={DISABLE_CANNY_FILTERS}
            dataset={{
              id: parseInt(datasetId, 10)
            }}
            height={'calc(100vh - 265px)'}
            labels={labels}
            onZoomChange={handleZoomChange}
            zoom={zoom}
            image={activeImage}
            visibleLabels={visibleLabels}
          />
        }
        {viewMode === 'list' &&
          <AnnotationsList
            showToolbox={showTollbox}
            disableSearch={DISABLE_CANNY_FILTERS}
            dataset={{
              id: parseInt(datasetId, 10)
            }}
            height={'calc(100vh - 265px)'}
            labels={labels}
            onZoomChange={handleZoomChange}
            zoom={zoom}
            visibleLabels={visibleLabels}
            image={activeImage}
          />
        }
      </div>
      <Space h="xs" />
      <ImagesGallery
        datasetId={parseInt(datasetId, 10)}
        images={images}
        isLoading={isFetchingNextChunk}
        onScrollEnd={
          () => {
            if ((imagesCount - imagesPage * 100) > 0) {
              setImagesPage(v => v + 1);
            }
          }
        }
        activeImage={activeImage!.id}
        onImageClick={handleImageClick}
      />
    </Card>
  );
}
