import {closeModal, ContextModalProps} from '@mantine/modals';
import LoginForm from './LoginForm';

export const LOGIN_MODAL = 'login';
export const ONBOARDING_MODAL = 'onboarding-modal';
export const INFERENCE_REPORT_CONVERT = 'inference-report-convert';
export const SUBSCRIPTION_MODAL = 'subscription-modal';

export const closeLoginModal = () => closeModal(LOGIN_MODAL);

export const LoginModal = ({innerProps}: ContextModalProps<{onSubmitSuccess: () => void}>) => (
  <LoginForm onSubmitSuccess={innerProps.onSubmitSuccess} />
);
