import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface ImportJob {
  id: number;
  dataset_name: string;
  source: string;
  inference_model: number;
  user: number;
  status: string;
  created: string;
}

export interface CreateImportJobPayload {
  dataset_name: string;
  paths: string[];
  inference_model: number;
}

const createImportJob = createAsyncThunk(
  "dataImport/list-folders",
  async (data: CreateImportJobPayload, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post('/api/import/jobs', data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadImportFolders = createAsyncThunk(
  "dataImport/load-folders",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/import/list-folders`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadImageFolders = createAsyncThunk(
  "dataImport/load-image-folders",
  async (path: string, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/import/list-image-folders`, {folder: path}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadImportJobs = createAsyncThunk(
  "dataImport/load-import-jobs",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/import/jobs`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);


const slice = createSlice({
  name: 'mfi',
  initialState: [] as ImportJob[],
  reducers: {

  },
  extraReducers: builder => {

    builder.addCase(loadImportJobs.fulfilled, (state, action) => {
      return action.payload;
    });

  }
});

export {loadImportFolders, loadImportJobs, createImportJob, loadImageFolders};

export default slice.reducer;
