import {
  Button,
  createStyles,
  Divider,
  LoadingOverlay,
  Select,
  TextInput
} from '@mantine/core';
import {useToggle} from '@mantine/hooks';
import React from 'react';
import * as analytics from '../app/features/analytics';
import {importElabSection, loadElabSections} from '../app/features/elab/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';

interface Props {
  experimentId: number;
  sectionId?: number;
  onImportSuccess: (datasetId: number, type: string) => void;
}

const useStyles = createStyles((theme) => ({
  root: {
    minHeight: 200,
    width: '100%',
  },
  formSection: {
    marginBottom: theme.spacing.xs,
  },
  importForm: {

  },
  importButton: {
    marginTop: theme.spacing.xl * 3
  },
  formGroup: {
    marginBottom: theme.spacing.sm
  },
  sectionTitle: {
    marginBottom: theme.spacing.sm
  },
  sectionDivider: {
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
  }
}));

export default function ElabJournalExperiment(props: Props) {
  const {classes} = useStyles();

  const dispatch = useAppDispatch();
  const sections = useAppSelector(state => state.elabSections);
  const [isLoading, toggleLoading] = useToggle([true, false]);
  const [selectedDataset, setSelectedDataset] = React.useState(props.sectionId || 0);

  const [title, setTitle] = React.useState('');

  React.useEffect(() => {
    dispatch(loadElabSections(props.experimentId)).then((res) => {
      toggleLoading(false);
      if (selectedDataset) {
        setSelectedDataset(selectedDataset);
        const selectedSectionItem = res.payload.filter((section: {id: number, name: string}) => section.id === selectedDataset)[0];
        if (selectedSectionItem !== undefined) {
          setTitle(selectedSectionItem.name);
        }
      }
    });
  }, [props.experimentId, selectedDataset]);

  const handleImportClick = (type: string) => {
    toggleLoading(true);
    analytics.logButtonClick({
      eventName: analytics.ELAB_IMPORT, payload: {
        importType: type
      }
    });
    dispatch(importElabSection({sectionId: selectedDataset, title, type})).then((res) => {
      props.onImportSuccess(res.payload.dataset_id, type);
    });
  };

  return (
    <div className={classes.root} id="elab-import-form">
      <LoadingOverlay
        visible={isLoading}
      />
      {!isLoading &&
        <React.Fragment>
          <div className={classes.formSection}>
            <div className={classes.formGroup}>
              <Select
                label="Select eLabNext section to import"
                data={sections.map(section => ({
                  value: section.id + '',
                  label: section.name
                }))}
                value={selectedDataset + ''}
                onChange={value => {
                  if (value !== null) {
                    const sectionId = parseInt(value, 10);
                    setSelectedDataset(sectionId);
                    const selectedSectionItem = sections.filter(section => section.id === sectionId)[0];
                    if (selectedSectionItem !== undefined) {
                      setTitle(selectedSectionItem.name);
                    }
                  }
                }}
              />
            </div>
            {!!selectedDataset &&
              <div className={classes.importForm}>
                <TextInput
                  label="Enter name for new mpVision dataset (optional)"
                  placeholder="Enter dataset name... "
                  // variant="filled"
                  onChange={e => {
                    setTitle(e.currentTarget.value);
                  }}
                />
              </div>
            }
          </div>
          <Button
            fullWidth
            disabled={!selectedDataset}
            className={classes.importButton}
            onClick={() => {
              handleImportClick('training');
            }}
            id="elab-import-form-training-button"
          >
            Import for AI Training
          </Button>
          <Divider
            label="or"
            labelPosition='center'
            className={classes.sectionDivider}
          />
          <div
            className={classes.formSection}
          >
            <Button
              fullWidth
              disabled={!selectedDataset}
              onClick={() => {
                handleImportClick('inference');
              }}
            >
              Import for AI Inference
            </Button>
          </div>
        </React.Fragment>
      }

    </div>
  );
}
