import {Center, Divider, Image, Text} from '@mantine/core';
import imageURL from '../assets/images/layers.svg';

export default function DocumentationNetwork() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        The model that is used to detect and classify the objects within images is called a
        neural network. This model learns the identities and the locations of the objects in
        an image by training on labelled images that are submitted by the end user.
        The neural network learns by changing its parameters, called weights, in order
        to reduce its prediction error during training, called the loss. The neural network
        is then saved as a model to be used for future image classifications.
      </Text>
      <Divider my="sm" variant="dashed" />
      <Center>
        <Image
          src={imageURL}
          alt=""
          mb="md"
          mt="xl"
        />
      </Center>
    </>
  )
}
