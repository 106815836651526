import {Card, Center, Col, createStyles, Grid} from "@mantine/core";
import {openContextModal} from "@mantine/modals";
import React from 'react';
import {Helmet} from 'react-helmet';
import {Outlet, useParams} from 'react-router-dom';
import {SHOW_ONBOARDING_MODAL} from '../app/configs';
import {SubscriptionType, toggleOnboardingPopup} from '../app/features/auth/slice';
import {DatasetType, loadDatasets} from '../app/features/datasets/slice';
import {loadImagingDevices} from '../app/features/imagingDevices';
import {loadLabels} from '../app/features/labels/slice';
import {startTutorial} from '../app/features/quick-guide/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import DatasetsNav from './DatasetsNav';
import {ONBOARDING_MODAL} from "./Modals";

const useStyles = createStyles(theme => ({
  root: {
  },
  datasetsWrapper: {
  },
  datasetTabs: {
    marginBottom: theme.spacing.md,
  },
  datasetsTitle: {

  },
  divider: {
    marginTop: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
  },
  emptyDatasetImage: {
    display: 'block',
    width: '20%',
    margin: '0 auto',
  },
  emptyDatasetText: {
    flexGrow: 2,
  },
  emptyText: {
    marginBottom: theme.spacing.md
  },
  controls: {
    marginBottom: theme.spacing.md,
  },
  startButton: {
    marginRight: theme.spacing.sm
  },
  tabButton: {
    backgroundColor: '#fff',
    color: '#000',
    fontWeight: 400,
    '&:hover': {
      color: '#000',
      backgroundColor: '#fff',
    }
  },
  tabButtonActive: {
    backgroundColor: '#ECEDEE',
    '&:hover': {
      backgroundColor: '#ECEDEE',
    }
  },
  datasetModalHeader: {
    marginBottom: 0
  }
}));

export default function Workspace() {
  const {classes} = useStyles();
  const dispatch = useAppDispatch();
  const {datasetType = 'TRAINING', datasetId = '1'} = useParams<{datasetType: string; datasetId: string; imageId: string}>();
  const [selectedDataset, setSelectedDataset] = React.useState(0);

  const showOnboarding = useAppSelector(state => state.auth.subscription_details.show_onboarding);
  const isInsideTrialPeriod = useAppSelector(({auth: {subscription_details}}) => (
    subscription_details.trial_period_days_left > 0 && subscription_details.subscription === SubscriptionType.free
  ));

  React.useEffect(() => {
    if (isInsideTrialPeriod && SHOW_ONBOARDING_MODAL && showOnboarding) {
      openContextModal({
        modal: ONBOARDING_MODAL,
        title: '',
        size: 684,
        innerProps: {
          onSubmitSuccess: () => {
            dispatch(toggleOnboardingPopup());
          }
        },
        onClose: () => {
          dispatch(toggleOnboardingPopup());
        }
      });
    }
  }, [showOnboarding]);

  const datasets = useAppSelector(state => state.datasets);
  const imagingDevices = useAppSelector(state => state.imagingDevices);

  React.useEffect(() => {
    setSelectedDataset(parseInt(datasetId, 10));
  }, [datasetId]);

  React.useEffect(() => {
    dispatch(loadLabels());
    dispatch(loadDatasets());
    dispatch(loadImagingDevices());
    dispatch(startTutorial(
      datasetType === 'training' ?
        'training'
        :
        'inference-dataset'
    ));

  }, [dispatch, datasetType]);
  return (
    <Grid
      className={classes.root}
      columns={24}
    >
      <Helmet>
        <title>Workspace | mpVision</title>
      </Helmet>
      <Col
        span={5}
      >
        <DatasetsNav
          selectedSection={datasetType.toUpperCase() as DatasetType}
          availableDevices={imagingDevices}
          datasets={datasets}
          selectedDataset={selectedDataset}
        />
      </Col>
      <Col
        span={19}
      >
        <Outlet />
      </Col>
      {!selectedDataset &&
        <Col
          span={19}
        >
          <Card style={{
            minHeight: 'calc(100vh - 100px)'
          }}>
            <Center>


            </Center>
          </Card>
        </Col>
      }
    </Grid>
  );
}
