import {Card, createStyles, Grid, Group, Title} from '@mantine/core';
import {useDocumentTitle} from '@mantine/hooks';
import {useEffect, useState} from 'react';
import {loadDatasetImages} from '../app/features/images/slice';
import {loadLabels} from '../app/features/labels/slice';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import AnnotationCanvas from './AnnotationCanvas';
import ImagesGallery from './ImagesGallery';
import ZoomControls from './ZoomControls';

const IMAGE_URL = 'https://images.unsplash.com/photo-1524492412937-b28074a5d7da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1771&q=80'

const useStyles = createStyles(theme => ({
  cardHeader: {
    borderBottom: `solid 1px ${theme.colors.gray[5]}`,
    marginBottom: theme.spacing.lg
  },
  componentTitle: {
    padding: theme.spacing.md,
  }
}));

const DATASET_ID = 11;

export default function MPComponents() {
  useDocumentTitle("Foundational Components");

  const {classes} = useStyles();
  const dispatch = useAppDispatch();

  const [activeImageId, setActiveImageId] = useState(0);


  const images = useAppSelector(state => state.images);
  const labels = useAppSelector(state => state.labels);

  const [zoom, setZoom] = useState(1);
  const [drawCanvasSize, setDrawCanvasSize] = useState({width: 0, height: 0});
  const [scroll, setScroll] = useState<'viewport-center' | [number, number]>('viewport-center');
  const handleZoomChange = (value: number) => {
    setZoom(value);
  };

  const activeImage = images.items.find(img => img.id === activeImageId);

  useEffect(() => {
    dispatch(loadDatasetImages({datasetId: DATASET_ID}));
    dispatch(loadLabels());
  }, []);

  return (
    <div>
      <Grid>
        <Grid.Col span={3}>
          <Card>
            <Card.Section className={classes.cardHeader}>
              <Title order={4} className={classes.componentTitle}>Particles Filter Form</Title>
            </Card.Section>

          </Card>
        </Grid.Col>
        <Grid.Col span={9}>
          <Card>
            <Card.Section className={classes.cardHeader}>
              <Title order={4} className={classes.componentTitle}>Image Zoom with Draw Area</Title>
            </Card.Section>
            <Group mb="md">
              <ZoomControls
                value={zoom}
                onChange={handleZoomChange}
              />
            </Group>
            <div
              style={{
                position: 'relative'
              }}
            >
              {activeImage &&
                <AnnotationCanvas
                  height={400}
                  labels={labels}
                  onZoomChange={setZoom}
                  zoom={zoom}
                  image={activeImage}
                  dataset={{
                    id: DATASET_ID
                  }}
                />
              }
            </div>
            <ImagesGallery
              datasetId={5}
              images={images.items}
              onImageClick={setActiveImageId}
              activeImage={activeImageId}
            />
          </Card>
        </Grid.Col>

      </Grid>
    </div>
  )
}
