import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface ImagingDevice {
  id: number;
  name: string;
  pixel_conversion_factor: number;
};

const loadImagingDevices = createAsyncThunk(
  "imagingDevices/load",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/datasets/imaging-devices/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'imagingDevices',
  initialState: [] as ImagingDevice[],
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadImagingDevices.fulfilled, (state, action) => {
      return action.payload
    });
  }
});

export {loadImagingDevices};

export default slice.reducer;
