import {
  Button, Popover
} from '@mantine/core';
import {useState} from 'react';
import {startInference} from '../app/features/inference/slice';
import {goToNextStep, TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch} from '../app/hooks';
import CalibrationStartForm from './CalibrationStartForm';

interface Props {
  modelsList: {
    id: number;
    title: string;
  }[];
  disabled?: boolean;
  disableTooltip?: boolean;
  datasetId: number;
}

export default function CalibrationStartControls(props: Props) {
  const [opened, setOpened] = useState(false);
  const dispatch = useAppDispatch();
  const handleStart = (modelId: number) => {
    dispatch(startInference({
      modelId,
      datasetId: props.datasetId,
    }));
  };
  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="bottom"
      width={240}
      withArrow
      transition="pop-top-right"
    >
      <Popover.Target>
        <Button
          onClick={() => {
            setOpened((o) => !o);
            dispatch(goToNextStep(TutorialComponent.CLICK_START_INFERENCE_BUTTON));
          }}
        >
          Start Calibration
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <CalibrationStartForm
          initialValues={{
            model: '0'
          }}
          modelsList={props.modelsList}
          onCancel={() => setOpened(false)}
          onSubmit={(data) => {
            handleStart(parseInt(data.model));
            setOpened(false);
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
}
