import {
  Button,
  createStyles,
  Group,
  Radio, Text,
  TextInput
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {TrashIcon} from '@modulz/radix-icons';
import React from 'react';
import {Link} from 'react-router-dom';
import {addImageLabel, deleteImageLabel} from '../app/features/imageLabels/slice';
import {addLabel} from '../app/features/labels/slice';
import {TutorialComponent} from '../app/features/quick-guide/slice';
import {useAppDispatch} from '../app/hooks';
import * as urls from '../urls';
import Tooltip from './Tooltip';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: theme.spacing.md
  },
  labelsList: {
    paddingTop: theme.spacing.sm,
    maxHeight: 200,
    overflowY: 'auto',
    border: `solid 1px ${theme.colors.gray[2]}`,
    padding: theme.spacing.sm,
  },
  labelsListItem: {
    borderBottom: 'none'
  },
  title: {
    borderBottom: 'solid 1px #ccc',
    marginBottom: theme.spacing.sm
  },
  addLabelButton: {
    marginTop: theme.spacing.sm
  },
  radioLabel: {
    userSelect: 'none',
  },
  viewContours: {
    marginTop: theme.spacing.sm,
  },
  activeContour: {
    marginTop: theme.spacing.md
  },
  editLabelsButton: {
    marginTop: theme.spacing.sm
  }
}));

interface ImageLabelData {
  label: string;
  top: number;
  left: number;
  width: number;
  height: number;
  positional_id: string;
};

interface Props {
  labels: {
    id: number;
    title: string;
  }[];
  onSubmitSuccess?: (label: string) => void;
  onCancelClick?: () => void;

  initialValues?: {
    label: string | number;
    top: number;
    left: number;
    width: number;
    height: number;
    positional_id: string;
  };

  datasetId: number;
  imageId: number;
}

export default function LabelForm({initialValues = {
  label: '',
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  positional_id: '',
}, ...props}: Props) {
  const {classes} = useStyles();

  const form = useForm<ImageLabelData>({
    initialValues: {
      ...initialValues,
      label: initialValues.label + '',
    }
  });

  const dispatch = useAppDispatch();
  const [isSavingLabel, setSavingLabel] = React.useState(false);
  const [labelTitle, setLabelTitle] = React.useState("");

  const handleLabelTitleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setLabelTitle(e.currentTarget.value);
  };

  const handleLabelDelete = () => {
    dispatch(deleteImageLabel({
      dataset: props.datasetId,
      imageId: props.imageId,
      positional_id: form.values.positional_id,
    }));
  };

  const handleLabelCreate = () => {
    return dispatch(addLabel({title: labelTitle})).then(res => {
      form.setFieldValue('label', res.payload);
      setLabelTitle('');
    });
  };
  const handleFormSubmit = () => {
    setSavingLabel(true);
    dispatch(addImageLabel({
      dataset: props.datasetId,
      imageId: props.imageId,
      imageLabel: form.values,
    })).then(() => {
      setSavingLabel(false);
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess(form.values.label);
      }
    });
  };

  const filteredLabels = (
    !!labelTitle ?
      props.labels.filter(({title}) => title.includes(labelTitle))
      :
      props.labels
  );

  const exactMatches = props.labels.filter(({title}) => title === labelTitle);

  return (
    <Tooltip
      tutorialOnly
      disableNav
      tutorialComponent={TutorialComponent.SELECT_LABEL_TITLE}
      position="right-start"
      stepInfo='Step 5/7'
      offset={23}
      target={<div
        onMouseDown={e => {
          e.stopPropagation();
        }}
        onMouseUp={e => {
          e.stopPropagation();
        }}
      >
        <TextInput
          value={labelTitle}
          onChange={handleLabelTitleChange}
          radius={0}
          placeholder="Enter label title"
        />

        <div className={classes.labelsList}>
          <Radio.Group
            orientation="vertical"
            {...form.getInputProps('label')}
          >
            {filteredLabels.map(({title, id}) =>
              <Radio key={id} value={id + ''} className={classes.radioLabel} label={title} />
            )}
          </Radio.Group>
        </div>
        <Button
          fullWidth
          size="xs"
          variant="outline"
          className={classes.editLabelsButton}
          component={Link}
          to={urls.EDIT_LABELS}
        >
          Edit Labels
        </Button>
        {labelTitle && !exactMatches.length &&
          <Button
            radius={0}
            fullWidth
            variant="light"
            className={classes.addLabelButton}
            onClick={handleLabelCreate}
          >
            Create label "{labelTitle}"
          </Button>
        }
        <Group grow>
          <Button
            disabled={!form.values.label}
            radius={0}
            className={classes.addLabelButton}
            onClick={handleFormSubmit}
            loading={isSavingLabel}
          >
            Save
          </Button>
          {!!props.onCancelClick &&
            <Button
              radius={0}
              color="gray"
              className={classes.addLabelButton}
              onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                props.onCancelClick!();
              }}
            >
              Cancel
            </Button>
          }
        </Group>
        <Group align={"flex-end"}>
          <Button
            color="red"
            size="xs"
            leftIcon={<TrashIcon />}
            className={classes.addLabelButton}
            variant="light"
            onClick={handleLabelDelete}
          >
            Delete Label
          </Button>
        </Group>
      </div>}
      content={
        <>
          <Text>
            Type the identity of the specific particle, select, and save as a Label.
          </Text>
        </>
      }
    />

  );
}
