import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

interface ElabJournalSection {
  id: number;
  name: string;
};

const loadElabSections = createAsyncThunk(
  "elabsections/load",
  async (experimentId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(
      '/api/elabjournal/sections',
      {experiment_id: experimentId},
      state.auth.token
    ).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const checkElabAccess = createAsyncThunk(
  "elabsections/checkAccess",
  async (_: undefined, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get('/api/elabjournal/check-access', {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const importElabSection = createAsyncThunk(
  "elabsections/import",
  async ({sectionId, title, type}: {sectionId: number, title: string, type: string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post('/api/elabjournal/import', {
      section_id: sectionId,
      title,
      type,
    }, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'elab',
  initialState: [] as ElabJournalSection[],
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadElabSections.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

export {loadElabSections, importElabSection, checkElabAccess};

export default slice.reducer;
