import {Text} from '@mantine/core';

export default function DocumentationIndex() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        Welcome to mpVision application documentation!
      </Text>
      <Text size="sm">
        Use navigation sidebar on left or buttons on the bottom of this
        page to learn about our machine learning technology.
      </Text>
    </>
  )
}
