import {Button, Card, FileButton, Select, Table} from '@mantine/core';
import {useForm} from '@mantine/form';
import {saveAs} from 'file-saver';
import {useEffect, useState} from 'react';
import {downloadInferenceSession, InferenceSession as InferenceSessionType, loadInferenceSessions, uploadInferenceSession} from '../../app/features/admin/slice';
import {useAppDispatch, useAppSelector} from '../../app/hooks';

export default function InferenceSession() {
  const dispatch = useAppDispatch();
  const sessions = useAppSelector(state => state.admin.inferenceSessions);
  const [sessionFile, setSessionFile] = useState<File | null>(null);
  const [downloadingSessionId, setDownloadingsessionId] = useState(0);
  const [isUploadingSession, setUploadingSession] = useState(false);

  const users = useAppSelector(state => state.admin.users);
  const handleSessionDownload = (session: InferenceSessionType) => {
    setDownloadingsessionId(session.id);
    dispatch(downloadInferenceSession(session.id)).then(res => {
      const title = session.dataset ? session.dataset.title : 'n_a_ds';
      saveAs(res.payload, `inference_session_${session.id}.${title.replace(' ', '-')}.tar`);
      setDownloadingsessionId(0);
    });
  };

  const rows = sessions.map((session) => (
    <tr key={session.id}>
      <td>{session.id}</td>
      <td>{session.dataset?.title || 'N/A'}</td>
      <td>{session.user.id} / {session.user.username}</td>
      <td>{session.status.total_images}</td>
      <td>{session.status.stats_collected}</td>
      <td>
        <Button
          size="xs"
          variant="outline"
          onClick={() => handleSessionDownload(session)}
          loading={session.id === downloadingSessionId}
        >
          Download
        </Button>
      </td>
    </tr>
  ));

  useEffect(() => {
    dispatch(loadInferenceSessions(0));
  }, []);

  const sessionForm = useForm({
    initialValues: {
      userId: "",
    }
  });

  const handleSessionUpload = (values: {userId: string}) => {
    setUploadingSession(true);
    dispatch(uploadInferenceSession({
      userId: parseInt(values.userId, 10),
      sessionFile: sessionFile!,
    })).then(() => {
      setUploadingSession(false);
    });
  };

  return (
    <Card withBorder>
      <div>
        <FileButton onChange={setSessionFile} accept="application/x-tar">
          {(props) => <Button {...props}>Upload Session File</Button>}
        </FileButton>
        {sessionFile !== null &&
          <form onSubmit={sessionForm.onSubmit(handleSessionUpload)}>
            <Select
              label="User"
              data={users.map(user => ({
                value: user.id + '',
                label: user.username,
              }))}
              mb="sm"
              {...sessionForm.getInputProps('userId')}
            />
            <Button
              type="submit"
              loading={isUploadingSession}
            >
              Save
            </Button>
          </form>
        }
      </div>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Dataset</th>
            <th>User</th>
            <th>Total Images</th>
            <th>Inferenced Images</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Card>
  )
}
