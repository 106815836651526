import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

interface ValidationImage {
  step: number;
  sample: number;
  timestamp: number;
  url: string;
}

export interface TrainingSession {
  id: number;
  dataset: number;
  date_started: string;
  status: string;
  is_done: boolean;
  is_stopped: boolean;
  is_running: boolean;
}


export interface TrainingSessionDetails {
  checkpoints: {
    filename: string;
    timestamp: number;
    step: number;
    loss: {
      loss: 0.4861290156841278,
      step: 2131
    }[];
    images: {
      filename: string;
      path: string;
      step: number;
      sample: number;
      url: string;
      timestamp: number;
    }[];
  }[];

  validation: {
    step: number;
    loss: number;
  }[];

  loss: {
    step: number;
    loss: number;
  }[];

}

export interface Checkpoint {
  filename: string;
  loss: number;
  step: number;
  timestamp: number;
  validation: number;
  images: ValidationImage[];
}


const loadSessions = createAsyncThunk(
  "trainingSession/list",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/training/sessions/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const stopSession = createAsyncThunk(
  "trainingSession/stop",
  async (sessionId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/training/sessions/${sessionId}/stop`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadSessionInfo = createAsyncThunk(
  "trainingSession/details",
  async (sessionId: string, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/training/sessions/${sessionId}`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

export interface TilingRequestData {
  imageId: number;
  canny_upper: number;
  canny_lower: number;
  tile_width: number;
  tile_height: number;
  scale_factor: number;
  overlap: number;
}

export interface TrainingStartData {
  datasetId: number;
}

const startTraining = createAsyncThunk(
  "trainingSession/start",
  async ({datasetId}: TrainingStartData, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/training/start/${datasetId}/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const getTiles = createAsyncThunk(
  "trainingSession/getTiles",
  async ({imageId, ...data}: TilingRequestData, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/training/tiles/${imageId}/`, data, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'trainingSessions',
  initialState: [] as TrainingSession[],
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(startTraining.fulfilled, (state, action) => {
      state.unshift(action.payload);
    });
    builder.addCase(loadSessions.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(stopSession.fulfilled, (state, action) => {
      const index = state.findIndex(sess => sess.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
    });
  }
});

export {loadSessionInfo, getTiles, startTraining, loadSessions, stopSession};

export default slice.reducer;
