import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export const DATASET_TYPES = [
  ['TRAINING', 'Training'],
  ['INFERENCE', 'Inference'],
  ['CALIBRATION', 'Calibration',]
];

export enum DatasetType {
  training = 'TRAINING',
  inference = 'INFERENCE',
  calibration = 'CALIBRATION',
}

export interface Dataset {
  id: number;
  user: number;
  title: string;
  source: string;
  dataset_title: string;
  timestamp: string;
  type: DatasetType;
};

export interface User {
  id: number;
  username: string;
  email: string;
}

export interface InferenceSession {
  id: number;
  user: User;
  dataset?: Dataset;
  status: {
    total_images: number;
    stats_collected: number;
  };
}

export interface AdminState {
  datasets: Dataset[];
  users: User[];
  inferenceSessions: InferenceSession[];
}

const loadDatasets = createAsyncThunk(
  "admin/load/datasets",
  async (offset: number = 0, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/mpv-console/datasets/?offset=${offset}`, undefined, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadInferenceSessions = createAsyncThunk(
  "admin/load/inference-sessions",
  async (offset: number = 0, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/mpv-console/inference-sessions/?offset=${offset}`, undefined, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const loadUsers = createAsyncThunk(
  "admin/load/users",
  async (_, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/mpv-console/users/`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

export const downloadDataset = createAsyncThunk(
  "admin/datasets/download",
  async (datasetId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/mpv-console/datasets/download/${datasetId}`, {}, state.auth.token)
      .then((res: any) => {
        if (res.ok) {
          return res.blob();
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

export const downloadInferenceSession = createAsyncThunk(
  "admin/inference-sessions/download",
  async (sessionId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/mpv-console/inference-sessions/${sessionId}/download/`, {}, state.auth.token)
      .then((res: any) => {
        if (res.ok) {
          return res.blob();
        } else if (res.status === 403) {
          return rejectWithValue("You are not allowed to perform this operation.")
        } else {
          return rejectWithValue("Something wrong happened. Please, try again later.")
        }
      });
  }
);

export const impersonate = createAsyncThunk(
  "admin/users/impersonate",
  async (data: {userId: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return fetch(`/api/mpv-console/users/impersonate/`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify({user_id: data.userId})
    }).then(res => {
      return res.json();
    });
  }
);

export const compareObjects = createAsyncThunk(
  "admin/objects/compare",
  async (data: {bboxA: number; bboxB: number}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    return fetch(`/api/inference/compare-objects/`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify(data)
    }).then(res => {
      return res.json();
    });
  }
);

export const uploadDataset = createAsyncThunk(
  "admin/datasets/download",
  async (data: {userId: number; dsFile: File}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    const formData = new FormData();
    formData.append('file', data.dsFile);
    formData.append('details', JSON.stringify({userId: data.userId}));
    return fetch(`/api/mpv-console/datasets/upload/`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: formData
    }).then(res => {
      return res.json();
    });
  }
);

export const uploadInferenceSession = createAsyncThunk(
  "admin/inference-sessions/upload",
  async (data: {userId: number; sessionFile: File}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;
    const formData = new FormData();
    formData.append('file', data.sessionFile);
    formData.append('details', JSON.stringify({userId: data.userId}));
    return fetch(`/api/mpv-console/inference-sessions/upload/`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${state.auth.token}`,
      },
      body: formData
    }).then(res => {
      return res.json();
    });
  }
);

const slice = createSlice({
  name: 'admin',
  initialState: {
    users: [],
    datasets: [],
    inferenceSessions: [],
  } as AdminState,
  reducers: {

  },
  extraReducers: builder => {

    builder.addCase(loadDatasets.fulfilled, (state, action) => {
      state.datasets = state.datasets.concat(action.payload.items);
    });

    builder.addCase(loadUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    builder.addCase(loadInferenceSessions.fulfilled, (state, action) => {
      state.inferenceSessions = action.payload.items;
    });

  }
});

export {loadDatasets, loadUsers, loadInferenceSessions};

export default slice.reducer;
