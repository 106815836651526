import {
  Accordion,
  ActionIcon,
  Anchor,
  Card,
  createStyles,
  Indicator,
  Modal,
  Tabs,
  Text,
  Title
} from '@mantine/core';
import {useState} from 'react';
import {useNavigate} from 'react-router';
import {Dataset, DatasetType} from '../app/features/datasets/slice';
import {ImagingDevice} from '../app/features/imagingDevices';
import editIconURL from '../assets/icons/edit.svg';
import folderIconURL from '../assets/icons/folder.svg';
import * as urls from '../urls';
import {formatDate} from '../utils/formatting';
import DatasetForm from './DatasetForm';
import DatasetImportForm from './DatasetImportForm';
import Tooltip from './Tooltip';

const useStyles = createStyles(theme => ({
  root: {

  },
  tabActive: {
    backgroundColor: '#428BDF',
    color: '#fff',
    "&:hover": {
      backgroundColor: '#428BDF',
      color: '#fff',
    }
  },
  title: {
    fontWeight: 500,
    padding: theme.spacing.md,
  },
  overflowWrapper: {
    overflow: 'auto',
    height: 'calc(100vh - 360px)',
  },
  addButtonWrapper: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    borderTop: `dashed 1px ${theme.colors.gray[4]}`,
    borderBottom: `dashed 1px ${theme.colors.gray[4]}`,
  },
  addButton: {
    color: theme.colors.green[6],
    fontSize: 14,
    '&:hover': {
      textDecoration: 'none',
    }
  },
  addButtonIcon: {
    marginBottom: 6,
    marginRight: 6,
  },
  editButton: {
    position: 'absolute',
    right: 10,
    top: 15,
  },
  listItem: {
    cursor: "pointer",
    position: 'relative',
    padding: theme.spacing.xs,
    "&:last-child": {
      borderBottom: 'none',
    },
    "&:first-of-type": {
    },
    "&:hover": {
      backgroundColor: '#ECEDEE',
    },
  },
  activeListItem: {
    backgroundColor: '#ECEDEE',
  },
  datasetModalHeader: {
    marginBottom: 0
  }
}));

interface Props {
  datasets: Dataset[];
  availableDevices: ImagingDevice[];
  selectedDataset: number;
  selectedSection?: DatasetType;
}

type ListProps = Omit<Props, 'onAddClick' | 'availableDevices'> & {onEditClick: (dsId: number) => void};

const DatasetsList = ({datasets, selectedDataset, onEditClick}: ListProps) => {
  const {classes, cx} = useStyles();
  const navigate = useNavigate();
  const handleDatasetClick = (datasetId: number, dsType: DatasetType) => {
    if (datasetId !== selectedDataset) {
      navigate(urls.getDatasetsURL(dsType, datasetId));
    }
  };
  return (
    <>
      {datasets.map(ds =>
        <Indicator
          key={ds.id}
          label="Importing..."
          color="yellow"
          disabled={!ds.import_in_progress}
          size={24}
          offset={30}
        >
          <div

            className={cx(classes.listItem, selectedDataset === ds.id && classes.activeListItem)}
            onClick={() => handleDatasetClick(ds.id, ds.type)}
          >
            <Title order={5}>{ds.title}</Title>
            <Text size="xs" color="gray">{formatDate(ds.timestamp)}</Text>
            <ActionIcon
              className={classes.editButton}
              onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onEditClick(ds.id);
              }}
            >
              <img src={editIconURL} alt="" />
            </ActionIcon>
          </div>
        </Indicator>
      )}
    </>
  )
}

export default function DatasetsNav({datasets, availableDevices, ...props}: Props) {
  const {classes, cx} = useStyles();
  const [edittingDatasetId, setEdittingDatasetId] = useState(-1);
  const navigate = useNavigate();

  const [datasetType, setDatasetType] = useState<DatasetType>(props.selectedSection || DatasetType.training);
  const handleDatasetTypeChange = (value: DatasetType) => {
    if (!value) {
      return;
    }
    setDatasetType(value);
  };

  const handleModalClose = () => setEdittingDatasetId(-1);
  const handleEditClick = (datasetId: number) => setEdittingDatasetId(datasetId);
  const handleAddClick = () => setEdittingDatasetId(0);

  const handleDeleteSuccess = (datasetId: number) => {

    if (datasetId === props.selectedDataset) {
      navigate('/');
      handleModalClose();
    } else {
      handleModalClose();
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <Card.Section>
          <Title
            order={5}
            className={classes.title}
          >
            Your Datasets
          </Title>
          <div className={classes.addButtonWrapper}>
            <Tooltip
              target={
                <Anchor
                  className={classes.addButton}
                  onClick={handleAddClick}
                >
                  <img
                    src={folderIconURL}
                    alt=""
                    className={classes.addButtonIcon}
                  />
                  Create/Import Dataset Folder
                </Anchor>
              }
              content={
                <>
                  <Text size="sm">
                    To train your AI model, first create a dataset.
                  </Text>
                </>
              }
            />
          </div>
          <Accordion
            value={datasetType}
            transitionDuration={0}
            onChange={handleDatasetTypeChange}
          >
            <Accordion.Item value={DatasetType.training}>
              <Accordion.Control
                className={cx(datasetType === DatasetType.training && classes.tabActive)}
              >
                Training
              </Accordion.Control>
              <Accordion.Panel
                className={classes.overflowWrapper}
              >
                <DatasetsList
                  onEditClick={handleEditClick}
                  selectedDataset={props.selectedDataset}
                  datasets={datasets.filter(ds => ds.type === 'TRAINING')}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value={DatasetType.inference}>
              <Accordion.Control
                className={cx(datasetType === DatasetType.inference && classes.tabActive)}
              >
                Inference
              </Accordion.Control>
              <Accordion.Panel
                className={classes.overflowWrapper}
              >
                <DatasetsList
                  onEditClick={handleEditClick}
                  selectedDataset={props.selectedDataset}
                  datasets={datasets.filter(ds => ds.type === 'INFERENCE')}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value={DatasetType.calibration}>
              <Accordion.Control
                className={cx(datasetType === DatasetType.calibration && classes.tabActive)}
              >
                Calibration
              </Accordion.Control>
              <Accordion.Panel
                className={classes.overflowWrapper}
              >
                <DatasetsList
                  onEditClick={handleEditClick}
                  selectedDataset={props.selectedDataset}
                  datasets={datasets.filter(ds => ds.type === 'CALIBRATION')}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Card.Section>
      </Card>
      <Modal
        centered
        opened={edittingDatasetId !== -1}
        onClose={handleModalClose}
        classNames={{
          header: classes.datasetModalHeader
        }}
      >
        {edittingDatasetId ?
          <DatasetForm
            availableDevices={availableDevices}
            onDeleteSuccess={handleDeleteSuccess}
            initialValues={datasets.find(ds => ds.id === edittingDatasetId) || {type: datasetType}}
            onSubmitSuccess={handleModalClose}
          />
          :
          <Tabs
            defaultValue="create-dataset"
            keepMounted={false}
          >
            <Tabs.List grow>
              <Tabs.Tab value="create-dataset" >Create Dataset</Tabs.Tab>
              <Tabs.Tab value="import-dataset" >Import Dataset</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="create-dataset" pt="xs">
              <DatasetForm
                availableDevices={availableDevices}
                onDeleteSuccess={handleDeleteSuccess}
                initialValues={datasets.find(ds => ds.id === edittingDatasetId) || {type: datasetType}}
                onSubmitSuccess={handleModalClose}
              />
            </Tabs.Panel>

            <Tabs.Panel value="import-dataset" pt="xs">
              <DatasetImportForm
                onDeleteSuccess={handleDeleteSuccess}
                onSubmitSuccess={handleModalClose}
              />
            </Tabs.Panel>

          </Tabs>
        }


      </Modal>
    </>
  );
}
