import {
  Container,
  Grid,
  MantineProvider
} from '@mantine/core';
import {ModalsProvider, openContextModal} from '@mantine/modals';
import React from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import {authenticateUser} from './app/features/analytics';
import {getAuthInfo} from './app/features/auth/slice';
import {useAppDispatch, useAppSelector, useIsAuthenticated} from './app/hooks';
import CalibrationDataset from './components/CalibrationDataset';
import CalibrationReport from './components/CalibrationReport';
import Console from './components/Console';
import Header from './components/Header';
import InferenceDataset from './components/InferenceDataset';
import InferenceReport from './components/InferenceReport';
import InferenceReportConvertionModal from './components/InferenceReportConvertionModal';
import MPComponents from './components/MPComponents';
import {INFERENCE_REPORT_CONVERT, LOGIN_MODAL, LoginModal, ONBOARDING_MODAL, SUBSCRIPTION_MODAL, closeLoginModal} from './components/Modals';
import OnboardingRequestModal from './components/OnboardingRequestModal';
import PageCompaceReports from './components/PageCompareReports';
import PageDocumentation from './components/PageDocumentation';
import PageElabCheckAccess from './components/PageElabCheckAccess';
import PageElabImport from './components/PageElabImport';
import PageInferenceReports from './components/PageInferenceReports';
import PageLabels from './components/PageLabels';
import PageTrainingSession from './components/PageTrainingSession';
import QuickGuide from './components/QuickGuide';
import SubscriptionModal from './components/SubscriptionModal';
import TrainingDataset from './components/TrainingDataset';
import Workspace from './components/Workspace';
import Datasets from './components/admin/Datasets';
import InferenceSessions from './components/admin/InferenceSessions';
import Users from './components/admin/Users';
import theme from './theme';
import * as urls from './urls';


function AuthWrapper() {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();

  const handleLoginSuccess = () => {
    closeLoginModal();
    window.location.reload();
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAuthInfo()).then(res => {
        authenticateUser(res.payload.user);
      });
    } else {
      openContextModal({
        modal: 'login',
        title: 'Authenticate',
        innerProps: {
          onSubmitSuccess: handleLoginSuccess
        }
      });
    }
  }, [isAuthenticated, dispatch]);

  return (
    <Outlet />
  );
}
function BaseLayout() {
  const isQuickGuideOpen = useAppSelector(state => state.quickGuide.isOpen);

  return (
    <>
      <Header />
      <Container
        fluid
        px={12}
        pt="lg"
      >
        <Grid columns={24}>
          <Grid.Col span={isQuickGuideOpen ? 19 : 24}>
            <Outlet />
          </Grid.Col>
          <Grid.Col span={5} hidden={!isQuickGuideOpen}>
            <QuickGuide />
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

function App() {
  return (
    <MantineProvider
      theme={theme}
      withNormalizeCSS
      withGlobalStyles
    >
      <ModalsProvider
        modals={{
          [LOGIN_MODAL]: LoginModal,
          [ONBOARDING_MODAL]: OnboardingRequestModal,
          [INFERENCE_REPORT_CONVERT]: InferenceReportConvertionModal,
          // @ts-ignore
          [SUBSCRIPTION_MODAL]: SubscriptionModal,
        }}
      >
        <Routes>
          <Route path="/" element={<BaseLayout />} >

            <Route path='/' element={<AuthWrapper />}>
              <Route path="/" element={<Workspace />}>
                <Route path="training/:datasetId" element={<TrainingDataset />} >
                  <Route path=":imageId" element={<TrainingDataset />} />
                </Route>
                <Route path="inference/:datasetId" element={<InferenceDataset />} >
                  <Route path=":imageId" element={<InferenceDataset />} />
                </Route>
                <Route path="calibration/:datasetId" element={<CalibrationDataset />} >
                  <Route path=":imageId" element={<CalibrationDataset />} />
                </Route>
                <Route path="labels" element={<PageLabels />} />
              </Route>

              <Route path="documentation" element={<PageDocumentation />}>
                <Route path=":page" element={<PageDocumentation />} />
              </Route>
              <Route path="/console/" element={<Console />} >
                <Route path='datasets' element={<Datasets />} />
                <Route path='users' element={<Users />} />
                <Route path='inference-sessions' element={<InferenceSessions />} />
              </Route>
              <Route path="inference/reports" element={<PageInferenceReports />} />
              <Route path="inference/reports/compare/:compareToken/" element={<PageCompaceReports />} />
              <Route path="inference/report/:sessionId" element={<InferenceReport />} />
              <Route path="calibration/report/:sessionId" element={<CalibrationReport />} />
              <Route path="training/session/:sessionId" element={<PageTrainingSession />} />
              <Route path="ui-components" element={<MPComponents />} />
            </Route>

            <Route path={urls.ELAB_CHECK_ACCESS} element={<PageElabCheckAccess />} />
            <Route path={urls.ELAB_SECTION_IMPORT} element={<PageElabImport />} />
            <Route path={urls.ELAB_EXPERIMENT_IMPORT} element={<PageElabImport />} />

          </Route>
        </Routes>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
