import {
  Button,
  Checkbox,
  createStyles,
  Group,
  Select, Text, TextInput
} from '@mantine/core';
import {useForm} from '@mantine/form';
import React, {useEffect} from 'react';
import {createImportJob, CreateImportJobPayload, loadImportFolders} from '../app/features/dataImport/slice';
import {loadModelsList} from '../app/features/training/modelsList';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import FileBrowser from './FileBrowser';

const useStyles = createStyles((theme) => ({
  root: {

  },
  textInput: {
  },
  formLevelError: {
    marginBottom: theme.spacing.md
  },
  formTitle: {
    fontWeight: 500,
  }
}));

interface Props {
  onSubmitSuccess?: () => void;
  onDeleteSuccess?: (datasetId: number) => void;
}

export default function DatasetImportForm({onSubmitSuccess}: Props) {
  const [isSavingDataset, setSavingDataset] = React.useState(false);
  const [isLoadingFolders, setLoadingFolder] = React.useState(true);
  const [folders, setFolders] = React.useState<string[]>([]);
  const [inferenceSelected, setInferenceSelected] = React.useState(false);

  const dispatch = useAppDispatch();
  const models = useAppSelector(state => state.inferenceModels)
  const {classes} = useStyles();

  useEffect(() => {
    dispatch(loadImportFolders()).then(res => {
      setFolders(res.payload);
      setLoadingFolder(false);
    });
    dispatch(loadModelsList());
  }, []);

  const form = useForm<CreateImportJobPayload>({
    initialValues: {
      dataset_name: '',
      paths: [],
      inference_model: 0
    },
    validate: {
      paths: (value: string[]) => value.length === 0 ? "Please, select folder" : false,
      dataset_name: (value) => !value ? "Please, input dataset name" : false,
    },
  });

  const handleFormSubmit = (data: CreateImportJobPayload) => {
    setSavingDataset(true);
    console.log(data);
    dispatch(createImportJob(data)).then(onSubmitSuccess);
    // if (data.id) {
    //   dispatch(updateDataset(data as Partial<Dataset> & {id: number})).then(onSubmitSuccess);
    // } else {
    //   dispatch(saveDataset(data)).then(onSubmitSuccess);
    // }
  };


  return (
    <form onSubmit={form.onSubmit(handleFormSubmit)}>
      <Text
        weight={500}
        component='label'
      >
        Select Folder to Import
      </Text>
      <FileBrowser
        onChange={path => {
          const index = folders.indexOf(path);
          if (index === -1) {
            form.setFieldValue('paths', [...form.values.paths, path]);
          } else {
            const folders = [...form.values.paths];
            folders.splice(index);
            form.setFieldValue('paths', folders);
          }
        }}
        value={form.values.paths}
      />

      <TextInput
        label="Dataset Title"
        placeholder="Leave empty to use import folder title"
        mb="sm"
        {...form.getInputProps('dataset_name')}
      />

      <Checkbox
        label="Start inference after importing"
        checked={inferenceSelected}
        onChange={e => {
          setInferenceSelected(e.currentTarget.checked);
        }}
      />

      {inferenceSelected &&
        <Select
          data={models.map(model => ({
            label: model.title,
            value: model.id + ''
          }))}
          label="Select Model for Inference"
          {...form.getInputProps('inference_model')}
        />
      }

      <Group
        position="apart"
        mt="md"
      >
        <Button
          type="submit"
          loading={isSavingDataset}
        >
          Import Dataset
        </Button>

      </Group>
    </form>
  );
}

