import { Box, createStyles, Divider, Image, Table, Text, Title } from '@mantine/core';
import imageURL from '../assets/images/report-example.jpg';
import ProcessingWorkflow from './ProcessingWorkflow';

const useStyles = createStyles((theme) => ({
  table: {
    border: '1px solid #CED7DF',
    marginTop: theme.spacing.lg,
    '> thead': {
      backgroundColor: '#F5F5F5',
    },
    '& thead tr th': {
      padding: theme.spacing.sm,
    },
    '& tbody tr td': {
      padding: theme.spacing.sm,
      borderRight: '1px solid #CED7DF'
    }
  }
}));

const tableItems = [
  {
    unit: 'ECD (Equivalent Circular Diameter) [um]',
    description: '2*sqrt(Area/pi)',
  },
  {
    unit: 'Area [px]',
    description: 'Number of particle pixels',
  },
  {
    unit: 'Perimeter [px]',
    description: 'Number of particle outline pixels',
  },
  {
    unit: 'Circularity',
    description: '4 * PI * (Area) / (Perimeter) ^2',
  },
  {
    unit: 'Max Feret Diameter [px]',
    description: 'The longest distance between any two points along the particle',
  },
  {
    unit: 'Aspects Ratio',
    description: 'Minor Axis / Major Axis',
  },
  {
    unit: 'X, Y Coordinates [px]',
    description: 'Particle bounding rectangle',
  },
];

export default function DocumentationReport() {
  const { classes } = useStyles();

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        The results of an AI model inferencing new image data is tabulated in the Reports page.  The report page consists of a table available for download with particle statistics and AI outputs.  The list of particle statistic outputs is customisable according to the desired analytical outputs.
      </Text>
      <Divider my="sm" variant="dashed" />
      <Title
        order={5}
        style={{ fontWeight: 500 }}
        mb="xs"
      >
        Initial Processing Workflow
      </Title>

      <Text size="sm" mb="lg">
        Process workflow from initial image upload to final report generation. Each stage must be performed consecutively during initial software usage.
      </Text>
      <Box mb="lg">
        <ProcessingWorkflow
          activeIndex={3}
        />
      </Box>
      <Title
        order={5}
        style={{ fontWeight: 500 }}
        mb="xs"
      >
        Report Screen
      </Title>
      <Text size="sm" mb="lg">
        Example report with graphics and corresponding tabular statistical data.
      </Text>
      <Image
        src={imageURL}
        alt=""
        mb="md"
        mt="md"
      />
      <Divider my="sm" variant="dashed" />
      <Title
        order={5}
        style={{ fontWeight: 500 }}
        mb="xs"
      >
        Statistica and Units
      </Title>
      <Text size="sm" mb="lg">
        Definitions of statistics included in the default reporting table.
      </Text>
      <Table
        className={classes.table}
        mb="lg"
      >
        <thead>
          <tr>
            <th>Statistics and Units</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {tableItems.map((item, index) =>
            <tr key={index}>
              <td>{item.unit}</td>
              <td>
                {item.description}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
