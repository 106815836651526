import {
  Card,
  Center,
  createStyles,
  Group,
  LoadingOverlay,
  Space
} from "@mantine/core";
import {push} from 'connected-react-router';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {loadDatasetDetails} from '../app/features/datasets/details';
import {getNextImagesChunk, loadDatasetImages} from '../app/features/images/slice';
import {InferenceSession, loadInferenceSessions} from '../app/features/inference/slice';
import {InferenceModel, loadModelsList} from '../app/features/training/modelsList';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import * as urls from '../urls';
import ButtonGrayscale from './ButtonGrayscale';
import EmptyDatasetPlaceholder from './EmptyDatasetPlaceholder';
import ImageDeleteButton from './ImageDeleteButton';
import ImagesGallery from './ImagesGallery';
import ImageZoom from './ImageZoom';
import InferenceSessionList from './InferenceSessionsList';
import InferenceStartControls from './InferenceStartControls';
import ZoomControls from './ZoomControls';

const useStyles = createStyles(theme => ({
  root: {
    paddingTop: theme.spacing.md,
    minHeight: 'calc(100vh - 100px)',
  },
  controls: {

  },
  ctaControls: {

  },
  secondaryControls: {

  },
  placeholderWrapper: {
    height: 'calc(100vh - 127px)',
  },
  uploadPlaceholder: {
    maxWidth: 440,
  },

  canvas: {
    position: 'relative',
    overflow: 'auto',
    flexGrow: 1,
    height: 'calc(100vh - 260px)',
  },
}));

interface Props {
  activeImageId: number;
  labels: {
    id: number;
    title: string;
  }[];
  loading?: boolean;
  modelsList: InferenceModel[];
  inferenceSessions: InferenceSession[];
}

export default function InferenceDataset() {
  const {classes} = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {datasetId = '0', imageId = ''} = useParams<{datasetId: string; imageId: string}>();
  const imagesCount = useAppSelector(state => state.images.count);
  const inferenceSessions = useAppSelector(state => state.inferenceSessions.filter(sess => sess.dataset === parseInt(datasetId, 10)));
  const {
    labels,
    inferenceModels,
  } = useAppSelector(state => state);
  const images = useAppSelector(state => state.images.items.filter(img => img.dataset === parseInt(datasetId, 10)));
  const activeImage = useAppSelector(state => {
    if (imageId) {
      const activeImageId = parseInt(imageId, 10);
      return state.images.items.find((img) => img.id === activeImageId) || (
        state.images.items.length ? state.images.items[0] : undefined
      )
    }
    return undefined;
  });

  const activeImageId = 0;

  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadImages = dispatch(loadDatasetImages({datasetId}));
    const loadDetails = dispatch(loadDatasetDetails(datasetId));
    const loadModels = dispatch(loadModelsList());
    const loadSessions = dispatch(loadInferenceSessions());
    loadImages.then((imagesResponse) => {
      setLoading(false);
      if (imagesResponse.payload && imagesResponse.payload.length && !imageId) {
        navigate(urls.getWorkspaceURL('inference', datasetId, imagesResponse.payload.items[0].id), {replace: true});
      }
    });
    return () => {
      loadDetails.abort();
      loadModels.abort();
      loadSessions.abort();
      loadImages.abort();
    };
  }, [datasetId, dispatch]);

  const [isFetchingNextChunk, setFetchingNextChunk] = useState(false);
  const [imagesPage, setImagesPage] = useState(1);
  useEffect(() => {
    let loadImagesChunk: any = undefined;
    if (imagesPage !== 1) {
      setFetchingNextChunk(true);
      const loadImagesChunk = dispatch(getNextImagesChunk({datasetId, page: imagesPage}));
      loadImagesChunk.then(() => {
        setFetchingNextChunk(false);
      })
    }
    return () => {
      if (loadImagesChunk) {
        loadImagesChunk.abort();
      }
    };
  }, [imagesPage]);
  useEffect(() => {
    if (images.length && !imageId) {
      navigate(urls.getWorkspaceURL('inference', datasetId, images[0].id), {replace: true});
    }
  }, [images]);


  const handleImageClick = (imageId: number) => {
    navigate(urls.getWorkspaceURL('inference', datasetId, imageId));
  };

  const handleZoomChange = (value: number) => setZoom(value);

  if (loading) {
    return (
      <Card
        className={classes.root}
      >
        <LoadingOverlay visible={!!loading} overlayBlur={2} />
      </Card>
    );
  }

  if (!activeImage) {
    return (
      <Card
        className={classes.root}
      >
        <Center className={classes.placeholderWrapper}>
          <div style={{maxWidth: 440}} className={classes.uploadPlaceholder}>
            <EmptyDatasetPlaceholder
              datasetId={parseInt(datasetId, 10)}
            />
          </div>
        </Center>
      </Card>
    )
  }
  return (
    <Card
      className={classes.root}
    >
      <LoadingOverlay visible={!!loading} overlayBlur={2} />
      <Group
        className={classes.controls}
        position="apart"
        mb="xs"
      >
        <Group
          className={classes.ctaControls}
        >
          <InferenceStartControls
            labels={labels}
            modelsList={inferenceModels}
            datasetId={parseInt(datasetId, 10)}
          />
          <InferenceSessionList
            sessions={inferenceSessions}
          />
        </Group>

        <Group
          className={classes.secondaryControls}
        >
          <ZoomControls
            onChange={handleZoomChange}
            value={zoom}
          />
          <ButtonGrayscale
            imageId={activeImageId}
            datasetId={parseInt(datasetId, 10)}
            isGrayscale={activeImage.is_grayscale}
          />
          <ImageDeleteButton
            datasetId={parseInt(datasetId, 10)}
            imageId={activeImageId}
            onSuccess={() => {
              if (images.length) {
                dispatch(push(urls.getWorkspaceURL("inference", datasetId, images[0].id)))
              }
            }}
          />
        </Group>
      </Group>
      {!loading &&
        <div className={classes.canvas}>
          <ImageZoom
            onZoomChange={z => {
              console.log(z);
            }}
            zoom={zoom}
            src={activeImage.file}
            height={'calc(100vh - 127px)'}
          />
        </div>
      }
      <Space h="xs" />
      <ImagesGallery
        datasetId={parseInt(datasetId, 10)}
        images={images}
        isLoading={isFetchingNextChunk}
        onScrollEnd={
          () => {
            if ((imagesCount - imagesPage * 100) > 0) {
              setImagesPage(v => v + 1);
            }
          }
        }
        activeImage={activeImage.id}
        onImageClick={handleImageClick}
      />
    </Card>
  );
}
