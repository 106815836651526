import {track} from '@amplitude/analytics-browser';
import amplitude from "amplitude-js";

const amplitudeClient = amplitude.getInstance();
amplitudeClient.init("c455d03f1a8b27a2e054fb59eb9c90a3");

export const CREATE_LABEL = 'createLabel';
export const CREATE_IMAGE_LABEL = 'saveLabel';
export const LABEL_DRAW = 'labelDraw';
export const LOGIN = 'login';
export const TRAINING_START = 'trainingStart';
export const OPEN_LOGIN_MODAL = 'openAuthModal';
export const CHECKPOINT_BUTTON = 'checkpointButton';
export const EXPORT_MODEL = 'exportModel';
export const TRAINING_TAB = 'inferenceTab';
export const INFERENCE_TAB = 'trainingTab';
export const INFERENCE_START = 'inferenceStart';
export const INFERENCE_REPORT = 'inferenceReport';
export const ELAB_IMPORT = 'elabImport';
export const ELAB_LOGIN_FAIL = 'elabLoginFail';
export const ELAB_LOGIN_SUCCESS = 'elabLoginSuccess';
export const TRAINING_SESSIONS_DROPDOWN = 'trainingSessionsDropdown';
export const ELAB_IMPORT_PAGE = 'elabImportPage';
export const ELAB_CHECK_ACCESS_PAGE = 'elabCheckAccessPage';
export const ELAB_AUTH_START = 'elabAuthStart';

export function logEvent(eventType: string, eventProperties?: {[key: string]: string | number}) {
  if (process.env.NODE_ENV !== 'development') {
    track(eventType, eventProperties);
  }
}

export function logButtonClick(event: {eventName: string, payload?: {[key: string]: string | number}}) {
  logEvent(event.eventName, {...event.payload, actionType: 'BUTTON_CLICK'});
}

export function logFailedAction(event: {eventName: string, payload?: {[key: string]: string | number}}) {
  logEvent(event.eventName, {...event.payload, actionType: 'ACTION_FAIL'});
}

export function logPageVisit(event: {eventName: string, payload?: {[key: string]: string | number}}) {
  logEvent(event.eventName, {...event.payload, actionType: 'PAGE_VISIT'});
}

export function authenticateUser(userId: number) {
  if (process.env.NODE_ENV !== 'development') {
    amplitudeClient.setUserId(userId.toString());
  }
}

export const logAuthClick = () => logButtonClick({eventName: LOGIN});
export const openLoginModal = () => logButtonClick({eventName: OPEN_LOGIN_MODAL});
export const logLabelDrawn = () => logEvent(LABEL_DRAW);
export const logLabelAdd = () => logButtonClick({eventName: CREATE_LABEL});
export const logImageLabelAdd = () => logButtonClick({eventName: CREATE_IMAGE_LABEL});
export const logTrainingStartClick = () => logButtonClick({eventName: TRAINING_START})
