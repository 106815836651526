import {ActionIcon, ActionIconProps, createStyles} from '@mantine/core';
import {PolymorphicComponentProps} from '@mantine/utils';
import infoIconImageURL from '../assets/icons/info-dark.svg';

const useStyles = createStyles(theme => ({
  infoIcon: {
    display: 'inline-block',
    marginLeft: 4,
    verticalAlign: 'text-top',
  }
}));

export default function InfoIcon(props: PolymorphicComponentProps<'button', ActionIconProps>) {
  const {classes} = useStyles();

  return (
    <ActionIcon variant="transparent" className={classes.infoIcon} size="sm" {...props}>
      <img src={infoIconImageURL} alt="" />
    </ActionIcon>
  )
}
