import {Button, Center, Text, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import React from 'react';
import {Link} from 'react-router-dom';
import * as urls from '../urls';

interface Props {
  onSubmit: (title: string) => Promise<any>;
  isModelExported: boolean;
}

export default function ExportModelForm(props: Props) {
  const [isLoading, setLoading] = React.useState(false);
  const form = useForm({
    initialValues: {
      title: ''
    },
    validate: {
      title: value => !value
    }
  });

  const handleSubmit = () => {
    setLoading(true);
    props.onSubmit(form.values.title).then(() => {
      setLoading(false);
    });
  };

  return (
    props.isModelExported ?
      <div>
        Model "{form.values.title}" is  exported and soon will be available for inference.
        You can start inference now in your Inference Workspace section
        <Center mt="sm">
          <Button component={Link} to={urls.getWorkspaceURL()}>
            Go to Workspace
          </Button>
        </Center>
      </div>
      :
      <div >
        <form
          onSubmit={form.onSubmit(handleSubmit)}
        >
          <Text size="sm" align="center" mb="sm">
            Provide a name for the AI model generated from your dataset and select ‘AI Export’.
          </Text>
          <TextInput
            placeholder='Input name of the model to be saved'
            {...form.getInputProps("title")}
            mb="sm"
          />

          <Button
            type="submit"
            loading={isLoading}
          >
            AI Export
          </Button>
        </form>
      </div>
  );
}
