import {Button} from '@mantine/core';
import {deleteImage} from '../app/features/images/slice';
import {useAppDispatch} from '../app/hooks';
import trashIconURL from '../assets/icons/trash.svg';

export default function ImageDeleteButton(props: {datasetId: number; imageId: number; onSuccess: (d: any) => void}) {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (props.imageId === 0) {
      return;
    }
    dispatch(deleteImage({
      imageId: props.imageId,
      datasetId: props.datasetId,
    })).then(props.onSuccess);

  }
  return (
    <Button
      variant="subtle"
      color="red"
      size="xs"
      onClick={handleClick}
    >
      <img src={trashIconURL} alt="" />
      Remove Image
    </Button>
  )
}
