import {
  Card,
  Center,
  createStyles, Group,
  LoadingOverlay,
  Space
} from "@mantine/core";
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {loadDatasetDetails} from '../app/features/datasets/details';
import {loadDatasetImages} from '../app/features/images/slice';
import {loadInferenceSessions} from '../app/features/inference/slice';
import {loadModelsList} from '../app/features/training/modelsList';
import {useAppDispatch, useAppSelector} from '../app/hooks';
import * as urls from '../urls';
import AnnotationCanvas from './AnnotationCanvas';
import ButtonGrayscale from './ButtonGrayscale';
import CalibrationSessionList from './CalibrationSessionsList';
import CalibrationStartControls from './CalibrationStartControls';
import EmptyDatasetPlaceholder from './EmptyDatasetPlaceholder';
import ImagesGallery from './ImagesGallery';
import ZoomControls from './ZoomControls';

const useStyles = createStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 100px)',
  },
  controls: {

  },
  ctaControls: {

  },
  placeholderWrapper: {
    height: 'calc(100vh - 127px)',
  },
  annotatorWrapper: {
    position: 'relative',
  },
  uploadPlaceholder: {
    maxWidth: 440,
  },
  drawBox: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  rect: {
    position: 'absolute',
    border: `solid 1px ${theme.colors.blue[5]}`,
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.colors.green[5]
    }
  },
  cannyFilters: {
    width: 240,
  },
  toolboxTabPanel: {
    border: 'solid 1px #ccc',
    borderTop: 'none',
    padding: theme.spacing.sm,
    height: 'calc(100vh - 275px)',
    overflow: 'auto',
  },
  imageObject: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    marginBottom: theme.spacing.sm,
    borderTop: `solid 1px #ccc`
  }
}));

export default function CalibrationDataset() {
  const {classes} = useStyles();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {datasetId = '0', imageId = ''} = useParams<{datasetId: string; imageId: string}>();

  const {
    labels,
    inferenceModels,
  } = useAppSelector(state => state);
  const images = useAppSelector(state => state.images.items.filter(img => img.dataset === parseInt(datasetId, 10)));
  const inferenceSessions = useAppSelector(state => state.inferenceSessions.filter(sess => sess.dataset === parseInt(datasetId, 10)));
  const activeImage = useAppSelector(state => {
    if (imageId) {
      const activeImageId = parseInt(imageId, 10);
      return state.images.items.find((img) => img.id === activeImageId)
    }
    return undefined;
  });

  const activeImageId = 0;

  const [zoom, setZoom] = useState(1);
  const [cannyFiltersOpen, setCannyFiltersOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadImages = dispatch(loadDatasetImages({datasetId}));
    const loadDetails = dispatch(loadDatasetDetails(datasetId));
    const loadModels = dispatch(loadModelsList());
    const loadSessions = dispatch(loadInferenceSessions());

    loadImages.then((imagesResponse) => {
      setLoading(false);
      if (imagesResponse.payload && imagesResponse.payload.length && !imageId) {
        navigate(urls.getWorkspaceURL('calibration', datasetId, imagesResponse.payload[0].id), {replace: true});
      }
    });
    return () => {
      loadModels.abort();
      loadSessions.abort();
      loadDetails.abort();
      loadImages.abort();
    };
  }, [datasetId, dispatch]);

  useEffect(() => {
    if (images.length && !imageId) {
      navigate(urls.getWorkspaceURL('calibration', datasetId, images[0].id), {replace: true});
    }
  }, [images]);


  const handleImageClick = (imageId: number) => {
    navigate(urls.getWorkspaceURL('calibration', datasetId, imageId));
  };

  const handleDeleteSuccess = () => {
    if (images.length && activeImageId) {
      navigate(urls.getWorkspaceURL('calibration', datasetId));
    }
  }
  const handleZoomChange = (value: number) => setZoom(value);
  const handleFiltersClick = () => {
    setCannyFiltersOpen(!cannyFiltersOpen);
  };


  const annotatorImageId = activeImageId || (images.length > 0 ? images[0].id : 0);

  if (loading) {
    return (
      <Card
        className={classes.root}
      >
        <LoadingOverlay visible={!!loading} overlayBlur={2} />
      </Card>
    );
  }

  if (!activeImage) {
    return (
      <Card
        className={classes.root}
      >
        <Center className={classes.placeholderWrapper}>
          <div style={{maxWidth: 440}} className={classes.uploadPlaceholder}>
            <EmptyDatasetPlaceholder
              datasetId={parseInt(datasetId, 10)}
            />
          </div>
        </Center>
      </Card>
    )
  }
  return (
    <Card
      className={classes.root}
    >
      <Group
        className={classes.controls}
        position="apart"
        mb="xs"
      >
        <Group
          className={classes.ctaControls}
        >
          <Group
            className={classes.ctaControls}
          >
            <CalibrationStartControls
              datasetId={parseInt(datasetId, 10)}
              modelsList={inferenceModels}
            />
            <CalibrationSessionList
              sessions={inferenceSessions}
            />
          </Group>
        </Group>
        <Group>
          <ZoomControls
            onChange={handleZoomChange}
            value={zoom}
          />
          <ButtonGrayscale
            datasetId={parseInt(datasetId, 10)}
            imageId={annotatorImageId}
            isGrayscale={activeImage!.is_grayscale}
          />
        </Group>
      </Group>
      <div className={classes.annotatorWrapper}>
        <AnnotationCanvas
          showToolbox={false}
          dataset={{
            id: parseInt(datasetId, 10)
          }}
          disableSearch={true}
          height={'calc(100vh - 265px)'}
          labels={labels}
          onZoomChange={handleZoomChange}
          zoom={zoom}
          image={activeImage}
        />
      </div>
      <Space h="xs" />
      <ImagesGallery
        datasetId={parseInt(datasetId, 10)}
        images={images}
        activeImage={activeImage!.id}
        onImageClick={handleImageClick}
      />
    </Card>
  );
}
