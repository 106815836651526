import {Box, Divider, Image, Text, Title} from '@mantine/core';
import imageURL from '../assets/images/training-set-example.jpg';
import ProcessingWorkflow from './ProcessingWorkflow';
export default function DocumentationTrainingSet() {

  return (
    <>
      <Text size="sm" mb="md" mt="sm">
        A training set is a group of images that the model will learn object identities and
        locations from over time.  It is labelled by the end user
        and represents the expected objects and locations for the model to detect.
      </Text>

      <Divider my="sm" variant="dashed" />
      <Image
        src={imageURL}
        alt=""
        mb="md"
        mt="md"
      />
      <Title
        order={5}
        style={{fontWeight: 500}}
        mb="xs"
      >
        Initial Processing Workflow
      </Title>
      <Text size="sm" mb="lg">
        Process workflow from initial image upload to final report generation. Each stage must be performed consecutively during initial software usage.
      </Text>
      <Box mb="lg">
        <ProcessingWorkflow
          activeIndex={0}
        />
      </Box>
    </>
  )
}
