import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import {RootState} from '../../store';

export interface InferenceModel {
  id: number;
  title: string;
  description: string;
}

const loadModelsList = createAsyncThunk(
  "inferenceModels/load",
  async (_: undefined, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.get(`/api/training/models`, {}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const exportCheckpointModel = createAsyncThunk(
  "inferenceModels/export",
  async ({checkpoint, session, title}: {checkpoint: number; session: number; title: string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.post(`/api/training/export/${session}/${checkpoint}/`, {title}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const updateModelDetails = createAsyncThunk(
  "inferenceModels/updateDetails",
  async ({id, title, description}: {id: number; title: string; description: string}, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.patch(`/api/training/models/${id}/`, {title, description}, state.auth.token).then(res => {
      if (res.status === 200) {
        return res.json();
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const deleteModel = createAsyncThunk(
  "inferenceModels/delete",
  async (modelId: number, {rejectWithValue, getState}) => {
    const state = getState() as RootState;

    return apiClient.remove(`/api/training/models/${modelId}/`, state.auth.token).then(res => {
      if (res.status === 200) {
        return modelId;
      } else if (res.status === 403) {
        return rejectWithValue("You are not allowed to perform this operation.")
      } else {
        return rejectWithValue("Something wrong happened. Please, try again later.")
      }
    });
  }
);

const slice = createSlice({
  name: 'inferenceModels',
  initialState: [] as InferenceModel[],
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadModelsList.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(deleteModel.fulfilled, (state, action) => {
      return state.filter(model => model.id !== action.payload);
    });
    builder.addCase(updateModelDetails.fulfilled, (state, action) => {
      return state.map(model => model.id === action.payload.id ? action.payload : {...model});
    });
  }
});

export {loadModelsList, exportCheckpointModel, updateModelDetails, deleteModel};

export default slice.reducer;
